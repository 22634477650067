import React, {Component} from 'react';
import {Card, CardHeader, CardBody} from 'reactstrap';
import {tr} from '../_locales';
import {config} from '../config';
import styles from './tutorial.module.scss';

class Tutorial extends Component {

  render() {
    const {data} = this.props;
    return (
      <Card className={styles.tutorial}>
        <CardBody>
          <video controls width='350'>
            <source src={config.API_URL + '/' + data.video.filepath} type="video/mp4" />
            <p>{tr('Your browser isn\'t able to load theses videos.')}</p>
          </video>
          <div className={styles.description}>
            <p>{data.description}</p>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default Tutorial;