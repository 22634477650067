import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';

export const typeChart={
  "Bar":Bar,
  "Pie":Pie,
  "Line":Line,
  "Doughnut":Doughnut,
  "Polar":Polar,
  "Radar":Radar
}

const colorForCharts = ["#FF6384", "#36A2EB","#FFCE56","#0074D9","#FF4136","#2ECC40","#FF851B","#7FDBFF","#B10DC9","#FFDC00",
"#001f3f","#39CCCC","#01FF70","#85144b","#F012BE","#3D9970","#111111","#AAAAAA"];
export const chartSettings = {
  Line: {
    fill: false,
    lineTension: 0,
    backgroundColor: "rgba(75,192,192,0.4)",
    borderColor: "rgba(75,192,192,1)",
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: "rgba(75,192,192,1)",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: "rgba(75,192,192,1)",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10
  },
  Bar: {
    backgroundColor: "rgba(255,99,132,0.2)",
    borderColor: "rgba(255,99,132,1)",
    borderWidth: 1,
    hoverBackgroundColor: "rgba(255,99,132,0.4)",
    hoverBorderColor: "rgba(255,99,132,1)"
  },
  Doughnut: {
    backgroundColor: colorForCharts
  },
  Pie: {
    backgroundColor: colorForCharts
  },
  Polar: {
    backgroundColor: colorForCharts
  },
 Radar:{
  backgroundColor: 'rgba(179,181,198,0.2)',
  borderColor: 'rgba(179,181,198,1)',
  pointBackgroundColor: 'rgba(179,181,198,1)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgba(179,181,198,1)',
 }
};
