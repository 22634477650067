import api from "../_api/api";
import { layoutConstants } from "../Grid";
import Notifications from "react-notification-system-redux";
import { tr } from "../_locales";
export const layoutActions = {
  addDefaultLayout,
  updateDefaultLayout,
  getDefaultLayout,
  deleteDefaultLayout,
  changeLayout,
  removeElement,
  addElement,
  updateElement
};

function changeLayout(layout) {
  return { type: layoutConstants.CHANGE_LAYOUT, layout };
}
function removeElement(element) {
  return { type: layoutConstants.REMOVE_EL_LAYOUT, element };
}
function addElement(element) {
  return { type: layoutConstants.ADD_EL_LAYOUT, element };
}
function updateElement(widget) {
  return { type: layoutConstants.UPDATE_EL_LAYOUT, widget };
}

function deleteDefaultLayout(id) {
  return dispatch => {
    api.layout.deleteDefault(id).then(layout => {
        dispatch(success(id));
        dispatch(Notifications.success({ message: tr("layout_store_success") }));
      }, error => {
        dispatch(Notifications.error({ message: error.statusText }));
      });
  };

  function success(id) {
    return { type: layoutConstants.DELETE_SUCCESS, id };
  }

}

function addDefaultLayout(layout) {
  return dispatch => {
    return api.layout.addDefault(layout).then(
    // return layoutService.addDefault(layout).then(
      layout => {dispatch(success(layout));},
      error => {dispatch(Notifications.error({ message: tr("save_error") }));}
    );
  };

  function success(layout) {
    return { type: layoutConstants.DEF_STORE_SUCCESS, layout };
  }

}
function updateDefaultLayout(layout) {
  return dispatch => {
    api.layout.updateDefault(layout).then(
      layout => {
        dispatch(success(layout));
        dispatch(Notifications.success({ message: tr("layout_store_success") }));
      },
      error => {dispatch(Notifications.error({ message:  tr("update_error") }));}
    );
  };

  function success(layout) {
    return { type: layoutConstants.DEF_UPDATE_SUCCESS, layout };
  }

}
function getDefaultLayout(id) {
  return dispatch => {
    return api.layout.getDefault(id).then(    
      layout => {dispatch(success(layout)); },
      // we distinguish non existant layouts from network errors
      error => {error.response && error.response.status !== 404 ? dispatch(Notifications.error({ message: tr("get_error") })):dispatch(empty())}
    );
  };
  function success(layout) {
    return { type: layoutConstants.DEF_GET_SUCCESS, layout };
  }
  function empty(layout) {
    return { type: layoutConstants.DEF_GET_EMPTY, layout };
  }  

}
