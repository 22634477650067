import React from "react";
import * as widgets from '../Widgets';
import {checkOtherData} from "../_helpers/otherData";

 export class Widget extends React.Component {


  render() {
    const { data, widgetId } = this.props;
    if (data && data.type)
      {
      const TheWidget =  widgets[data.type]; // The widget is passed by the parent in data property
      const otherData = checkOtherData(data);  //vérifie si il y a des données supplémentaires à passer

        return (  
                <TheWidget data={data} widgetId={widgetId} otherData={otherData}></TheWidget>   // Calling the right Widget
            );            
      }
    
    else
        return null;    
    }   
}