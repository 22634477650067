import React, {Component} from 'react';
import {Card, CardBody, Input, Container, Row, Col,
        Button, Form, FormGroup, Label, FormFeedback} from 'reactstrap';
import {tr} from '../../../_locales';
import UIStore from '../../../store/UIStore';

class ChangelogEdit extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      version: '',
      fr: '',
      en: '',
      id: null,
      data: null,

      errVersion: false,
      errText: false,
    };
  }
  //-------------------------- lifecycle -------------------------------//
  componentDidMount() {
    const data = this.props.mode === 'add' ? null : this.props.data.data.find(item => item.id === this.props.id);
    const version = data ? data.version : '';
    const fr = data ? data.fr : '';
    const en = data ? data.en : '';
    const id = data ? data.id : null
    this.setState({version, fr, en, id, data});
  }

  //-------------------------- rendering -------------------------------//
  render() {
    return (
      <Form onSubmit={this.submit}>
        <Card>
          <h4>
            {this.props.mode === 'add' ? tr('Create version') : tr('Modify version')}
          </h4>
          <Button className='ButtonBack' onClick={this.props.back} color='secondary'>{tr('Back')}</Button>
          <Button className='ButtonUpdate' onClick={this.submit} color='success'>{tr('Update')}</Button>
          <CardBody>
            <FormGroup>
              <Label>{tr('Version')+':'}</Label>
              <Input value={this.state.version} onChange={(e) => {this.setState({version: e.target.value});}}
                     invalid={this.state.errVersion} placeholder='ex: 1.0.0' />
              <FormFeedback>{tr('You should specify a version id.')}</FormFeedback>
            </FormGroup>
            <Container>
              <Row>
                <Col md>
                <FormGroup>
                  <Label>{tr('Description: ') + 'fr'}</Label>
                  <Input type="textarea" value={this.state.fr} onChange={(e) => {this.setState({fr: e.target.value});}}
                        invalid={this.state.errText} placeholder={tr('Description') + '...'} />
                  <FormFeedback>{tr('You should add a description.')}</FormFeedback>
                </FormGroup>
                </Col>
                <Col md>
                  <FormGroup>
                    <Label>{tr('Description: ') + 'en'}</Label>
                    <Input type="textarea" value={this.state.en} onChange={(e) => {this.setState({en: e.target.value});}} 
                          invalid={this.state.errText} placeholder={tr('Description') + '...'} />
                    <FormFeedback>{tr('You should add a description.')}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Form>
    );
  }

  //-------------------------- events -------------------------------//
  submit = (e) => {
    const {id, version, fr, en, data}= this.state;
    let errCounter = 0;

    this.setState({
      errVersion: false,
      errText: false,
    });
    if (e) e.preventDefault();
    if (!this.state.version) {
      this.setState({errVersion: true});
      errCounter++;
    }
    if (!this.state.fr || !this.state.en) {
      this.setState({errText: true});
      errCounter++;
    }
    if (errCounter > 0)
      return;
    this.props.update({
      id,
      version,
      fr,
      en,
    }, id, data, this.props.mode);
  }

}

export {ChangelogEdit};