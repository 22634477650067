import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UIStore from './../store/UIStore';

/**
 * Manage Logout
 * Logout and redirect to Home
 * @param rest
 * @returns {*}
 * @constructor
 */
export const LogoutRoute = ({ ...rest }) => {
  UIStore.logout();
  return <Route {...rest} render={props => ( <Redirect to={{pathname: '/', state: {from: props.location}}}/> )}/>
}