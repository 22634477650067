import axios from "axios";
import { manageErrors } from "../_api/api";
import { config } from "../config";

export function axiosDatasource(method, url, data, errorDefault, responseType = 'json') {
  let objSend = {
    method: method,
    url: url,
    data: data,
    responseType
  };
  if (!url.startsWith("http")){ // Si ce n'est pas une URL absolue on ajoute l'URL de base qui va bien selon l'environnement
    url = config.API_URL + url;
  }
  if (!url.startsWith(config.API_URL) && config.HEADER_DATASOURCE) {
    // if we don't send to bob back-end and a header is configured put it
    objSend.headers = config.HEADER_DATASOURCE;
  }
  return axios(objSend).then(
    element => ({ error: false, data: element.data }),
    error => {
      return manageErrors(error, errorDefault);
    }
  );
}
