import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import moment from "moment";
import { getEnvironnements, getLocales } from "../../../_api/apiProjipac";

const fieldList = [
  { field: "createdBy_libelle" },
  { field: "internal" },
  { field: "project_id" },
  { field: "data.maxReachedStep" },
  { field: "data.forms.heatModeStep.form.departement" },
  { field: "data.forms.heatModeStep.form.departement_value" },
  { field: "data.forms.heatModeStep.form.stationMeteo" },
  { field: "data.forms.heatModeStep.form.stationMeteo_value" },
  { field: "data.deperds.TBase" },
  { field: "data.forms.heatModeStep.form.altitude" },
  { field: "data.forms.heatModeStep.form.mitoyennete_i18nCode" },
  { field: "data.forms.heatModeStep.form.level_i18nCode" },
  { field: "data.forms.heatModeStep.form.zones[0].surface" },
  { field: "data.forms.heatModeStep.form.zones[0].hauteur" },
  { field: "data.forms.heatModeStep.form.zones[0].emetteur_i18nCode" },
  { field: "data.forms.heatModeStep.form.zones[0].temperature", label: "temperature zone1" },
  { field: "data.forms.heatModeStep.form.zones[1].surface", label: "surface zone2" },
  { field: "data.forms.heatModeStep.form.zones[1].hauteur", label: "hauteur zone2" },
  { field: "data.forms.heatModeStep.form.zones[1].emetteur_i18nCode", label: "emetteur zone2" },
  { field: "data.forms.heatModeStep.form.zones[1].temperature", label: "temperature zone2" },

  { field: "data.forms.heatModeStep.form.deperditions.deperditionMethod_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.temperature" },
  { field: "data.forms.heatModeStep.form.deperditions.year" },
  { field: "data.forms.heatModeStep.form.deperditions.insulationWork" },
  { field: "data.forms.heatModeStep.form.deperditions.walls_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.roofing_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.windows_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.dpe" },
  { field: "data.forms.heatModeStep.form.deperditions.annualBilling" },
  { field: "data.forms.heatModeStep.form.deperditions.actualSystem_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.nrgPrice" },
  { field: "data.forms.heatModeStep.form.deperditions.nrgUnit" },
  { field: "data.forms.heatModeStep.form.deperditions.withECS_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.nbPeoples" },
  { field: "data.forms.heatModeStep.form.deperditions.systemAge_i18nCode" },
  { field: "data.forms.heatModeStep.form.deperditions.dayTemp" },
  { field: "data.forms.heatModeStep.form.deperditions.nightTemp" },
  { field: "data.forms.heatModeStep.form.deperditions.deperditions" },
  { field: "data.deperds.Deperd" },
  { field: "data.newMaximumTempTransmitter" },

  { field: "data.forms.hpChoiceStep.form.heatPumpType_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.typologie_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.captage_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.utilisation" },
  { field: "data.forms.hpChoiceStep.form.energie_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.alimentation_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.abonnement_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.releveChaudiere" },
  { field: "data.forms.hpChoiceStep.form.anneeChaudiere_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.typeChaudiere_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.energieChaudiere_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.puissanceChaudiere" },
  { field: "data.forms.hpChoiceStep.form.typeECS_i18nCode" },
  { field: "data.forms.hpChoiceStep.form.nbOccupants" },
  { field: "data.selectedProduct.product.code" },
  { field: "data.selectedProduct.product.code_env" },
  { field: "data.selectedProduct.product.reference" },
  { field: "data.schemaCode" },
  //"data.accessories.recommendedAccessories[...]._accessory.code  // Pour chaque
  //"data.accessories.otherAccessories[...]._accessory.code  // Pour chaque
];

const fieldListCold = [
  { field: "data.simulationCool", label: "coolMode simulationCool" },
  { field: "data.forms.coolModeStep.form.inertia_i18nCode", label: "coolMode inertia" },
  { field: "data.forms.coolModeStep.form.nbPeoples", label: "coolMode nbPeoples" },
  { field: "data.forms.coolModeStep.form.location_i18nCode", label: "coolMode location" },
  { field: "data.forms.coolModeStep.form.temperature", label: "coolMode temperature" },
  { field: "data.selectedProduct.SurfaceRAF", label: "coolMode SurfaceRAF" },
  { field: "data.selectedProduct.ColdMaxPowerTransmitters", label: "coolMode ColdMaxPowerTransmitters" },
  { field: "data.selectedProduct.ColdTxCouv", label: "coolMode ColdTxCouv" },
  { field: "data.selectedProduct.AllColdConso", label: "coolMode AllColdConso" },
  { field: "data.selectedProduct.EERGlobal", label: "coolMode EERGlobal" },
  { field: "data.selectedProduct.E4.RAFHPCO2", label: "coolMode RAFHPCO2" },
  { field: "data.selectedProduct.E4.RAFHPCost", label: "coolMode RAFHPCost" },
  { field: "data.selectedProduct.E4.RAFHPKWH", label: "coolMode RAFHPKWH" },
  { field: "data.selectedProduct.E5.ConfortTX", label: "coolMode ConfortTX (<24°C)" },
  { field: "data.selectedProduct.E5.InteriorTX", label: "coolMode InteriorTX (24°-26°C)" },
  { field: "data.selectedProduct.E5.DisconfortTX", label: "coolMode DisconfortTX (>26°C)" },
];

const excelExport = (jsonData) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, "projipac4_export" + fileExtension);
};

export const satisfactionExport = async (data) => {
  const envs = await getEnvironnements();
  const locales = await getLocales();
  let finalData = [];

  data.forEach((item) => {
    let translated_data = {};
    for (var key in item.data) {
      if (item.data.hasOwnProperty(key)) {
        if (key == "GIVE_IT_HELP_PLUS_SATISFACTION") {
          translated_data[locales.data["GIVE_IT_HELP_PLACEHOLDER"]] = locales.data[item.data[key]]
            ? locales.data[item.data[key]]
            : item.data[key];
        } else if (key == "INTUITIENESS") {
          translated_data[locales.data["INTUITIVENESS"]] = locales.data[item.data[key]]
            ? locales.data[item.data[key]]
            : item.data[key];
        } else {
          translated_data[locales.data[key]] = locales.data[item.data[key]]
            ? locales.data[item.data[key]]
            : item.data[key];
        }
      }
    }

    finalData.push({
      id: item.id,
      createdBy: item.createdBy_libelle,
      date: moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      environnement: envs.find((elt) => elt.id === item.environnement_id).code,
      satisfied: item.satisfied,
      ...translated_data,
    });
  });
  excelExport(finalData);
};

export const excelStdExport = async (data) => {
  const envs = await getEnvironnements();
  const locales = await getLocales();

  data = data.map((d) => {
    delete d.action;
    return d;
  });

  excelExport(data);
};

const resetDeperditionMethod0 = (data) => {
  data.forms.heatModeStep.form.deperditions.deperditions = null;
};
const resetDeperditionMethod1 = (data) => {
  data.forms.heatModeStep.form.deperditions.dpe = null;
};
const resetDeperditionMethod2 = (data) => {
  data.forms.heatModeStep.form.deperditions.annualBilling = null;
  data.forms.heatModeStep.form.deperditions.actualSystem = null;
  data.forms.heatModeStep.form.deperditions.actualSystem_i18nCode = null;
  data.forms.heatModeStep.form.deperditions.nrgPrice = null;
  data.forms.heatModeStep.form.deperditions.withECS = null;
  data.forms.heatModeStep.form.deperditions.withECS_i18nCode = null;
  data.forms.heatModeStep.form.deperditions.nbPeoples = null;
  data.forms.heatModeStep.form.deperditions.systemAge = null;
  data.forms.heatModeStep.form.deperditions.systemAge_i18nCode = null;
  data.forms.heatModeStep.form.deperditions.dayTemp = null;
  data.forms.heatModeStep.form.deperditions.nightTemp = null;
};
const resetDeperditionMethod3 = (data) => {
  data.forms.heatModeStep.form.deperditions.year = null;
  data.forms.heatModeStep.form.deperditions.insulationWork = null;
  data.forms.heatModeStep.form.deperditions.walls = null;
  data.forms.heatModeStep.form.deperditions.walls_i18nCode = null;
  data.forms.heatModeStep.form.deperditions.roofing = null;
  data.forms.heatModeStep.form.deperditions.roofing_i18nCode = null;
  data.forms.heatModeStep.form.deperditions.windows = null;
  data.forms.heatModeStep.form.deperditions.windows_i18nCode = null;
};

export const ProjectTraceExport = async (data) => {
  const envs = await getEnvironnements();
  const locales = await getLocales();
  let finalData = [];
  data.forEach((item) => {
    if (item.data.maxReachedStep >= 5) {
      try {
        switch (item.data.forms.heatModeStep.form.deperditions.deperditionMethod) {
          case "0":
            resetDeperditionMethod1(item.data);
            resetDeperditionMethod2(item.data);
            resetDeperditionMethod3(item.data);
            break;
          case "1":
            resetDeperditionMethod0(item.data);
            resetDeperditionMethod2(item.data);
            resetDeperditionMethod3(item.data);
            break;
          case "2":
            resetDeperditionMethod0(item.data);
            resetDeperditionMethod1(item.data);
            resetDeperditionMethod3(item.data);
            break;
          case "3":
            resetDeperditionMethod0(item.data);
            resetDeperditionMethod1(item.data);
            resetDeperditionMethod2(item.data);

            // 3
            if (item.data.forms.heatModeStep.form.deperditions.insulationWork == "0") {
              item.data.forms.heatModeStep.form.deperditions.walls = null;
              item.data.forms.heatModeStep.form.deperditions.walls_i18nCode = null;
              item.data.forms.heatModeStep.form.deperditions.roofing = null;
              item.data.forms.heatModeStep.form.deperditions.roofing_i18nCode = null;
              item.data.forms.heatModeStep.form.deperditions.windows = null;
              item.data.forms.heatModeStep.form.deperditions.windows_i18nCode = null;
            }
            break;
        }

        switch (item.data.forms.hpChoiceStep.form.heatPumpType) {
          case "1":
            item.data.forms.hpChoiceStep.form.captage = null;
            item.data.forms.hpChoiceStep.form.captage_i18nCode = null;

            item.data.forms.hpChoiceStep.form.utilisation = null;
            item.data.forms.hpChoiceStep.form.energie_i18nCode = null;
            break;
          case "2":
            item.data.forms.hpChoiceStep.form.typologie = null;
            item.data.forms.hpChoiceStep.form.typologie_i18nCode = null;

            item.data.forms.hpChoiceStep.form.utilisation = null;
            item.data.forms.hpChoiceStep.form.energie_i18nCode = null;
            break;
          case "3":
            item.data.forms.hpChoiceStep.form.typologie = null;
            item.data.forms.hpChoiceStep.form.typologie_i18nCode = null;

            item.data.forms.hpChoiceStep.form.captage = null;
            item.data.forms.hpChoiceStep.form.captage_i18nCode = null;
            break;
        }

        switch (item.data.forms.hpChoiceStep.form.releveChaudiere) {
          case "sans":
            item.data.forms.hpChoiceStep.form.anneeChaudiere = null;
            item.data.forms.hpChoiceStep.form.anneeChaudiere_i18nCode = null;
            item.data.forms.hpChoiceStep.form.typeChaudiere = null;
            item.data.forms.hpChoiceStep.form.typeChaudiere_i18nCode = null;
            item.data.forms.hpChoiceStep.form.energieChaudiere = null;
            item.data.forms.hpChoiceStep.form.energieChaudiere_i18nCode = null;
            item.data.forms.hpChoiceStep.form.puissanceChaudiere = null;
            break;
        }

        const startData = {
          id: item.id,
          date: moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss"),
          environnement: envs.find((elt) => elt.id === item.environnement_id).code,
        };
        let otherData = {};
        [...fieldList, ...fieldListCold].forEach((f) => {
          let field = f.field;
          const member = field.split(".");

          // Pour la zone  2
          let prop = f.label ? f.label : member[member.length - 1];

          if (prop == "releveChaudiere") {
            otherData[prop] = _.get(item, field) == "sans" ? locales.data["NO"] : locales.data["YES"];
          } else {
            otherData[prop] =
              field.endsWith("_i18nCode") && locales.data[_.get(item, field)]
                ? locales.data[_.get(item, field)]
                : _.get(item, field);
          }
        });

        item.data.accessories &&
          item.data.accessories.recommendedAccessories.forEach((acc, index) => {
            if (acc._selected) {
              otherData[`recommendedAccessory_${index + 1}`] = acc._accessory.code;
            }
          });

        item.data.accessories &&
          item.data.accessories.otherAccessories.forEach((acc, index) => {
            if (acc._selected) {
              otherData[`otherAccessories_${index + 1}`] = acc._accessory.code;
            }
          });

        finalData.push({ ...startData, ...otherData });
      } catch (ex) {
        console.log("ERROR: ", ex);
      }
    }
  });
  excelExport(finalData);
};
