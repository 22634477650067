import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  AppFooter,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";

// routes config
import DefaultFooter from "./DefaultFooter";
import { DefaultHeader } from "./DefaultHeader";
import { menuActions } from ".";
import { GridPage } from "../Grid";
import { Settings } from "../Views/Settings";
import { Datasource } from "../Datasource/Datasource";
import { datasourceActions } from "../Datasource/datasource.actions";
import { tr } from "../_locales";
import { canIDoThis } from "../_helpers/rightsManager";
import { observer } from 'mobx-react';
import UIStore from '../store/UIStore';
import { DEFAULT_ROUTE } from '../configApp';

@observer
class MainComponent extends React.Component {
  componentDidMount() {
    this.props.dispatch(menuActions.getMenu(this.props.authentication)); // retrieve the menu to be displayed
    this.props.dispatch(datasourceActions.getAll()); // Store all datasources to use in the application
  }

  getUrl(item) {
    // URL is either an external URL or layout/id
    if (!item.url) {
      if (item.layoutId) {
        item.url = "/layout/" + item.layoutId;
      } else if (item.extUrl) {
        item.url = item.extUrl;
      } else {
        item.url = "/layout/" + item.name;
      }
    }
    return item;
  }
  addScreen = () => {
    // addscreen menu item creates a new entry
    return {
      name: tr("add_screen"),
      icon: "icon-plus",
      url: "/addScreen",
      variant: "secondary"
    };
  };

  addChildren(child, parent, tab) {
    let theParent = tab.find(el => el.id === parent);
    if (theParent){
      if (!theParent.children) {
        theParent.children = [child];
      } else if (!theParent.children.find(el => el.id === child.id)) {
        child = this.getUrl(child);
        theParent.children.push(child);
      }
    }
  }

  getMenu(menu) {;
    // adds url to menu items and addscreen item
    let tempMenu = { ...menu };
    tempMenu.items = tempMenu.items.filter(item=>{
      if (!canIDoThis(UIStore.user, "super") && item.superAdmin){
        return (false)}
      return true;
      })

    if (tempMenu && tempMenu.items) {
      tempMenu.items.forEach((item, index) => {
        item.name = tr(item.name);
        if (item.parent && item.parent !== 0) {
          this.addChildren(item, item.parent, tempMenu.items);
        } else {
          item = this.getUrl(item);
        }
      });
    }
    canIDoThis(UIStore.user, "layout_modify") &&
      !tempMenu.items.find(el => el.url === "/addScreen") &&
      tempMenu.items.push(this.addScreen());
    tempMenu.items = tempMenu.items.filter(el => el.parent === 0 || el.url === "/addScreen"); //remove children from main menu
    return tempMenu;
  }

  render() {
    const theMenu = { ...this.props.menu };

    return (
      <div className="app">
        <DefaultHeader />
        {/* Header with top menu: my account datasource etc..*/}
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarForm />
            {theMenu && theMenu.items && <AppSidebarNav navConfig={this.getMenu(theMenu)} {...this.props} />}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Switch>
              {theMenu &&
                theMenu.items &&
                theMenu.items.filter(item => item.extUrl && !item.layoutId).map((item, idx) => (
                  //* Routes to external URLs window.location is used to lead to the URL
                  <Route
                    key={idx}
                    path={this.getUrl(item).url}
                    exact={true}
                    name={item.name}
                    component={() => (window.location = item.extUrl)}
                  />
                ))}
              <Route exact path="/addScreen" render={props => <GridPage page="new" menuId="add" {...props} />} />
              <Route path="/layout" component={GridPage} />
              <Route exact path="/settings" component={Settings} />
              {/* Don't authorize access to datasource menu for unauthorized users*/}
              {canIDoThis(UIStore.user, "datasource_modify") && (
                <Route exact path="/datasource" component={Datasource} />
              )}
              <Redirect from="/" to={DEFAULT_ROUTE} />
              {/* Dashbaord by default item 1... */}
            </Switch>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { menu, authentication } = state;
  return {
    menu,
    authentication
  };
}

const connectedMainComponent = connect(mapStateToProps)(MainComponent);

export { connectedMainComponent as MainComponent };
