import { datasourceConstants } from "./datasource.constants";

export function datasource(state = {}, action) {
  switch (action.type) {
    case datasourceConstants.GET_ALL_SUCCESS:
      return { list: action.datasources };

    case datasourceConstants.STORE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.data]
      };
    case datasourceConstants.DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item=> item.id !== action.id)
      };
    case datasourceConstants.UPDATE_SUCCESS:
    return {
      ...state,
      list: state.list.map(item=> {
        if (item.id === action.data.id) {
           return action.data
        }
        return item;
      })
    };
    default:
      return state;
  }
}
