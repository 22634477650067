import React, {Component} from 'react'
import {Spinner} from 'reactstrap'

class Loader extends Component {
  render() {
    return (
      <div>
        <Spinner type='grow' />
        <Spinner type='grow' />
        <Spinner type='grow' />
      </div>
    );
  }
}

export default Loader;