import React from "react";
import {widgetList} from '../Widgets';
import { tr }  from '../_locales';
import { Dropdown,DropdownToggle,DropdownItem, DropdownMenu } from 'reactstrap';


 export class WidgetList extends React.Component {

  // List of widgets

      
  constructor(props) {
    super(props);
    this.state= {
      dropDown:false,
      chosenWidget: {}
    }

  }

  setWidget(widget)
  {
    this.setState({chosenWidget: widget});
    this.props.onCloseRequest(widget); // call parent's close this will open the widgetlist displayed by parent
    }
  
  toggleDrop()
  {this.setState({dropDown:!this.state.dropDown})}

  render() {

    return (
          <div>
           <Dropdown nav isOpen={this.state.dropDown} toggle={() => {this.toggleDrop();}}>
                  <DropdownToggle nav caret>
                  <i  className="fa fa-plus"></i>&nbsp;{tr('add_widget')}
                  </DropdownToggle>
                  <DropdownMenu>
                   {widgetList.map(widget => 
                     <DropdownItem   key ={widget.type} onClick={this.setWidget.bind(this,widget.inputComponent)} ><i className={widget.icon} />{tr(widget.type+'Input')}</DropdownItem>
                  )}
                  </DropdownMenu>
           </Dropdown>
          </div>
           );
         }
      }