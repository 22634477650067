import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'core-js/fn/array/find';
// import 'core-js/fn/array/includes';
import { Provider } from "react-redux";
import { App } from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { rootReducer } from "./_helpers";
import { loadState, saveState } from "./_helpers/localStorage";
import { routerMiddleware, connectRouter } from "connected-react-router";
import history  from "./history";
import { httpRequestInterceptor, httpResponseInterceptor } from './_middlewares/httpInterceptors';
import {config} from "./config";
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "https://874bb92bdb8b4baf9c69e3b1afd024ba@o101133.ingest.sentry.io/5199589",
  environment: config.ENV,
});

const loggerMiddleware = createLogger();
const persistedState = loadState(); // on charge les données du local Store

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  connectRouter(history)(rootReducer),
  persistedState,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware,
      routerMiddleware(history)
    )
  )
);

store.subscribe(() => {
  // All data store in LocalStore
  saveState({
    authentication: store.getState().authentication,
    currentMenuItem: store.getState().currentMenuItem,
    language: store.getState().language,
    setLayout: store.getState().setLayout,
    menu: store.getState().menu,
    datasource: store.getState().datasource
  });
});
httpRequestInterceptor();
httpResponseInterceptor();

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
