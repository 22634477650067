import React from "react";
import { connect } from "react-redux";
import { tr } from "../_locales";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Label,
  Col,
  FormGroup,
  Form,
  Input,
  Collapse,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { datasourceService } from "./datasource.service";
import { datasourceActions } from "./datasource.actions";
import { TableWidget } from "../Widgets/Table/TableWidget";
import { Waiting } from "../Views/Waiting";

const emptyElement = {
  name: "",
  baseUrl: "",
  format: "",
  type: "",
  create: { active: false, endpoint: "", method: "" },
  update: { active: false, endpoint: "", method: "" },
  trash: { active: false, endpoint: "", method: "" },
  read: { endpoint: "", method: "" },
  distant: { active: false, limit: "", skip: "", sort: "", where: "", total: "" }
};
class Datasource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseTest: false, // to collapse the test area
      collapseAddEdit: false, // to collapse the add/edit area
      testing: false, //wait icon,
      data: {}, // displayed in test result
      editElement: emptyElement
    };
    this.toggleRights = this.toggleRights.bind(this);
    this.handleTestRead = this.handleTestRead.bind(this);
    this.OperationFields = this.OperationFields.bind(this);
  }
  toggleRights(item) {
    const field = item.target.name;
    let obj = {};
    obj[field] = !this.state[field]; //simple boolean used to display element
    obj.editElement = { ...this.state.editElement };
    obj.editElement[field].active = !this.state.editElement[field].active; //change the value of current element rights

    this.setState(obj);
  }

  handleTestRead() {
    // sends back the data to test the returned elements
    this.setState({ collapseTest: true });
    if (!this.baseUrl.value) {
      this.setState({ data: tr("data_not_found") });
    } else {
      this.setState({ testing: true });
      datasourceService.read(this.fillInData()).then(answer => {
        if (answer.error === false) {
          this.setState({ data: answer.data });
          this.setState({ testing: false });
        } else {
          this.setState({ testing: false });
          this.setState({ data: tr(answer.data) });
        }
      });
    }
  }
  deleteDatasource(datasourceId) {
    const { dispatch } = this.props;
    // deletes the datasource
    return dispatch(datasourceActions._delete(datasourceId));
  }
  editDatasource(datasourceId) {
    const element = this.props.datasource.list.filter(item => item.id === datasourceId); // get the right element from redux
    this.setState({ editElement: element[0], collapseAddEdit: true }); // put the item in the state to provide to the inputs
    window.scrollTo(0, 0); // go to the top of page
  }
  getOptions(type, operation) {
    // set the different options depending on action
    switch (type) {
      case "REST":
        switch (operation) {
          case "update":
            return [ "PUT", "PATCH","POST", "GET"];
          case "trash":
            return ["DELETE", "POST", "GET"];
          case "create":
            return ["POST", "GET"];
          default:
            return [];
        }

      default:
        return [];
    }
  }
  fillInData(id) {
    // fill in the data for store or update in the right format
    let data = { read: {}, update: {}, trash: {}, create: {} };
    data.name = this.name.value;
    data.type = this.type.value;
    data.format = this.format.value;
    data.baseUrl = this.baseUrl.value;
    data.read.method = this.readMethod.value;
    data.read.endpoint = this.readEndpoint.value;
    data.trash = {
      active: this.state.editElement.trash.active,
      method: this.trashMethod.value,
      endpoint: this.trashEndpoint.value
    };
    data.update = {
      active: this.state.editElement.update.active,
      method: this.updateMethod.value,
      endpoint: this.updateEndpoint.value
    };
    data.create = {
      active: this.state.editElement.create.active,
      method: this.createMethod.value,
      endpoint: this.createEndpoint.value
    };
    data.distant = {
      active: this.state.editElement.distant.active,
      limit: this.limit.value,
      skip: this.skip.value,
      sort: this.sort.value,
      where: this.where.value,
      total: this.total.value
    };
    if (id) {
      // in update case
      data.id = id;
    }
    return data;
  }

  handleSubmit(event) {
    const { dispatch } = this.props;
    event && event.preventDefault();
    if (!this.state.editElement.id) {
      // in case of edit
      dispatch(datasourceActions.save(this.fillInData())).then(() => {
        this.setState({ collapseAddEdit: false, editElement: emptyElement });
      });
    } else {
      dispatch(datasourceActions.update(this.fillInData(this.state.editElement.id))).then(() => {
        // in case of new element
        this.setState({ collapseAddEdit: false, editElement: emptyElement });
      });
    }
  }
  gotoAdd() {
    this.setState({ collapseAddEdit: true, editElement: emptyElement }); //Open add box and initialise edit content
    window.scrollTo(0, 0); // go to the top of page
  }

  render() {
    const { editElement } = this.state;
    return (
      <div>
        <Nav pills className="navbar-dark bg-white">
          {!this.state.collapse && ( // Save current layout, don't show it if in input mode
            <NavItem>
              <NavLink href="#" onClick={this.gotoAdd.bind(this, "add")}>
                <i className="fa fa-plus" />
                &nbsp;
                {tr("add_source")}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <Collapse isOpen={this.state.collapseAddEdit}>
          <Card>
            <br />
            <CardTitle>
              &nbsp;
              {tr("datasource")}
            </CardTitle>
            <CardBody>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <CardBody>
                  <p className="text-muted">{tr("intro_add_datasource")}</p>
                  <FormGroup row>
                    <Col md="1">
                      <Label htmlFor="name">{tr("name")} </Label>
                    </Col>
                    <Col xs="4" md="4">
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        required
                        defaultValue={editElement.name}
                        innerRef={input => (this.name = input)}
                      />
                    </Col>
                    <Col md="1" />
                    <Col md="2" className="text-right">
                      <Label htmlFor="type">{tr("type_interface")} </Label>
                    </Col>
                    <Col xs="3" md="3">
                      <Input
                        id="type"
                        name="type"
                        type="select"
                        required
                        defaultValue={editElement.type}
                        innerRef={input => (this.type = input)}
                      >
                        <option value="REST">REST</option>
                        <option disabled value="SOAP">
                          SOAP
                        </option>
                        <option disabled value="GraphQL">
                          GraphQL
                        </option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="1">
                      <Label htmlFor="format">{tr("format")} </Label>
                    </Col>
                    <Col xs="4" md="4">
                      <Input
                        id="format"
                        name="format"
                        type="select"
                        required
                        defaultValue={editElement.format}
                        innerRef={input => (this.format = input)}
                      >
                        <option value="JSON">JSON</option>
                        <option disabled value="XML">
                          XML
                        </option>
                        <option disabled value="CSV">
                          CSV
                        </option>
                      </Input>
                    </Col>
                    <Col md="1" />
                    <Col md="2" className="text-right">
                      <Label htmlFor="baseUrl">{tr("baseUrl")} </Label>
                    </Col>
                    <Col xs="3" md="3">
                      <Input
                        id="baseUrl"
                        name="baseUrl"
                        type="text"
                        placeholder="https://"
                        required
                        defaultValue={editElement.baseUrl}
                        innerRef={input => (this.baseUrl = input)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="2">
                      <Label htmlFor="readMethod">{tr("read")} </Label>
                    </Col>
                    <Col md="1">
                      <Label>{tr("method")} </Label>
                    </Col>
                    <Col xs="2" md="2">
                      <Input
                        id="readMethod"
                        name="readMethod"
                        type="select"
                        required
                        defaultValue={editElement.read.method}
                        innerRef={input => (this.readMethod = input)}
                      >
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                      </Input>
                    </Col>
                    <Col md="3" className="text-right">
                      <Label htmlFor="readEndpoint">{tr("endpoint")} </Label>
                    </Col>
                    <Col xs="3" md="3">
                      <Input
                        id="readEndpoint"
                        name="readEndpoint"
                        type="text"
                        defaultValue={editElement.read.endpoint}
                        innerRef={input => (this.readEndpoint = input)}
                      />
                    </Col>
                    <Col md="1">
                      <Button
                        className="btn-ladda"
                        data-style="expand-right"
                        onClick={this.handleTestRead}
                        color="primary"
                      >
                        Test
                      </Button>
                    </Col>
                  </FormGroup>
                  <Collapse isOpen={this.state.collapseTest}>
                    <Card>
                      <CardBody>
                        {this.state.testing && <Waiting />}
                        {!this.state.testing && (
                          <pre style={{ maxHeight: "20em" }}>{JSON.stringify(this.state.data, null, 2)}</pre>
                        )}
                      </CardBody>
                    </Card>
                  </Collapse>
                  {this.OperationFields("update", "REST")}
                  {this.OperationFields("create", "REST")}
                  {this.OperationFields("trash", "REST")}
                  {this.distantManagement()}
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="success">
                    {tr("submit")}
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Collapse>
        <Card className={this.props.theme.current.class}>
          <br />
          <CardTitle>
            &nbsp;
            {tr("list_datasource")}
          </CardTitle>
          <CardBody>
            <TableWidget
              data={{ table: "dataSources" }}
              deleteDatasourceFct={data => this.deleteDatasource(data)} //pass deleteDataSource method to TableWidget for callback
              editDatasourceFct={data => this.editDatasource(data)} //pass editDatasource method to TableWidget for callback
            />
          </CardBody>
        </Card>
      </div>
    );
  }

  OperationFields(operation, type) {
    const { editElement } = this.state;
    return (
      <FormGroup row>
        <Col md="2">
          <FormGroup check className="checkbox">
            <Input
              className="form-check-input"
              type="checkbox"
              id={operation}
              name={operation}
              onChange={this.toggleRights}
              checked={editElement[operation]["active"]}
              innerRef={input => (this.operation = input)}
            />
            <Label check className="form-check-label" htmlFor={operation}>
              {tr(operation)}
            </Label>
          </FormGroup>
        </Col>
        <Col md="1" className={editElement[operation]["active"] ? "text-right" : "d-none"}>
          <Label>{tr("method")} </Label>
        </Col>
        <Col xs="2" md="2" className={editElement[operation]["active"] ? "text-right" : "d-none"}>
          <Input
            id={operation + "Method"}
            name={operation + "Method"}
            type="select"
            defaultValue={editElement[operation]["method"]}
            innerRef={input => (this[`${operation}Method`] = input)}
          >
            {this.getOptions(type, operation).map(item => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </Input>
        </Col>
        <Col md="3" className={editElement[operation]["active"] ? "text-right" : "d-none"}>
          <Label htmlFor={operation + "Endpoint"}>{tr("endpoint")} </Label>
        </Col>
        <Col xs="3" md="3" className={editElement[operation]["active"] ? "text-right" : "d-none"}>
          <Input
            id={operation + "Endpoint"}
            name={operation + "Endpoint"}
            type="text"
            defaultValue={editElement[operation]["endpoint"]}
            innerRef={input => (this[`${operation}Endpoint`] = input)}
            placeholder={operation !== "create" ? "/##id##" : ""}
          />
        </Col>
      </FormGroup>
    );
  }
  distantManagement() {
    const { editElement } = this.state;
    return (
      <div>
        <FormGroup row>
          <Col md="2">
            <FormGroup check className="checkbox">
              <Input
                className="form-check-input"
                type="checkbox"
                id="distant"
                name="distant"
                onChange={this.toggleRights}
                checked={editElement["distant"]["active"]}
                innerRef={input => (this.operation = input)}
              />
              <Label check className="form-check-label" htmlFor={"distant"}>
                {tr("distant")}
              </Label>
            </FormGroup>
          </Col>
          <Col md="3" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Label>{tr("limit")} </Label>
          </Col>
          <Col md="3" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Input
              id={"limit"}
              name={"limit"}
              type="text"
              defaultValue={editElement["distant"]["limit"]}
              innerRef={input => (this[`limit`] = input)}
              placeholder={"?limit=##limit##"}
            />
          </Col>
          <Col md="1" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Label>{tr("skip")} </Label>
          </Col>
          <Col md="3" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Input
              id={"skip"}
              name={"skip"}
              type="text"
              defaultValue={editElement["distant"]["skip"]}
              innerRef={input => (this[`skip`] = input)}
              placeholder={"?skip=##skip##"}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="1" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Label>{tr("Total")} </Label>
          </Col>
          <Col md="3" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Input
              id={"total"}
              name={"total"}
              type="text"
              defaultValue={editElement["distant"]["total"]}
              innerRef={input => (this[`total`] = input)}
              placeholder={"/count"}
            />
          </Col>
          <Col md="1" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Label>{tr("sort")} </Label>
          </Col>
          <Col md="3" className={editElement["distant"]["active"] ? "text-right" : "d-none"}>
            <Input
              id={"sort"}
              name={"sort"}
              type="text"
              defaultValue={editElement["distant"]["sort"]}
              innerRef={input => (this[`sort`] = input)}
              placeholder={"?sort=##sortfield## ##direction##"}
            />
          </Col>
          {/* <Col md="1" className={this.state["distant"] || editElement["distant"]["active"] ? "text-right" : "d-none"}> */}
          <Col md="1" className={"text-right"}>
            <Label>{tr("where")} </Label>
          </Col>
          {/* <Col md="3" className={this.state["distant"] || editElement["distant"]["active"] ? "/id" : "d-none"}> */}
          <Col md="3">
            <Input
              id={"where"}
              name={"where"}
              type="text"
              defaultValue={editElement["distant"]["where"]}
              innerRef={input => (this[`where`] = input)}
              placeholder={'?where={"##field##":{"contains":"##value##"}}'}
            />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { datasource,theme } = state;
  return {
    datasource,
    theme
  };
}

const connectedDatasource = connect(mapStateToProps)(Datasource);

export { connectedDatasource as Datasource };
