import axios from "axios";
import { manageErrors } from "../_api/api";
import { config } from "../config";

export const getEnvironnements = async () => {
  const envs = await axios.get("environnement");
  return envs.data
}
export const getLocales= async () => {
  const locales = await axios.get('formattedLocale/fr');
  return locales
}
