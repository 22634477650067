
export const canIDoThis=(user,right)=>{
  //Le menu de gauche
  if (right === "super" && (user.role === "superadmin" || user.role=== "hyperadmin")){
    return true;
  }else{ // Les menu hyper admin
    if(user && user.role ==="hyperadmin"){
      return true;
    }
  }
  return false;
}