import ax from 'axios';
import { config } from "../config";

export async function getMaintData() {
    let response;

    try {
       response = await ax.get(`${config.API_URL}/maintenance/get`);
    } catch (error) {
        console.log(error)
    }

    return response &&  response.data && response.data.isInMaintenance;
}

export async function setMaintData(isMaint, StartDate = '', EndDate = '') {
    let response;

    try {
       //response = await ax.post(`${config.API_URL}/maintenance/set/isMaint=${isMaint}&dateStart=${StartDate}&dateEnd=${EndDate}`);
       response = await ax.post(`${config.API_URL}/maintenance/set`, {
        isMaint: isMaint,
        dateStart: StartDate,
        dateEnd: EndDate
       });

    } catch (error) {
        return error;
    }

    return response;
}