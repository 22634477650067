import React from "react";
import { connect } from "react-redux";
import { layoutActions } from "../Grid";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import { WidgetList, Widget } from "../Widgets";
import { Nav, NavItem, NavLink, Card, CardHeader, CardBody, Collapse, Container, Label } from "reactstrap";
import { tr } from "../_locales";
import { widgetList } from "../Widgets";
import history from "../history";
import { canIDoThis } from "../_helpers/rightsManager";
import { observer } from 'mobx-react';
import UIStore from '../store/UIStore';
import {checkOtherData} from "../_helpers/otherData";
import MaintenanceModal, {ExitMaintenanceModal} from "./maintenanceModal";
import {getMaintData, setMaintData} from "./maintenance.service"
import { confirmAlert } from "react-confirm-alert";
import Notifications from "react-notification-system-redux";

const fullwidth = true
const ResponsiveReactGridLayout = WidthProvider(Responsive);
@observer
class GridPage extends React.Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 120
  };
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      widgetChosen: null,
      editwidget: null,
      currentUrl: null, // To detect if the URL has changed
      modalIsOpen: false,
      cancelModalIsOpen: false,
      isCheckedSwitch: ""    };
  }

  async componentDidUpdate(prevProps) {
    // As we are reusing the same component throughout the application we must determine when to reupload the layout
    // Here the criteria is the URL change
    const url = window.location.href;
    if (!this.state.url || this.state.url !== url) {
      this.setState({ url: url }); // Inform of the new URL
      const idStart = window.location.pathname.split("/").pop(); // Get the layout number
      idStart &&
        idStart !== "addScreen" &&
        this.props.dispatch(layoutActions.getDefaultLayout(idStart)).then(answer => {
          //get the layout
          if (this.state.collapse && prevProps.data.id) {
            this.closeCollapseInput(); // close input if we change url
          }
          if (this.state.currentLayout !== parseInt(idStart, 10)) {
            this.setState({ currentLayout: parseInt(idStart, 10) });
          }
        });
    }
  }

  async componentDidMount() {
    const idStart = window.location.pathname.split("/").pop(); 
    idStart === "addScreen" && this.onchangeLayout([]); //empty screen

    if(idStart === "12") {
        const isInMaintenance = await getMaintData();

        this.setState({isCheckedSwitch: isInMaintenance})
    } 
  }

  closeCollapseInput() {
    this.setState({ collapse: false, editwidget: null, widgetChosen: null });
  }
  openCollapseInput() {
    this.setState({ collapse: true });
  }

  closeWidgetList(component) {
    this.setState({ widgetChosen: component });
    this.openCollapseInput();
  }
  addActions(actions) {
    actions.foreach(el => {
      this.setState({ [el.i]: el });
    });
  }
  onChangeSwitch(e) {
    if(!this.state.modalIsOpen && e.target.checked) {
      this.setState({ modalIsOpen: e.target.checked, isCheckedSwitch: true });
    } else if (!e.target.checked) {
      confirmAlert({
        message: tr("confirm_exit_maintenance"),
        buttons: [
          { label: tr("yes"), onClick: () => this.modalExit() },
          { label: tr("no"), onClick: () =>  this.cancelModalClose() },
        ],
        closeOnClickOutside: false,
      });
      this.setState({ cancelModalIsOpen: true, isCheckedSwitch: false });
    }
  }
  createElement(el,length) {
    const widthRatio = length ? 100/length :100;
    const width = el.widget.type === "TableWidget" ? `${widthRatio}%`:"auto"
    const boxStyle = {
      minWidth: width,
      backgroundColor: "#fff",
      backgroundClip: "border-box",
      border: "1px solid #c8ced3",
      borderRadius: "0.25rem",
      overflow: "auto",
      width:"100%!important"
    };

    //Check the header and the white backgroung must be displayed must be displayed 
    const cardDisplay = (el.widget.nocard  && !canIDoThis(UIStore.user, "layout_modify"))? false : true;

    return (
      <div key={el.i} data-grid={el} style={cardDisplay && boxStyle} >
        <Card
          className={this.props.theme.current.class}
          style={{ border: "none", backgroundColor: !cardDisplay && "transparent" }}
        >
          {cardDisplay && (
            <CardHeader className="draggable">
              {el.widget && tr(el.widget.title)}
              <div className={"card-header-actions"}>
                {/* Used by the widget to display their actions on the left of other actions */}
                <div className={"card-header-actions widget_class_" + el.i} style={{ float: "left" }} />
                {canIDoThis(UIStore.user, "layout_modify") && (
                  <button className="card-header-action btn " onClick={this.onEditWidget.bind(this, el)}>
                    <i title={tr("edit_widget")} className="icon-pencil" />
                  </button>
                )}
                {canIDoThis(UIStore.user, "layout_modify") && (
                  <button className="card-header-action btn btn-close" onClick={this.onRemoveItem.bind(this, el.i)}>
                    <i title={tr("close_widget")} className="icon-close" />
                  </button>
                )}
              </div>
            </CardHeader>
          )}
          <CardBody>{
            <>
              {el.widget.title === "Environnement" && 

                  //   <FormGroup>
                  //   <Input
                  //     type="switch"
                  //     className="form-switch"
                  //     hecked={true}
                  //     onClick={this.onChangeSwitch.bind(this)}
                  //   />
                  //   <Label check>Maintenance</Label>
                  // </FormGroup>               

                <Label>
                  <input id="id-switch-1"
                       type="checkbox"
                       role="switch"
                       onChange={this.onChangeSwitch.bind(this)}
                       checked={this.state.isCheckedSwitch}
                  />
                  <span className="state">
                    <span className="container">
                      <span className="position">
                      </span>
                    </span>
                  </span>
                  <span className="maint-label">
                    Maintenance
                  </span>
                </Label>
          }
              <Widget data={el.widget} widgetId={el.i} />
            </>
            }</CardBody>
        </Card>
      </div>
    );
  }

  onRemoveItem(i) {
    this.props.dispatch(layoutActions.removeElement(i));
  }
  onEditWidget(element) {
    const theWidget = widgetList.filter(el => el.type === element.widget.type); // we get the right component
    this.setState({ widgetChosen: theWidget[0].inputComponent, collapse: true, editwidget: element }); // we open the input
  }
  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onchangeLayout(layout) {
    this.props.dispatch(layoutActions.changeLayout(layout));
  }

  addDefaultLayout() {
    this.props.dispatch(layoutActions.addDefaultLayout(this.props.data.layout));
  }
  goToUrl(url) {
    history.push(url);
  }
  updateDefLayout() {
    if (window.location.pathname.split("/").pop() === "addScreen") {
      this.props
        .dispatch(layoutActions.addDefaultLayout(this.props.data)) //change layout
        .then(() => {
          //display layout
          this.goToUrl("/layout/" + this.props.data.id);
        });
    } else {
      const data = { id: this.state.currentLayout, layout: this.props.data.layout }; // if no widget save empty layout
      this.props.dispatch(layoutActions.updateDefaultLayout(data));
    }
  }

  addWidget(widget) {
    const i = widget.i;
    delete widget.i;
    if (i) {
      // si on est edition on met à jour l' element
      let modWidget = { ...this.state.editwidget };
      modWidget.widget = widget;
      this.props.dispatch(layoutActions.updateElement(modWidget));
    } else {
      const length = this.props.data && this.props.data.layout ? this.props.data.layout.length : 0;
      const size = widget.type === "BasicWidget" ? 2 : 6; // size 2 for Basic 6 for the others e.g. Tables
      let x = (length * size) % (this.state.cols || 12);
      if (!x){x=0;}
      this.props.dispatch(
        layoutActions.addElement({
          i: "n" + Date.now(),
          x: (length * size) % (this.state.cols || 12),
          y: 0, // puts it at the bottom
          w: size,
          h: size,
          widget: widget
        })
      );
    }
    this.closeCollapseInput();
  }

  async modalOk(dateStart, dateEnd) {
    //const StartDate = dateStart && dateStart.value && (new Date(dateStart.value)).toISOString() //dateStart.valueAsNumber;
    //const EndDate = dateEnd && dateStart.value && (new Date(dateStart.value)).toISOString() //dateEnd.valueAsNumber;
    const StartDate = dateStart && dateStart.value && dateStart.valueAsNumber;
    const EndDate = dateEnd && dateStart.value && dateEnd.valueAsNumber;
    this.setState({
      modalIsOpen: false
    })

    let response = await setMaintData(true, StartDate, EndDate);
    //debugger
    if (response.isAxiosError) {
      this.setState({isCheckedSwitch: false})

      this.props.dispatch(Notifications.error({ message: tr("update_error") , position: "tc"}))
    } else {
      this.setState({isCheckedSwitch: true})
    }
  }
  
  async modalExit() {
    this.setState({
      cancelModalIsOpen: false
    })

    let response = await setMaintData(false);
    debugger
    if (response.isAxiosError) {
      this.setState({isCheckedSwitch: true})
      
      this.props.dispatch(Notifications.error({message: tr("update_error") , position: "tc"}))
    } else {
      this.setState({isCheckedSwitch: false})
    }
  }

  modalClose() {
    this.setState({
      modalIsOpen: false,
      isCheckedSwitch: false 
    });
  }

  cancelModalClose() {
    this.setState({
      cancelModalIsOpen: false,
      isCheckedSwitch: true
    });
  }

  render() {
    const { data, user } = this.props;
    const WidgetInput = this.state.widgetChosen;
    const otherData = checkOtherData(data); 

    return (
      <div>
        {canIDoThis(UIStore.user, "layout_modify") && (
          <Nav pills className="navbar-dark bg-white">
            {!this.state.collapse && ( // Save current layout, don't show it if in input mode
              <NavItem>
                <NavLink href="#" onClick={this.updateDefLayout.bind(this)} style={{ color: "green" }}>
                  <i className="fa fa-save" />
                  &nbsp;
                  {tr("save_layout")}
                </NavLink>
              </NavItem>
            )}
            <WidgetList
              onCloseRequest={data => this.closeWidgetList(data)} // pass Close method to widgetList for callback
              onAddWidget={data => this.addWidget(data)} //pass Addwidget method to widgetList for callback
            />
          </Nav>
        )}

        <Collapse isOpen={!this.state.collapse}>
          {" "}
          {/* Either display the grid*/}
          <ResponsiveReactGridLayout
            isResizable={canIDoThis(UIStore.user, "layout_modify")}
            isDraggable={canIDoThis(UIStore.user, "layout_modify")}
            onLayoutChange={this.onchangeLayout.bind(this)}
            onBreakpointChange={this.onBreakpointChange.bind(this)}
            draggableHandle=".draggable" // only items with class draggable can be dragged e.g. card header only
            {...this.props}
          >
            {data && data.layout && data.layout.map(el => this.createElement(el,data.layout.length))}
          </ResponsiveReactGridLayout>
        </Collapse>
        <Collapse isOpen={this.state.collapse}>
          <Container>
            {/* Or display the widget input*/}
            {this.state.widgetChosen && (
              <WidgetInput
                onAddWidget={data => this.addWidget(data)}
                closeWidget={this.closeCollapseInput.bind(this)}
                editWidget={this.state.editwidget}
                otherData = {otherData}
              />
            )}
          </Container>
        </Collapse>
        <MaintenanceModal modalIsOpen={this.state.modalIsOpen} modalOk={this.modalOk.bind(this)} modalClose={this.modalClose.bind(this)}/>
        {/* <ExitMaintenanceModal exitModalIsOpen={this.state.cancelModalIsOpen} modalExit={this.modalExit.bind(this)} modalCancel={this.cancelModalClose.bind(this)}/> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { data } = state.setlayout;
  const { user } = state.authentication;
  const { theme } = state;

  return {
    data,
    user,
    theme
  };
}
const connectedGridPage = connect(mapStateToProps)(GridPage);
export { connectedGridPage as GridPage };
