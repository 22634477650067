import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,Table,CustomInput,ButtonGroup} from "reactstrap";
import { tr } from "../../../_locales";



  class FaqEdit extends React.Component {

     state={data:null}
     componentDidMount(){
        if (this.props.mode === "edit"){
            const data = this.props.data.data.find(item=>item.id === this.props.id);
            this.setState({data});
        }
    }
    handleSubmit = (event) =>{
       event && event.preventDefault();
       const {question_fr,question_en,answer_fr,answer_en} = this;
       let id = this.state.data ? this.state.data.id : null;

       let values= {id:id,question_fr:question_fr.value,question_en:question_en.value,
                          answer_fr:answer_fr.value, answer_en:answer_en.value};

       this.props.update(values,id,this.state.data,this.props.mode);
    }


     render(){
         const {data} = this.state;
         const {mode} = this.props;
         return(<div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>

                <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ?tr("Update") : tr("Add")}</Button>
                <Button type="back" className="ButtonBack" color="secondary" onClick={this.props.back}>{tr("Back")}</Button>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="question_fr">{tr("question_fr")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="code"name="question_fr"type="text"  required defaultValue={data && data.question_fr}
                                innerRef={input => (this.question_fr = input)}/>
                        </Col> 
                        <Col md="1"/>                       
                        <Col md="1">
                            <Label htmlFor="question_en">{tr("question_en")} </Label>
                        </Col>
                        <Col  md="4">
                        <Input id="code"name="question_en"type="text"   defaultValue={data && data.question_en}
                                innerRef={input => (this.question_en = input)}/>
                        </Col>  
                    </FormGroup>   
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="answer_fr">{tr("answer_fr")} </Label>
                        </Col>
                        <Col  md="11">
                            <Input className="EditDescription" id="answer_fr"name="answer_fr"type="textarea" required defaultValue={data && data.answer_fr}
                                innerRef={input => (this.answer_fr = input)}/>
                        </Col>
                    </FormGroup>        
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="answer_en">{tr("answer_en")} </Label>
                        </Col>
                        <Col  md="11">
                            <Input className="EditDescription" id="answer_en"name="answer_en"type="textarea"  defaultValue={data && data.answer_en}
                                innerRef={input => (this.answer_en = input)}/>
                        </Col>
                    </FormGroup>                                                                 
                </CardBody>
                </Card>
                </Form> 
         </div>);
         }
     

 
        }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedFaqEdit = connect(mapStateToProps)(FaqEdit);
  export { connectedFaqEdit as FaqEdit };