import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Form, Input, FormGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import "../Table/Projipac/projipac.css";

 class TraceWidgetInput extends React.Component {
  handleSubmit(event) {
    event.preventDefault();
    const { onAddWidget, editWidget } = this.props; // Calling parent function onAddWidget with the inputs
    if (editWidget) {
      editWidget.widget.title = this.title.value;
      editWidget.widget.content = this.content.value;
    }
    onAddWidget({
      type: "TraceWidget",
      title: this.title.value,
      content: this.content.value,
      i: editWidget ? editWidget.i : null
    });
  }
  render() {
    const { datasource, editWidget } = this.props;
    return (
      <div>
        <br />
        <h4>{tr("TraceWidgetInput")}</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="title">{tr("title")}: </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  defaultValue={editWidget ? editWidget.widget.title : ""}
                  innerRef={input => (this.title = input)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="content">{tr("content")}: </Label>
                <Input
                  id="table"
                  name="table"
                  type="select"
                  defaultValue={editWidget ? editWidget.widget.content : ""}
                  innerRef={input => (this.content = input)}
                >
                  {datasource.list.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col xs="1">
              <Button type="submit" color="success">
                {tr("submit")}
              </Button>
            </Col>
            <Col xs="1">
              <Button>{tr("back")}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { datasource } = state;
  return {
    datasource
  };
}
const connectedTraceWidgetInput= connect(mapStateToProps)(TraceWidgetInput);
export { connectedTraceWidgetInput as TraceWidgetInput };