import React from "react";
import { datasourceService } from "../../Datasource/datasource.service";
import Notifications from "react-notification-system-redux";
import { tr } from "../../_locales";
import { getUrlParams } from "../../_helpers/stringManager";
import { connect } from "react-redux";
import classNames from "classnames";
import { mapToCssModules } from "reactstrap/lib/utils";
import { Card, CardBody } from "reactstrap";

class ColorCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null, // data displayed
      waitingData: true
    };
  }
  componentWillReceiveProps(nextProps) {
    // if (nextProps.data.datasource !== this.props.data.datasource || nextProps.data.chart !== this.props.data.chart) {
    // the table data has been udated so need to reload the right data
    this.retrieveData();
  }
  retrieveData() {
    const { list, data } = this.props;
    const found = list && list.filter(item => item.id === parseInt(data.datasource, 10));
    const params = getUrlParams();
    found && found.length > 0 &&
      datasourceService.read(found[0], params).then(answer => {
        if (answer.error === false) {
          this.setState({ data: answer.data, waitingData: false }); // we store the datasource
        } else {
          this.setState({ waitingData: false });
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
        }
      });
  }
  componentDidMount() {
    this.retrieveData();
  }

  render() {
    const { data } = this.props;
    // demo purposes only

    const classes = mapToCssModules(classNames("animated fadeIn", "text-white", data.color), null);

    return (
      <Card className={classes} color={data.color} style={{marginBottom:0}}>
        <CardBody>
       <div className="h4 m-0">{this.state.data}</div>
          <div>{data.content}</div>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { datasource } = state;
  const { list } = state.datasource;
  return {
    datasource,
    list
  };
}
const connectedColorCard = connect(mapStateToProps)(ColorCard);
export { connectedColorCard as ColorCard };
