import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Form, Input, FormGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import { datasourceService } from "../../Datasource/datasource.service";
import { typeChart } from "./chart.settings";


class ChartWidgetInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waiting: false,
      keys: []
    };
  }  
  componentDidMount(){
    const { editWidget } = this.props;
    if (editWidget){
    this.getFields(editWidget.widget.datasource);
    }

  }
  handleSubmit(event) {
    event.preventDefault();
    const { onAddWidget, editWidget } = this.props; // Calling parent function onAddWidget with the inputs
    if (editWidget) {
      editWidget.widget.title = this.title.value;
      editWidget.widget.chart = this.chart.value;
      editWidget.widget.datasource = this.datasource.value;
      editWidget.widget.datafield = this.datafield.value;
      editWidget.widget.labelfield = this.labelfield.value;
    }
    onAddWidget({
      type: "ChartWidget",
      title: this.title.value,
      chart: this.chart.value,
      datasource: this.datasource.value,
      datafield:this.datafield.value,
      labelfield:this.labelfield.value,
      i: editWidget ? editWidget.i : null
    });
  }
  getFields(value=null){
      this.setState({ waiting: true });
      value= value && typeof(value)==="string"? value:this.datasource.value;
      const found = this.props.datasource.list.filter(item => item.id === parseInt(value, 10));
      const params = this.props.otherData ? this.props.otherData:null;
      found[0] &&
        datasourceService.read(found[0],params).then(answer => {
          if (answer.error === false && answer.data[0]) {
            const keys = Object.keys(answer.data[0]); // Keys of the first item in the table
            this.setState({ keys: keys,  waiting: false }); // we store the keys in state
          } else {
            this.setState({ keys: [], types: [], waiting: false, collapseColumns: false }); // Reset state if empty
          }
        });
    
  }
  render() {
    const { datasource, editWidget } = this.props;
    return (
      <div>
        <br />
        <h4>{tr("ChartWidgetInput")}</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="title">{tr("title")}: </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  defaultValue={editWidget ? editWidget.widget.title : ""}
                  innerRef={input => (this.title = input)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="chart">{tr("chart_type")}: </Label>
                <Input
                  id="chart"
                  name="chart"
                  type="select"
                  defaultValue={editWidget ? editWidget.widget.chart : ""}
                  innerRef={input => (this.chart = input)}
                >
                  {Object.keys(typeChart).map(item => {
                    return (
                      <option key={item} value={item}>
                        {tr(item)}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="datasource">{tr("Source")}: </Label>
                <Input
                  id="datasource"
                  name="datasource"
                  type="select"
                  required
                  defaultValue={editWidget ? editWidget.widget.datasource : ""}
                  innerRef={input => (this.datasource = input)}
                  onChange={this.getFields.bind(this)}>
                >
                  <option/>
                  {datasource.list.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="datafield">{tr("data_field")}: </Label>
                {!this.state.waiting &&
                <Input
                  id="datafield"
                  name="datafield"
                  type="select"
                  required
                  defaultValue={editWidget ? editWidget.widget.datafield : ""}
                  innerRef={input => (this.datafield = input)}
                >
                  <option/>
                  {this.state.keys.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Input>}
              </Col>
            </Row>
          </FormGroup>    
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="labelfield">{tr("label_field")}: </Label>
                {!this.state.waiting &&
                <Input
                  id="labelfield"
                  name="labelfield"
                  type="select"
                  defaultValue={editWidget ? editWidget.widget.labelfield : ""}
                  innerRef={input => (this.labelfield = input)}
                >
                  <option/>
                  {this.state.keys.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Input>}
              </Col>
            </Row>
          </FormGroup>                 
          <Row>
            <Col xs="1">
              <Button type="submit" color="success">
                {tr("submit")}
              </Button>
            </Col>
            <Col xs="1">
              <Button onClick={this.props.closeWidget}>{tr("back")}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { datasource } = state;
  return {
    datasource
  };
}
const connectedChartWidgetInput = connect(mapStateToProps)(ChartWidgetInput);
export { connectedChartWidgetInput as ChartWidgetInput };
