import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as formReducer } from 'redux-form'
import { authentication } from '../User';
import { registration } from '../User';
import { users } from '../User';
import { setlayout } from '../Grid';
import { language } from '../_locales';
import { menu,theme } from '../Menu/menu.reducer';
import { datasource } from '../Datasource/datasource.reducer';

const appReducer  = combineReducers({
    authentication,
    registration,
    users,
    notifications,
    setlayout,
    menu,
    language,
    datasource,
    form: formReducer,
    theme: theme
});
export const rootReducer = (state, action) => {
    if (action.type === 'USERS_LOGOUT') {
        // the store is emptied on action LOGOUT just keep the previous language and theme
      state = {language:{current:state.language.current},theme:{current:state.theme.current}}
    }
  
    return appReducer(state, action)
  }

