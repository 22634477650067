import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Form, Input, FormGroup, Row } from "reactstrap";

export class BasicWidgetInput extends React.Component {
  handleSubmit(event) {
    event.preventDefault();
    const { onAddWidget,editWidget } = this.props; // Calling parent function onAddWidget with the inputs
    if (editWidget)
    {
      editWidget.widget.title= this.title.value;
      editWidget.widget.content= this.content.value;
    }
    onAddWidget({
      type: "BasicWidget",
      title: this.title.value,
      content: this.content.value,
      i: editWidget ? editWidget.i : null
    });
  }
  render() {
    const { editWidget } = this.props;
    return <div><br/>
    <h4>{tr("BasicWidgetInput")}</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="title">{tr("title")}: </Label>
                <Input id="title" name="title" type="text" defaultValue={editWidget ?editWidget.widget.title:''} innerRef={input => (this.title = input)} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="content">{tr("content")}: </Label>
                <Input id="content" name="content" type="textarea" defaultValue={editWidget ?editWidget.widget.content:''} innerRef={input => (this.content = input)} />
              </Col>
            </Row>
          </FormGroup>
          <Row>
            <Col xs="1">
              <Button type="submit" color="success">{tr("submit")}</Button>
            </Col> 
            <Col xs="1">
              <Button >{tr("back")}</Button>
            </Col>            
          </Row>
        </Form>
      </div>;
  }
}
