import { datasourceConstants } from "./datasource.constants";
import api,{ manageErrors } from "../_api/api";
import Notifications from "react-notification-system-redux";
import { tr } from "../_locales";

export const datasourceActions = {
  getAll,
  save,
  update,
  _delete
};

// function getAll(){
//   const datasource = [
//     {
//       name: "Menu",
//       type: "REST",
//       format: "JSON",
//       baseUrl: "http://localhost:1337/menudefs",
//       read: { method: "GET", endpoint: "" },
//       update: { active: true, method: "PATCH", endpoint: "/##id##" },
//       create: { active: true, method: "POST", endpoint: "" },
//       trash: { active: true, method: "DELETE", endpoint: "/##id##" },
//       distant: { active: false, limit: "", skip: "", sort: "", where: "", total: "" }
//     }
//   ];
//   return { type: datasourceConstants.GET_ALL_SUCCESS, datasource };
// }

function getAll() {
  return dispatch => {

    api.datasource.getAll().then(
      datasources => {
        dispatch(success(datasources));
      },
      error => {
        const errorInfo = manageErrors(error,"get_error");
        dispatch(Notifications.error({ message: tr(errorInfo.data)}));
      }
    );
  };

  function success(datasources) {
    return { type: datasourceConstants.GET_ALL_SUCCESS, datasources };
  }

}
function save(data) {
  return dispatch => {
    return api.datasource.add(data).then(
      answer => {
        dispatch(success(answer));
        dispatch(Notifications.success({ message: tr("datasource_store_success") }));
      }, error => {
        const errorInfo = manageErrors(error,"save_error");
        dispatch(Notifications.error({ message: tr(errorInfo.data) }));
      });
  };

  function success(data) {
    return { type: datasourceConstants.STORE_SUCCESS, data };
  }

}
function update(data) {
  return dispatch => {
    return api.datasource.update(data).then(
      answer => {
        dispatch(success(answer));
        dispatch(Notifications.success({ message: tr("datasource_update_success") }));
      }, error => {
        const errorInfo = manageErrors(error,"update_error");
        dispatch(Notifications.error({ message: tr(errorInfo.data) }));
      });
  };

  function success(data) {
    return { type: datasourceConstants.UPDATE_SUCCESS, data };
  }

}
function _delete(id) {
  return dispatch => {

    return api.datasource.delete(id).then(    
      answer => {
        dispatch(success(id));
        dispatch(Notifications.success({ message: tr("datasource_delete_success") }));
      },
      error => {
        const errorInfo = manageErrors(error,"delete_error");
        dispatch(Notifications.error({ message:  tr(errorInfo.data)  }));
      }
    );
  };

  function success(id) {
    return { type: datasourceConstants.DELETE_SUCCESS, id };
  }

}
