import React from "react";
import { Table } from "reactstrap";
import { tr } from "../../_locales";
export class ObjectType extends React.Component {

  constructor(props) {
    super(props);

    // props
    this.level = props.level || 0;
    this.maxLevel = 5;
    this.nextLevel = this.level === this.maxLevel ? 0 : this.level + 1
  }



  render() {
    let data = this.props.data;
    let result = null;
    if (data === null || this.nextLevel === 0) {
      // null
      result = (<span className="null">null</span>);
    }
    else if (typeof(data) === typeof({}) && data !== null) {
      // Object or Array
      let rows = Object.keys(data).map((name) => {
        return (
          <tr key={name}>
            <th>{tr(name)}</th>
            <td><ObjectType data={data[name]} level={this.nextLevel} /></td>
          </tr>
        );
      });
      result = (
        <Table responsive bordered >
          <tbody >
            {rows}
          </tbody>
        </Table>
      );
    }
    else {
      // something else
      result = (<span >{data}</span>);
    }
    return result;
  }
}