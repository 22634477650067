import { localeConstants } from "../_locales";
const initialLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  "fr-FR";
export function language(state = { current: initialLanguage }, action) {
  switch (action.type) {
    case localeConstants.CHANGE_LANGUAGE:
      return { current: action.language };
    default:
      return state;
  }
}
