import UIStore from '../store/UIStore';
import ax from 'axios';
import { config } from '../config';

export function setToken (token) {
  UIStore.setToken(token);
}

export function logout () {
  UIStore.logout();
}

/**
 * Get userinfos
 * @returns {Promise<boolean>}
 */
export async function getUserInfos () {
  try {
    const response = await ax.get(`${config.API_URL}/auth/userinfos`);
    UIStore.logged(response.data);
    return true;

  } catch (error) {
    console.error(error);
    return false;

  }

}