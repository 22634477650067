import React, {Component} from 'react';
import {Card, CardBody,
        Button, FormGroup, Label, Form, Input} from 'reactstrap';
import {tr} from '../../../_locales';
import UIStore from '../../../store/UIStore';

class ParameterEditSuperAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      id: null,
      data: null,
      value: '',
    };
  }

  componentDidMount() {
    const data = this.props.mode === 'add' ? null : this.props.data.data.find(item => item.id === this.props.id);
    const code = data ? data.code : '';
    const value = data ? data.value : '';
    const id = data ? data.id : null;
    this.setState({data, code, id, value});
  }

  render() {
    return (
      <Form onSubmit={this.submit}>
       <Card>
          <h4>{tr('Env parameter')}</h4>
          <Button className='ButtonBack' color='secondary' onClick={this.back}>{tr('Back')}</Button>
          <Button className='ButtonUpdate' color='success' onClick={this.submit}>{tr('Update')}</Button>
          <CardBody>
            <FormGroup>
              <Label>{tr('Code')}</Label>
              <Input value={this.state.code} onChange={e => {this.setState({code: e.target.value})}} placeholder={tr('Code') + '...'}/>
            </FormGroup>
            <FormGroup>
              <Label>{tr('Value') + ' (' + tr('Optional') + ')'}</Label>
              <Input value={this.state.value} onChange={e => {this.setState({value: e.target.value});}} placeholder={tr('Value') + '...'} />
            </FormGroup>
            {this.state.code === "termsAndConditions" && <i>Please forward your file to our technical teams</i>}
          </CardBody>
        </Card>
      </Form>
    )
  }

  //-------------------------- events -------------------------------//
  submit = (e) => {
    const {id, code, data, value} = this.state;
    if (e)
      e.preventDefault();
    this.props.update({id, code, value}, id, data, this.props.mode);
  }

  back = () => {
    this.props.back();
  }
}

class ParameterEditAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      id: null,
      data: null
    };
  }

  async componentDidMount() {
    const data = this.props.mode === 'add' ? null : this.props.data.data.find(item => item.id === this.props.id);
    const value = data ? data.value : '';
    const id = data ? data.id : null;
    this.setState({data, value, id});
  }

  render() {
    return (
      <div>
          { this.props.mode === "add" ?
          <Modal isOpen>
            <ModalHeader>{tr('Unauthorized')}</ModalHeader>
            <ModalBody>
              <p>{tr('Your are not able to add a parameter code.')}</p>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={this.props.back}>{tr('Back')}</Button>
            </ModalFooter>
          </Modal>
          :
          <Form onSubmit={this.submit}>
            <Modal isOpen>
              <ModalHeader>{tr('Env parameter')}</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label>{tr('Value')}</Label>
                  <Input value={this.state.value} onChange={e => {this.setState({value: e.target.value})}} placeholder={tr('Value') + '...'}/>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color='secondary' onClick={this.props.back}>{tr('Back')}</Button>
                <Button color='primary' onClick={this.submit}>{tr('Done')}</Button>
              </ModalFooter>
            </Modal>
          </Form>
        }
      </div>
    )
  }

  submit = (e) => {
    if (e) e.preventDefault();
    const {id, data, value} = this.state;
    this.props.update({id, value, code: this.props.data.code}, id, data, this.props.mode);
  }
   
}

const ParameterEdit = function(props) {
  return (
    <div>
      { UIStore.user.role === "admin" ?
        <ParameterEditAdmin {...props} />
        :
        <ParameterEditSuperAdmin {...props} />
      }
    </div>
  )
}
export {ParameterEdit};