import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Form, Input, FormGroup, Row } from "reactstrap";
import { connect } from "react-redux";

const  listColors=[
  {color:"#20a8d8",label:"blue",btColor:"primary"},
  {color:"#c8ced3",label:"grey",btColor:"secondary"},
  {color:"#4dbd74",label:"green",btColor:"success"},
  {color:"#f86c6b",label:"red",btColor:"danger"},
  {color:"#ffc107",label:"yellow",btColor:"warning"},
  {color:"#63c2de",label:"light_blue",btColor:"info"},
  {color:"#f0f3f5",label:"white",btColor:"light"},
  {color:"#2f353a",label:"dark",btColor:"dark"},
];

class ColorCardInput extends React.Component {

 
  handleSubmit(event) {
    event.preventDefault();
    const { onAddWidget, editWidget } = this.props; // Calling parent function onAddWidget with the inputs
    if (editWidget) {
      editWidget.widget.title = this.title.value;
      editWidget.widget.color = this.color.value;
      editWidget.widget.datasource = this.datasource.value;
      editWidget.widget.content = this.title.content;
      editWidget.widget.nocard = true; // to indicate not to display as card
    }
    onAddWidget({
      type: "ColorCard",
      title: this.title.value,
      color: this.color.value,
      datasource: this.datasource.value,
      content: this.content.value,
      nocard:true,
      i: editWidget ? editWidget.i : null
    });
  }

  render() {
    const { datasource, editWidget } = this.props;
    return (
      <div>
        <br />
        <h4>{tr("ColorCardInput")}</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="title">{tr("title")}: </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  defaultValue={editWidget ? editWidget.widget.title : ""}
                  innerRef={input => (this.title = input)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="color">{tr("color_type")}: </Label>
                <Input
                  id="color"
                  name="color"
                  type="select"
                  defaultValue={editWidget ? editWidget.widget.color : ""}
                  innerRef={input => (this.color = input)}
                >
                  {listColors.map(item => {
                    return (
                      <option  key={item.color} value={item.btColor} style={{backgroundColor:item.color}}>  
                      {item.label}       
                      </option>
                                            
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="datasource">{tr("Source")}: </Label>
                <Input
                  id="datasource"
                  name="datasource"
                  type="select"
                  required
                  defaultValue={editWidget ? editWidget.widget.datasource : ""}
                  innerRef={input => (this.datasource = input)}
                  >
                  <option/>
                  {datasource.list.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={6}>
                <Label htmlFor="content">{tr("text_content")}: </Label>
                <Input
                  id="content"
                  name="content"
                  type="text"
                  defaultValue={editWidget ? editWidget.widget.content : ""}
                  innerRef={input => (this.content = input)}
                />
              </Col>
            </Row>
          </FormGroup>
          <Row>
          {(!editWidget || !editWidget.i) && <Col xs="1">
              <Button type="submit" color="success">
                {tr("submit")}
              </Button>
            </Col>}
            <Col xs="1">
              <Button onClick={this.props.closeWidget}>{tr("back")}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { datasource } = state;
  return {
    datasource
  };
}
const connectedColorCardInput = connect(mapStateToProps)(ColorCardInput);
export { connectedColorCardInput as ColorCardInput };
