import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Form, Input, FormGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import { datasourceService } from "../../Datasource/datasource.service";
import { arrayMove } from "react-sortable-hoc";
import { SortableListFields } from "./TableFieldInput";

class TableWidgetInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [],
      types: [],
      input:[],
      finalValues:[]
    };
  }
  componentDidMount() {
    const { editWidget } = this.props;
    if (editWidget) {
      this.getColumns(editWidget.widget.table);
    }
  }

  toggle(index, type) {
    let modKeys = [...this.state.keys];
    modKeys[index][type] = !modKeys[index][type];
    // link and file are exclusive
    modKeys[index]["link"] = type === "file" && modKeys[index][type] ? false : modKeys[index]["link"];
    modKeys[index]["file"] = type === "link" && modKeys[index][type] ? false : modKeys[index]["file"];
    this.setState({ keys: modKeys });
  }
  handleSubmit(event) {
    event.preventDefault();
    const { onAddWidget, editWidget } = this.props;
    let newKeys = this.state.keys.map(
      (item, index) =>
        item.link
          ? {
              ...item,
              linkValue: this.state.finalValues["link" + index].value,
              linkField: this.state.finalValues["linkfield" + index].value,
              linkOwnField: this.state.finalValues["linkownfield" + index].value
            }
          : item // if there is a link add the link value
    );

    onAddWidget({
      type: "TableWidget",
      title: this.title.value,
      table: this.table.value,
      fields: newKeys,
      i: editWidget ? editWidget.i : null
    });
  }
  isListConfigured(){
    const { editWidget } = this.props;
    return(editWidget && editWidget.widget && editWidget.widget.fields.length > 0)
  }
  getListOrdered(item){
    // if (this.isListConfigured())
    // {
    //   let table=[];
    //   this.props.editWidget.widget.fields.forEach((field)=>{
    //       table.push(field.field);
    //   });
    //   const newFields = Object.keys(item).filter(x=>!table.includes(x));//find new fields
    //   table.push.apply(newFields);
    //   return table;
    // }
    // else {
      return Object.keys(item);
    // }
  }
  getColumns(value = null) {
    const { editWidget } = this.props;
    let fields = [];
    this.setState({ waiting: true });
    value = value && typeof value === "string" ? value : this.table.value;
    const found = this.props.datasource.list.filter(item => item.id === parseInt(value, 10));
    const params = this.props.otherData ? this.props.otherData:null;

    found[0] &&
      datasourceService.read(found[0],params).then(answer => {
        if (answer.error === false && answer.data[0]) {
          const keys = this.getListOrdered(answer.data[0]);
          // const keys = Object.keys(answer.data[0]); // Keys of the first item in the table
          keys.forEach((item, index) => {
            let finalItem= {field: item,display:true,edit:true,link:false,file:false,linkVale:"",linkField:"",linkOwnField:""};
            if (this.isListConfigured()) {
              // if list is configured check settings
              let found = editWidget.widget.fields.find(fields => fields.field === item);
              if (found) {
                finalItem.display = found.display;
                finalItem.edit = found.display ? found.edit :false;
                finalItem.file = found.file ? found.file : false;
                finalItem.link = found.link;
                if (finalItem.link) {
                  finalItem.linkValue = found.linkValue;
                  finalItem.linkField = found.linkField ? found.linkField : "";
                  finalItem.linkOwnField = found.linkOwnField ? found.linkOwnField : item;
                }
              }
            }
            fields[index] = finalItem;
          });
          let types = [];
          for (let value of Object.values(answer.data[0])) {
            types = [...types, typeof value]; // Types fo the fields of the first item
          }
          this.setState({ keys: fields, types: types, waiting: false }); // we store the keys, types in state
        } else {
          this.setState({ keys: [], types: [], waiting: false }); // Reset state if empty
        }
      });
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      keys: arrayMove(this.state.keys, oldIndex, newIndex)
    });
  };

  render() {
    const { datasource, editWidget } = this.props;
    return (
      <div>
        <br />
        <h4>{tr("TableWidgetInput")}</h4>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="title">{tr("title")}: </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  defaultValue={editWidget ? editWidget.widget.title : ""}
                  innerRef={input => (this.title = input)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label htmlFor="table">{tr("datasources")}: </Label>
                <Input
                  id="table"
                  name="table"
                  type="select"
                  defaultValue={editWidget ? editWidget.widget.table : ""}
                  innerRef={input => (this.table = input)}
                  onChange={this.getColumns.bind(this)}
                >
                  >
                  {datasource.list.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
          </FormGroup>
          {!this.state.waiting && (
            <FormGroup>
              {this.state.keys.length > 0 && (
                <SortableListFields
                  items={this.state.keys}
                  toggle_fct={(index, type) => this.toggle(index, type)}
                  useDragHandle={true}
                  onSortEnd={this.onSortEnd}
                  finalValues={this.state.finalValues}
                />
              )}
            </FormGroup>
          )}
          <Row>
            {(!editWidget || !editWidget.i) && (
              <Col xs="1">
                <Button type="submit" color="success">
                  {tr("add")}
                </Button>
              </Col>
            )}
            <Col xs="1">
              <Button>{tr("back")}</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { datasource } = state;
  const { data } = state.setlayout; // to remove for testing
  return {
    datasource,
    data
  };
}
const connectedTableWidgetInput = connect(mapStateToProps)(TableWidgetInput);
export { connectedTableWidgetInput as TableWidgetInput };
