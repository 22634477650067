import React from "react";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button} from "reactstrap";
import { tr } from "../../../_locales";

  export class LocaleEdit extends React.Component {
     state={data:null}
     componentDidMount(){
        let group = null;
        if(this.props.id){
            group = this.props.data.data.find(item=>item.id === this.props.id);
            this.setState({data:group})
        }
    }

     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {code,value,french} = this;
        let id = this.state.data ? this.state.data.id : null;
        let itemUpdate = {id:id,code:code.value,value:value.value,french:french.value};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }

     render() {
         const {data} = this.state;
         const {mode} = this.props;

         return(<div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                    <h4>{tr("Infos")}</h4>
                    <CardBody>
                    <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                    <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ?tr("Update") : tr("Add")}</Button>
                        <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="code">{tr("code")} </Label>
                        </Col>
                        <Col  md="10">
                            <Input id="code"name="code"type="text"   defaultValue={data && data.code}
                                innerRef={input => (this.code = input)}/>
                        </Col>
                        <Col md="1"/>
                        </FormGroup>
                        <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="value">{tr("French Label")} </Label>
                        </Col>
                        <Col  md="10">
                            <Input id="value"name="value"type="text"   disabled defaultValue={data && data.french}
                                innerRef={input => (this.french = input)}/>
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Col md="1">
                            <Label htmlFor="value">{tr("value")} </Label>
                          </Col>
                          <Col  md="10">
                            <Input id="value"name="value" type="textarea"   defaultValue={data && data.value}
                                   innerRef={input => (this.value = input)}/>
                          </Col>
                        </FormGroup>
                    </CardBody>
                    </Card>
                </Form>
         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }