import axios from "axios";
import { userActions } from "../User/user.actions";
import { store } from "../index";

export default {
  user: {
    login: (username, password) =>
      axios.post("/users/login", JSON.stringify({ username, password })).then(res => res.data),
    logout: () => axios.post("/users/logout"),
    update: data => axios.patch(`/users/${data.id}`, JSON.stringify(data)).then(res => res.data),
    getNonAdmins: ()=>axios.get('standardusers').then(res=>res.data)
  },
  menu: {
    get: () => axios.get("/menudefs").then(res => res.data),
    addLayout: (menuId, layoutId) =>
      axios.patch(`menudefs/${menuId}`, JSON.stringify({ layoutId })).then(res => res.data)
  },
  layout: {
    addDefault: layout => axios.post("layoutdefs", JSON.stringify(layout)).then(res => res.data),
    updateDefault: layout => axios.patch(`layoutdefs/${layout.id}`, JSON.stringify(layout)).then(res => res.data),
    getDefault: id => axios.get(`layoutdefs/${id}`).then(res => res.data),
    deleteDefault: id => axios.delete(`layoutdefs/${id}`).then(res => res.data)
  },
  datasource: {
    add: data => axios.post("dataSources", JSON.stringify(data)).then(res => res.data),
    update: data => axios.patch(`dataSources/${data.id}`, JSON.stringify(data)).then(res => res.data),
    getAll: () => axios.get(`dataSources`).then(res => res.data),
    delete: id => axios.delete(`dataSources/${id}`).then(res => res.data)
  },
  accessories:{
    getAll: ()=>axios.get(`accessories/all`).then(res=>res.data),
    getCompAcc: (code,env)=>axios.get(`accessories/hp/code=${code}&env=${env}`).then(res=>res.data),
    getDepIncAcc : (code,env,all=false)=>axios.get(`accessories/depinc/code=${code}&env=${env}&all=${all}`).then(res=>res.data),
    getAllGroups: lang=>axios.get(`/accessorygroup/lang=${lang}`).then(res=>res.data),
    updateGroup: (id,lang)=>axios.delete(`/accessorygroup/lang=${lang}&id=${id}`)
  },
};

export const manageErrors = (error, defaultError) => {
  if (error.response && error.response.status) {
    if (error.response.status === 401) {
      return { error: true, data: "no_rights" };
    } else if (error.response.status === 498) {
      store.dispatch(userActions.logout()); // Log out if token has expired or is invalid and display appropriate message
      return { error: true, data: "not_authentified" };
    }
    else{
      if (error.response.data && error.response.data.err){
        return { error: true, data: error.response.data.err };
      }
      return { error: true, data: defaultError };
    }
  } else {
    return { error: true, data: defaultError };
  }
};
