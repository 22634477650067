import React from "react";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button} from "reactstrap";
import { tr } from "../../../_locales";

  export class AccessoryGroupEdit extends React.Component {
     state={data:null}
     componentDidMount(){
        let group = null;
        if(this.props.id){
            group = this.props.data.data.find(item=>item.id === this.props.id);
            this.setState({data:group})
        }
    }

     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {code,description,order} = this;
        let id = this.state.data ? this.state.data.id : null;
        let itemUpdate = {id:id,description:description.value,order:order.value};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }

     render() {
         const {data} = this.state;
         const {mode} = this.props;

         return(<div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                    <h4>{tr("Infos")}</h4>
                    <CardBody>
                    <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                    <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ?tr("Update") : tr("Add")}</Button>
                        <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="description">{tr("description")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="description"name="description"type="text"   defaultValue={data && data.description}
                                innerRef={input => (this.description = input)}/>
                        </Col>
                          <Col md="1">
                            <Label htmlFor="description">{tr("order")} </Label>
                          </Col>
                          <Col  md="4">
                            <Input id="order"name="order"type="number"   defaultValue={data && data.order}
                                   innerRef={input => (this.order = input)}/>
                          </Col>
                        </FormGroup>
                    </CardBody>
                    </Card>
                </Form>
         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }