import React, {Component} from 'react'
import {Card, CardBody, Label,
        Form, FormGroup, Input, Button, FormFeedback} from 'reactstrap';
import FileUploader from '../../../_components/FileUploader';
import Select from 'react-select';
import Axios from 'axios';
import {tr} from '../../../_locales';
import UIStore from '../../../store/UIStore';

class TutorialEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categorySelected: null,
      categoriesLoading: true,
      categoriesLoadingErr: false,

      video: null,
      description: '',

      id: null,
      data: null,

      errCategory: false,
      errVideo: false,
      errdescription: false,
    };

  }

  async componentDidMount() {
    const data = this.props.mode === 'add' ? null : this.props.data.data.find(item => item.id === this.props.id);
    const categorySelected = data ? data.category_code : null;
    const video = data ? data.video : null;
    const description = data ? data.description : '';
    const id = data ? data.id : null;
    this.setState({data, video, description, categorySelected, id});
    try {
      let categories = await Axios.get('/tutorials/categories/env=' + UIStore.environment + '&lang=' + UIStore.lang);
      categories =  categories.data.map(i => {return ({label: i.value, value: i.code})});
      this.setState({
        categories,
        categorySelected: categorySelected || categories[0] || null,
        categoriesLoading: false,
      });
      if (this.props.mode === 'edit') {
        let results = categories.filter(i => i.value === data.category_code);
        if (results.length === 1)
          this.setState({categorySelected: results[0]});
        else
          this.setState({categorySelected: null});
      }
    } catch (e) {
      this.setState({categoriesLoadingErr: true});
    }
  }

  render() {
    return(
      <Form onSubmit={this.submit}>
        <Card>
          <h4>{tr('Tutorial edit')}</h4>
          <Button className='ButtonBack' onClick={this.props.back} color='secondary'>{tr('Back')}</Button>
          <Button className='ButtonUpdate' onClick={this.submit} color='success'>{tr('Update')}</Button>
          <CardBody>
            <p>{tr('Tutorial need TUTORIAL_ code in translate for categories')}</p>

            <FormGroup>
              <Label>{tr('Category') + ':'}</Label>
              <Select value={this.state.categorySelected} options={this.state.categories}
                      onChange={(e) => this.setState({categorySelected: e})} isLoading={this.state.categoriesLoading}/>
              {this.state.errCategory && <p style={{color: 'red'}}>{tr('No category selected.')}</p>}
            </FormGroup>
            <FormGroup>
              <Label>{tr('Description') + ':'}</Label>
              <Input type='textarea' placeholer={tr('Description') + '...'} invalid={this.state.errdescription}
                     value={this.state.description} onChange={e => this.setState({description: e.target.value})}/>
              <FormFeedback>{tr('No description provided.')}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label>{tr('Video') + ':'}</Label>
              <FileUploader type={['video/*']}
                            onUploaded={e => {this.setState({video: e});}}
                            disableDelete={this.props.mode !== 'add'}
                            value={this.props.mode === 'add' ? this.state.video : null}
                            forceText={(this.props.mode === 'edit' && this.state.video) ? this.state.video.name : null}/>
              {this.state.errVideo && <p style={{color: 'red'}}>{tr('No video provided')}</p>}
            </FormGroup>
          </CardBody>
        </Card>
      </Form>
    )
  }

  //-------------------------- events -------------------------------//

  submit = (e) => {
    if (e)
    e.preventDefault();
    let errCounter = 0;
    const {id, description, video, categorySelected, data} = this.state;

    this.setState({
      errCategory: false,
      errVideo: false,
      errdescription: false
    });
    if (!categorySelected) {
      errCounter++;
      this.setState({errCategory: true});
    }
    if (!description) {
      errCounter++;
      this.setState({errdescription: true});
    }
    if (!video) {
      errCounter++;
      this.setState({errVideo: true});
    }
    if (!errCounter) {
      this.props.update({id, description, video, environnement_id: UIStore.environment, category_code: categorySelected.value,
                        videoName:video.name, category:categorySelected.label}, id, data, this.props.mode);
    }

  }
}

export {TutorialEdit};