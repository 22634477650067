export const datasourceConstants = {
  GET_ALL_SUCCESS: "GET_ALL_DATASOURCE_SUCCESS",

  STORE_SUCCESS: "STORE_DATASOURCE_SUCCESS",

  DELETE_SUCCESS: "DELETE_DATASOURCE_SUCCESS",

  UPDATE_SUCCESS: "UPDATE_DATASOURCE_SUCCESS",

  UPDATE_REQUEST: "UPDATE_ELEMENT_REQUEST"
};
