import React from "react";
import { axiosDatasource } from "../../_api/apiDatasource";
import { connect } from "react-redux";
import { tr } from "../../_locales";
import { Button, Label, Col, FormGroup, Form, Input } from "reactstrap";
import Select from 'react-select';
import UIStore from "../../store/UIStore";
import * as FileSaver from "file-saver";
import Axios from 'axios';

import {config} from "../../config";

class TraceWidget extends React.Component {
  state = { nbTraces: "", currentEnv: "", noSolution: false }

  componentDidMount() {
    const test = this.props;
    //this.getData();
  }
  async getData(info) {
    const { list, data } = this.props;
    const found = list.filter(item => item.id === parseInt(data.content, 10));
    debugger
    if (found.length > 0) {
      const url = info ? found[0].baseUrl + "/" + info : found[0].baseUrl;
      const excelBuffer = await axiosDatasource("GET", url, null, "get_error", 'blob');

      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const data = new Blob([excelBuffer.data], { type: fileType });
      FileSaver.saveAs(data, "projipac4_export" + fileExtension);
    }
  }
  handleSubmit = (event) => {
    event && event.preventDefault();
    const { dateStart, dateEnd } = this;
    const start = dateStart && dateStart.valueAsNumber ? dateStart.valueAsNumber : '';
    //on ajoute une journée pour aller jusqu'à minuit
    const end = dateEnd && dateEnd.valueAsNumber ? dateEnd.valueAsNumber + 86400000 : '';
    const env = this.state.currentEnv && this.state.currentEnv.value ? this.state.currentEnv.value : '';
    this.getData(`dateStart=${start}&dateEnd=${end}&env=${env}&noSolution=${this.state.noSolution}`)
  }

  onExportFTP = async () => {
    try {
      const response = await Axios.post(`${config.API_URL}/projectTraces/export`);
  
    } catch (error) {
      console.error(error);
    }
  }

  changeEnv = (e) => {
    this.setState({ currentEnv: e })
  }
  render() {
    const { nbTraces, traces, currentEnv } = this.state;

    return ( // Currently only displays the content nothing fancy
      <div>
        <Form onSubmit={this.handleSubmit.bind(this)} className="Traces">
          <p className="text-muted">{tr("Filtrer les traces")}</p>
          <FormGroup row>
            <Col md="1">
              <Label htmlFor="dateStart">{tr("Date début")} </Label>
            </Col>
            <Col xs="2" md="2">
              <Input id="dateStart" name="dateStart" type="date" innerRef={input => (this.dateStart = input)} />
            </Col>
            <Col md="1">
              <Label htmlFor="dateEnd">{tr("Date Fin")} </Label>
            </Col>
            <Col xs="2" md="2">
              <Input id="dateEnd" name="dateEnd" type="date" innerRef={input => (this.dateEnd = input)} />
            </Col>
            <Col md="1">
              <Label htmlFor="dateEnd">{tr("Environnement")} </Label>
            </Col>
            <Col xs="3" md="3">
              <Select autosize={true} options={UIStore.envs} className="select "
                value={currentEnv} onChange={this.changeEnv} />
            </Col>
            <Col md="1">
              <Button type="submit" color="success">{tr("Exporter")}</Button>
            </Col>
          </FormGroup>
          <FormGroup row>
          {/* <Button color="success" onClick={this.onExportFTP}>{tr("ExporterFTP")}</Button> */}
            <Col md="2">
              <FormGroup check className="checkbox">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="noSolution"
                  name="noSolution"
                  onChange={(e) => this.setState({noSolution: e.target.checked})}
                  checked={this.state.noSolution}
                />
                <Label className="form-check-label" htmlFor="noSolution" style={{marginTop: '0'}}>
                  {tr("Etudes sans solutions")}
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>
        </Form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { datasource } = state;
  const { list } = state.datasource;
  return {
    datasource,
    list
  };
}

const connectedTraceWidget = connect(mapStateToProps)(TraceWidget);

export { connectedTraceWidget as TraceWidget };