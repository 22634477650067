import intl from "react-intl-universal";

export function tr(item) {
  let test = intl.get(item).defaultMessage(item);
  return (test);
}
export const locales = {
  "fr": require("../_locales/fr-FR.json"),
  "en": require("../_locales/en-US.json"),

};
