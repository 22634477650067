import React, {Component} from 'react'
import {Card, CardBody,
        Button, Input, Form, FormGroup, Label, Container,
        Row, Col} from 'reactstrap';
import { tr } from '../../../_locales';
import Rater from '../../../_components/rate';
import {getLocales} from "../../../_api/apiProjipac";

class SatisfactionView extends Component {

  state={data: null, ACCESS_FACILITY: null, ERGONOMY: null, SIMPLICITY: null,
         FIABILITY: null, RAPIDITY: null, LISIBILITY: null, INFORMATIONS: null,
         DESIGN: null, TOOL_GIVE_HELP_SATISFACTION: null,
	       GIVE_IT_HELP_SATISFACTION: null, GIVE_IT_HELP_PLUS_SATISFACTION: null,
         APP_IS_HELPFULL: null, INTUITIVENESS: null, SUGGESTION: null,
         locales:null
        };

  locales=null;

  async componentDidMount() {
    const locales = await getLocales();

    const data = this.props.mode === "add" ? null : this.props.data.data.find(item=>item.id === this.props.id);
    this.setState({
      data,
      ACCESS_FACILITY: data.data.ACCESS_FACILITY,
      ERGONOMY: data.data.ERGONOMY,
      SIMPLICITY: data.data.SIMPLICITY,
      FIABILITY: data.data.FIABILITY,
      RAPIDITY: data.data.RAPIDITY,
      LISIBILITY: data.data.LISIBILITY,
      INFORMATIONS: data.data.INFORMATIONS,
      DESIGN: data.data.DESIGN,
      TOOL_GIVE_HELP_SATISFACTION: data.data.TOOL_GIVE_HELP_SATISFACTION,
      GIVE_IT_HELP_SATISFACTION: data.data.GIVE_IT_HELP_SATISFACTION,
      GIVE_IT_HELP_PLUS_SATISFACTION: data.data.GIVE_IT_HELP_PLUS_SATISFACTION,
      APP_IS_HELPFULL: data.data.APP_IS_HELPFULL,
      INTUITIVENESS: data.data.INTUITIVENESS,
      SUGGESTION: data.data.SUGGESTION,
      locales: locales.data
    });
  }

  render() {
    return (
      this.state.locales &&
      <Card>
        <h4>{tr('SATISFATION FORM')}</h4>
        <Button className='ButtonBack' onClick={this.props.back} color='secondary'>{tr('BACK')}</Button>
        <CardBody>
          {this.state.data &&
            <Form onSubmit={() => {}}>
              <FormGroup>
                <RateLine name={this.state.locales['ACCESS_FACILITY']} value={this.state.ACCESS_FACILITY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['ERGONOMY']} value={this.state.ERGONOMY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['SIMPLICITY']} value={this.state.SIMPLICITY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['FIABILITY']} value={this.state.FIABILITY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['RAPIDITY']} value={this.state.RAPIDITY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['LISIBILITY']} value={this.state.LISIBILITY} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['INFORMATIONS']} value={this.state.INFORMATIONS} />
              </FormGroup>
              <FormGroup>
                <RateLine name={this.state.locales['DESIGN']} value={this.state.DESIGN} />
              </FormGroup>
              <FormGroup>
                <Container><Row>
                  <Col md={4}>{this.state.locales['TOOL_GIVE_HELP_SATISFACTION']}</Col>
                  <Col md={3}>{this.state.locales[this.state.TOOL_GIVE_HELP_SATISFACTION]}</Col>
                </Row></Container>
              </FormGroup>
              <FormGroup>
                <Container><Row>
                  <Col md={4}>{this.state.locales['GIVE_IT_HELP_SATISFACTION']}</Col>
                  <Col md={3}>{this.state.locales[this.state.GIVE_IT_HELP_SATISFACTION]}</Col>
                </Row></Container>
              </FormGroup>
              <FormGroup>
                <Label>{this.state.locales['GIVE_IT_HELP_PLACEHOLDER']}</Label>
                <Input type='textarea' disabled value={this.state.GIVE_IT_HELP_PLUS_SATISFACTION} />
              </FormGroup>
              <FormGroup>
                <Label>{this.state.locales['SUGGESTION']}</Label>
                <Input type='textarea' disabled value={this.state.SUGGESTION} />
              </FormGroup>
            </Form>
          }
        </CardBody>
      </Card>
    );
  }
}

const RateLine = (props) => (
  <Container>
    <Row>
      <Col md={4}><Label>{tr(props.name)}:</Label></Col>
      <Col md={3}><Rater value={props.value} /></Col>
    </Row>
  </Container>
)

export {SatisfactionView};