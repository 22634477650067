import { observable, action, isComputed } from 'mobx';
import makeInspectable from 'mobx-devtools-mst';

class UIStore {

  @observable appReady = false;
  @observable user = null;
  @observable token = null;
  @observable isAuthenticated = false;
  @observable lang = null;
  @observable projilang = null;
  @observable environment ;
  @observable envs = [];
  @observable tablePageSize = 10;

  constructor() {
    this.lang = localStorage.getItem('_lang') || 'fr-FR'; //TODO a binder avec projilang
    const token = localStorage.getItem('_token');
    this.environment = localStorage.getItem("projipac_env") ;
    this.projilang = localStorage.getItem("projipac_lang");
    this.tablePageSize = localStorage.getItem("tablePageSize") || 10;
    this.isAuthenticated = token !== null;
    if(this.isAuthenticated) {
      this.token = token;
      let user = localStorage.getItem('_user');
      if (user) {
        this.user = JSON.parse(user);
      }
    }
    makeInspectable(this);
  }

  @action setToken(token) {
    localStorage.setItem('_token', token);
    this.token = token;
  }

  @action logged(user) {
    localStorage.setItem('_user', JSON.stringify(user));
    this.user = user;
    this.isAuthenticated = true;
  }

  @action logout() {
    localStorage.removeItem('_token');
    localStorage.removeItem('_user');
    this.isAuthenticated = false;
    this.user = null;
  }

  @action appIsReady() {
    this.appReady = true;
  }

  @action setLanguage(lang) {
    localStorage.setItem('_lang', lang);
    this.lang = lang;
  }
  @action setEnvs(envs) {
    this.envs = envs;
  }
  @action setEnvironnement(env) {
    localStorage.setItem('projipac_env', env);
    this.environment = env;
  }
  @action setLangProjipac(language) {
    localStorage.setItem('projipac_lang', language);
    this.projilang = language;
  }
  @action setPageSize(pageSize) {
    localStorage.setItem('tablePageSize', pageSize);
    this.tablePageSize = pageSize;
  }
  

}

export default new UIStore();