import UIStore from '../store/UIStore';

export const checkOtherData= (data) => {

const env = UIStore.environment;
const lang = UIStore.projilang;
if (env){return{env:`env=${env}&lang=${lang}`}}

return null
}
