import React from "react";
import { datasourceService } from "../../Datasource/datasource.service";
import Notifications from "react-notification-system-redux";
import { tr } from "../../_locales";
import { getUrlParams } from "../../_helpers/stringManager";
import { connect } from "react-redux";
import { ObjectType } from "./ObjectType";
import {Label} from 'reactstrap';

class ObjectWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], // data displayed
      waitingData: true
    };
  }

  componentDidMount() {
    const { list, data } = this.props;
    const found = list.filter(item => item.id === parseInt(data.content, 10));
    const params = getUrlParams();
    found.length > 0 &&
      datasourceService.read(found[0], params).then(answer => {
        this.setState({ waitingData: false });
        if (answer.error === false) {
          if (typeof answer.data === "object") {
            this.setState({ data: answer.data }); // we store the datasource
          } else {
            this.props.dispatch(Notifications.error({ message: tr("return_not_object"), position: "tc" }));
          }
        } else {
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
        }
      });
  }
  render() {
    return (
      <div>
        {this.state.waitingData && (
          <Label ><i>{tr('loading')}</i></Label>
        )}
        {!this.state.waitingData && <ObjectType data={this.state.data} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { datasource } = state;
  const { list } = state.datasource;
  return {
    datasource,
    list
  };
}
const connectedObjectWidget = connect(mapStateToProps)(ObjectWidget);
export { connectedObjectWidget as ObjectWidget };
