import React, {Component} from 'react';
import {Card, CardBody, Input, Container, Row, Col,
  Button, Form, FormGroup, Label, FormFeedback} from 'reactstrap';
import FileUploader from '../../../_components/FileUploader';
import {tr} from '../../../_locales';
import UIStore from '../../../store/UIStore';

class LegalHelpEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      id: null,
      lang: '',
      img1: '',
      img2: '',
      img1Data: null,
      img2Data: null,
      environnement_id: null,

      errImg1: false,
      errImg2: false,
    }
  }

  componentDidMount() {
    const data = this.props.mode === 'add' ? null : this.props.data.data.find(item => item.id === this.props.id);
    const id = data ? data.id : null;
    const img1 = data ? data.img1 : '';
    const img2 = data ? data.img2 : '';
    const img1Data = data ? data.img1Data : null;
    const img2Data = data ? data.img2Data : null;
    const lang = data ? data.lang : '';
    const environnement_id = data ? data.environnement_id : null;
    this.setState({img1, img2, img1Data, img2Data, lang, environnement_id, id, data});
  }

  render() {
    return (
      <Form onSubmit={this.submit}>
        <Card>
          <h4>{tr('Legal help')}</h4>
          <CardBody>
            <Button className='ButtonBack' onClick={this.props.back} color='secondary'>{tr('Back')}</Button>
            <Button className='ButtonUpdate' onClick={this.submit} color='success'>{tr('Update')}</Button>
            <div style={{marginTop: '10px'}}></div>
            <Container>
              <Row>
                <Col lg='6'>
                  <FormGroup>
                    <Label>{tr('Image 1') + ':'}</Label>
                    <FileUploader type={['image/*']}
                                  onUploaded={e => {this.setState({img1Data: e});}}
                                  disabledDelete={this.props.mode !== 'add'}
                                  value={this.props.mode === 'add' ? this.state.img1Data : null}
                                  forceText={(this.props.mode === 'edit' && this.state.img1Data) ? this.state.img1Data.name : null}
                                  />
                    {this.state.errImg1 && <p style={{color: 'red'}}>{tr('No image provided')}</p>}
                  </FormGroup>
                </Col>
                <Col lg='6'>
                  <FormGroup>
                    <Label>{tr('Image 2') + ':'}</Label>
                    <FileUploader type={['image/*']}
                                  onUploaded={e => {this.setState({img2Data: e});}}
                                  disabledDelete={this.props.mode !== 'add'}
                                  value={this.props.mode === 'add' ? this.state.img2Data : null}
                                  forceText={(this.props.mode === 'edit' && this.state.img2Data) ? this.state.img2Data.name : null}
                                  />
                    {this.state.errImg2 && <p style={{color: 'red'}}>{tr('No image provided')}</p>}
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Form>
    )
  }

  submit = (e) => {
    if (e)
      e.preventDefault();
    let errCounter = 0;
    const {img1Data, img2Data, id, data} = this.state;

    if (!img1Data) {
      errCounter++;
      this.setState({errImg1: true});
    }
    /*if (!img2Data) {
      errCounter++;
      this.setState({errImg2: true});
    }*/

    if (!errCounter) {
      this.props.update({id, img1Data, img2Data}, id, data, this.props.mode);
    }
  }
}

export {LegalHelpEdit};