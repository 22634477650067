import React from "react";
import { tr } from "../../_locales";
import { Button, Label, Col, Input, Row, Collapse } from "reactstrap";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <i className="fa fa-arrows-alt" />); // This can be any component you want
const SortableFieldItem = SortableElement(({ idx, item, toggle_fct, keys,finalValues }) => (
  <Row key={idx}>
    <Col md="1">
      <DragHandle />
    </Col>
    <Col md="2">
      <Input key={idx} id={`field${item.field}`} name={`field${item.field}`} disabled type="text" value={item.field} />
    </Col>
    <Col md="3">
      <Button
        title={tr("display_field")}
        key={idx}
        outline
        onClick={() => toggle_fct(idx, "display")}
        disabled={item.field === "id"}
        color={
          item.display ? "primary" : "secondary" // don't manage id field
        }
      >
        {item.display && <i className="fa fa-eye" />}
        {!item.display && <i className="fa fa-eye-slash" />}
      </Button>
      <Button
        title={tr("editable")}
        key={idx + "edit"}
        outline
        onClick={() => toggle_fct(idx, "edit")}
        color={item.edit ? "primary" : "secondary"}
      >
        <i className="fa fa-pencil" />
      </Button>      
      <Button
        title={tr("file_type")}
        key={idx + "file"}
        outline
        onClick={() => toggle_fct(idx, "file")}
        color={item.file ? "primary" : "secondary"}
      >
        <i className="fa fa-file-o" />
      </Button>
      <Button
        title={tr("link_other_data")}
        key={idx + "link"}
        outline
        onClick={() => toggle_fct(idx, "link")}
        color={item.link ? "primary" : "secondary"}
      >
        <i className="fa fa-external-link" />
      </Button>
    </Col>
    <Col md="2">
      <Collapse isOpen={item.link}>
        <Label>{tr("used_field")}: </Label>
        <Input
          key={idx + "linkownfield"}
          id={`linkownfield${item.field}`}
          name={`linkownfield${item.field}`}
          type="select"
          defaultValue={keys[idx].linkOwnField ? keys[idx].linkOwnField : item.field}
          innerRef={input => (finalValues[`linkownfield${idx}`] = input)}
        >
          <option />
          {keys.length &&
            keys.map(el => {
              return (
                <option key={el.field} value={el.field}>
                  {el.field}
                </option>
              );
            })}
        </Input>
      </Collapse>
    </Col>
    <Col md="1">
      <Collapse isOpen={item.link}>
        <Label>{tr("screen")}: </Label>
        <Input
          key={idx + "link"}
          id={`link${item.field}`}
          name={`link${item.field}`}
          type="text"
          defaultValue={keys[idx].linkValue}
          innerRef={input => (finalValues[`link${idx}`] = input)}
        />
      </Collapse>
    </Col>
    <Col md="2">
      <Collapse isOpen={item.link}>
        <Label>{tr("dest_field")}: </Label>
        <Input
          key={idx + "linkfield"}
          id={`linkfield${item.field}`}
          name={`linkfield${item.field}`}
          type="text"
          defaultValue={keys[idx].linkField}
          innerRef={input => (finalValues[`linkfield${idx}`] = input)}
        />
      </Collapse>
    </Col>
  </Row>
));

export const SortableListFields = SortableContainer(({ items, toggle_fct,finalValues }) => {
  return (
    <ul>
      {items.map((item, idx) => (
        <SortableFieldItem key={`item-${idx}`} index={idx} idx={idx} item={item} toggle_fct={toggle_fct} keys={items} finalValues={finalValues}/>
      ))}
    </ul>
  );
});
