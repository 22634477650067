import React, { useState } from 'react';
import { Input, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, Form, FormGroup, Button, Row } from 'reactstrap'


export default function MaintenanceModal({ modalIsOpen, modalOk, modalClose}) {
    const [selectedVal, setSelectedVal] = useState("now");
    const [date, setDate] = useState({dateStart:0,dateEnd:0})

    //let dateStart = 0 , dateEnd = 0;

    function selectChange(e) {
        setSelectedVal(e.target.value);
    }

    return (<Modal isOpen={modalIsOpen} size="lg" style={{maxWidth: '450px'}}>
        <ModalHeader>
            <Row>
                <Col>
                    <span>Maintenance</span>
                </Col>
            </Row>
        </ModalHeader>
        <ModalBody>
            <Form>
                <Col>
                    <Input id="now" name="now" type="radio" value="now" checked={selectedVal === "now"} onChange={(e) => selectChange(e)} />
                    <Label htmlFor="now">Now</Label>
                </Col>
                <Col style={{display: 'flex'}}>
                <Input id="date" name="date" type="radio"  value="date" checked={selectedVal === "date"} onChange={(e) => selectChange(e)} />
                    <Input 
                        id="dateStart" 
                        name="dateStart" 
                        type="datetime-local"  
                        onChange={(e) => setDate({...date, dateStart: e.target})} 
                        disabled={selectedVal !== "date"}
                        style={{width: '190px'}}/>
                    <Label htmlFor="dateStart" style={{padding: '5px'}}>To</Label>
                    <Input 
                        id="dateEnd" 
                        name="dateEnd" 
                        type="datetime-local" 
                        onChange={(e) => setDate({...date, dateEnd: e.target})} 
                        min={date.dateStart.value}
                        disabled={selectedVal !== "date"}
                        style={{width: '190px'}}/>
                </Col>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button style={{ backgroundColor: "#3a9d5d" }} onClick={()=>modalOk(date.dateStart, date.dateEnd)}>Ok</Button>
            <Button  onClick={modalClose}>Cancel</Button>
        </ModalFooter>
    </Modal>);
}

export function ExitMaintenanceModal({exitModalIsOpen, modalCancel, modalExit}) {
    return (
        <Modal isOpen={exitModalIsOpen} size="sm" className="modalSaveProject">
            <ModalBody>
                <div>Confirm the exit from maintenance</div>
            </ModalBody>            
            <ModalFooter>
            <Button style={{ backgroundColor: "#3a9d5d" }} onClick={modalExit}>Confirm</Button>
            <Button onClick={modalCancel}>Cancel</Button>
        </ModalFooter>
        </Modal>
    )
}