import React from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import history from "../../history";
import { FileEditor, fileFormatter } from "./FileEditor";
import { tr } from "../../_locales";

const commonColumnOptions = {
  sort: true,
  // filter: textFilter({ placeholder: " " }),
  sortCaret: (order, column) => caretDisplay(order)
};
const caretDisplay = order => {
/*   if (!order) return <i className="fa fa-sort fa-xs" />; */
   if (order === "asc")
    return (
      <font color="red">
        <i className="fa fa-sort-asc fa-xs" />
      </font>
    );
  else if (order === "desc")
    return (
      <font color="red">
        <i className="fa fa-sort-desc fa-xs" />
      </font>
    ); 
  return null;
};

export const manageColumns = (fields, answer, hiddenColumns, remote, filter, sort, dispatch) => {
  if (fields && fields.length > 0) {
    return fields.map(item => {
      // either a link type a file or a standard cell type
      let column = item.link ? linkColumn(item) : item.file ? fileColumn(item, dispatch) : standardColumn(item);
      if (remote) {
        // when in distant mode we must feedback the fields entered before as defaultValue
        if (filter && filter[item["field"]]) {
          column.filter = textFilter({ placeholder: " ", defaultValue: filter[item["field"]] });
        } else {
          column.filter = textFilter({ placeholder: " ", defaultValue: "" });
        }
        if (sort && sort.sortfield === item.field) {
          column.sortCaret = () => caretDisplay(sort.direction);
        }
      }
      //sort numerically if id column or column name *_id*
      if (item.field === "id" || item.field.includes("id")) {
        column.sortFunc = (a, b, order) => {
          if (order === "desc") {
            return Number(b) - Number(a);
          } else {
            return Number(a) - Number(b);
          }
        };
      }
      return column;
    });
  } else if (answer.length > 0) {
    // otherwise take all the fields of the first item
    return firstItemColumn(answer[0], hiddenColumns);
  }
};
const standardColumn = item => ({
  // configuration of a standard column
  ...commonColumnOptions,
  dataField: item.field,
  hidden: !item.display,
  csvExport: item.display,
  text: tr(item.field),
  classes: item.field === ("id" || "environnement_id")?"columnToHide":item.field,
  headerClasses:item.field === ("id" || "environnement_id")?"columnToHide":"",
  editable:item.edit,
  align : item.field === "description"?"left":"center",
  // style: {"whiteSpace": 'nowrap'}
});
const linkColumn = item => ({
  // configuration of a column with links
  ...commonColumnOptions,
  dataField: item.field,
  csvExport: item.display,
  hidden: !item.display,
  text: tr(item.field),
  classes: item.field,
  style: () => {
    return { color: "RoyalBlue", cursor: "pointer" };
  },
  events: {
    onClick: event => {
      displayLink(item.linkValue, item.linkField, item.linkOwnField, event.target.textContent, event);
    }
  }
});
const fileColumn = (item, dispatch) => ({
  // configuration of a file column to input files
  ...commonColumnOptions,
  dataField: item.field,
  csvExport: false,
  hidden: !item.display,
  text: tr(item.field),
  classes: item.field,
  style: () => {
    return { color: "blue", cursor: "pointer" };
  },
  formatter: fileFormatter,
  editorRenderer: (editorProps, value, row, rowIndex, columnIndex) => (
    <FileEditor key={rowIndex} {...editorProps} value={value} dispatch={dispatch} />
  )
});

const firstItemColumn = (firstItem, hiddenColumns) => {
  // configuration of a  column with keys based on first item retrieved
  const keys = Object.keys(firstItem).filter(item => !hiddenColumns.includes(item));
  return keys.map(item => {
    return { ...commonColumnOptions, dataField: item, text: tr(item) };
  });
};

const displayLink = (value, field, ownField, param, event) => {
  if (param && param.split("http").length > 1) {
    // real http link
    window.open(param);
  } else {
    let finalParam = param;
    if (ownField) {
      const searchParam = event.target.closest("tr").getElementsByClassName(ownField); // find the element on the same row with the field class
      finalParam = searchParam.length > 0 ? searchParam[0].textContent : finalParam;
    }
    history.push(`/layout/${value}?${field}=${finalParam}`);
  }
};

export const getIcons = (fct_delete, fct_edit , fct_add = null) => (
  <div>
    {fct_delete && (
      <a style={{ color: "red" }} onClick={fct_delete}>
        <i className="fa fa-trash"  />
      </a>
    )}
    &nbsp;&nbsp;&nbsp;&nbsp;
    {fct_edit && (
      <a style={{ color: "black" }} onClick={fct_edit}>
        <i className="fa fa-pencil" />
      </a>
    )}
    {fct_add && (
      <a style={{ color: "green" }} onClick={fct_add}>
        <i className="fa fa-plus" />
      </a>
    )}
  </div>
);
export const getFilterInfo = filters => {
  let filterInfo = "";
  Object.keys(filters).forEach(filter => {
    filterInfo = { ...filterInfo, [filter]: filters[filter].filterVal };
  });
  return filterInfo;
};
export const sameFilter = (type, newFilter, oldFilter) => {
  // checks if old and new filters are the same to prevent infinite loop in change
  if ((type = "filter")) {
    if (
      !newFilter ||
      !oldFilter ||
      Object.keys(newFilter).length === 0 ||
      !Object.keys(oldFilter).length === 0 ||
      Object.keys(newFilter).length !== Object.keys(oldFilter).length
    ) {
      return false;
    }
    let same = true;
    Object.keys(newFilter).forEach(e => {
      if (!oldFilter[e]) {
        same = false;
      }
      if (newFilter[e].filterVal !== oldFilter[e]) {
        same = false;
      }
    });

    return same;
  }
  return false;
};
