export const verifyCheckedEnv = (data,storedEnvs) =>{
    let envs = {};
    if (data && data.envs){
        if((typeof data.envs) === "string") {
            data.envs = data.envs.split(",");
        }
        storedEnvs.forEach(item=>
           {envs[item.code] = data.envs.find(elt=>elt == item.value) ? true :false; })
    }
    else{
        storedEnvs.forEach(item=> {envs[item.code] = false});
    }
    return envs;
    // this.setState({envs:envs});
}
export const getCheckedEnvs = (stateEnvs,storeEnvs)=>{
    // const {envs} = this.state;
    let returnEnvs = [];
    for (const property in stateEnvs){
        if (stateEnvs[property]){
           const env =storeEnvs.find(item=>item.code === property);
           if(env){ returnEnvs.push(env.value.toString());}
        }
    }
    return returnEnvs;
}