import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,Table,CustomInput} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import HpDocEdit from './HpDocEdit';
import {verifyCheckedEnv,getCheckedEnvs} from "./env_management";

  class HpSuperEdit extends React.Component {
     state={data:null,envs:{},select:false}
     componentDidMount(){
        let hp = null;
                hp = this.props.data.data.find(item=>item.id === this.props.id);
                if(hp){
                    const envs = verifyCheckedEnv(hp, UIStore.envs);
                    this.setState({data:hp,envs:envs});
                }
     }

     toggleCheck = (env) =>{
        const {envs} = this.state;
        if (envs){
            let newEnvs = {...envs};
            newEnvs[env] = !envs[env];
            this.setState({envs:newEnvs});
        }
     }

     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {envs} = this.state;
        const {code,reference,photo,classe_energie,code_schema,gamme} = this;
        const checkedEnvs = getCheckedEnvs(envs,UIStore.envs);
        let countries =[];
        for (var country in envs){
            if(envs[country]){ countries.push(country)}
        }

        let id = this.state.data ? this.state.data.id : null;
        let itemUpdate = {id:id,code:code.value,reference_def:reference.value,envs:checkedEnvs,
                          photo:photo.value,classe_energie:classe_energie.value,code_schema:code_schema.value,
                          gamme:gamme.value,countries:countries.join(", ")};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }

     render() {
         const {data} = this.state;
         
         const {mode} = this.props;
         
         return(<div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ?tr("Update") : tr("Add")}</Button>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="reference">{tr("reference")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="reference"name="reference"type="text"   defaultValue={data && data.reference_def}
                            innerRef={input => (this.reference = input)}/>
                    </Col>
                    <Col md="1"/>
                    <Col md="1">
                        <Label htmlFor="code">{tr("code")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="code"name="code"type="text"  required defaultValue={data && data.code}
                            innerRef={input => (this.code = input)}/>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="photo">{tr("photo")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="photo"name="photo"type="text"   defaultValue={data && data.photo}
                            innerRef={input => (this.photo = input)}/>
                    </Col>
                    <Col md="1"/>
                    <Col md="1">
                        <Label htmlFor="classe_energie">{tr("classe_energie")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="classe_energie"name="classe_energie"type="text"   defaultValue={data && data.classe_energie}
                            innerRef={input => (this.classe_energie = input)}/>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="code_schema">{tr("code_schema")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="code_schema"name="code_schema"type="text"   defaultValue={data && data.code_schema}
                            innerRef={input => (this.code_schema = input)}/>
                    </Col>
                    <Col md="1"/>
                    <Col md="1">
                        <Label htmlFor="gamme">{tr("gamme")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="gamme"name="gamme"type="text"   defaultValue={data && data.gamme}
                            innerRef={input => (this.gamme = input)}/>
                    </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
            {data && <HpDocEdit data={data}/>}

            <h4>{tr("environnement_mgt")}</h4>
            <FormGroup row className="Checkboxes">
            {UIStore.envs && UIStore.envs.map((env,index)=>
                      <CustomInput className="Checkbox" type="checkbox" id={`env_${index}`} name={env.value}
                      onChange={()=>this.toggleCheck(env.code)} checked={this.state.envs[env.code]}
                      label={env.label} />
                )}
            </FormGroup>

            </Card>
            </Form>
         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedHpSuperEdit = connect(mapStateToProps)(HpSuperEdit);
  export { connectedHpSuperEdit as HpSuperEdit };