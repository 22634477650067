import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,Table} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import api from "../../../_api/api";
import HpDocEdit from './HpDocEdit';
import Select from 'react-select';
import {verifyCheckedEnv,getCheckedEnvs} from "./env_management";

  class HpEdit extends React.Component {
    state={data:null,allAcc:null,compatibleAcc:null,addAcc:false}

    componentDidMount() {
         //let allAcc= null;
         const hp = this.props.data.data.find(item=>item.id === this.props.id);

            api.accessories.getAll().then(allAccs=>{
                const accs = allAccs.map(item => {
                    return { 
                        id: item.id.toString(),
                        reference: item.reference_def,
                        value: item.code,label:`${item.code}  ${item.reference_def}`
                    } 
                });

                if (this.props.mode ==="add"){
                    this.setState({allAcc:accs});
                } else { // edit mode
                    //hp = this.props.data.data.find(item=>item.id === this.props.id);

                    if(hp) {
                        const envs = verifyCheckedEnv(hp, UIStore.envs);
                        let acc = this.getCompAccessory(hp,allAccs);
                        this.setState({compatibleAcc:acc,allAcc:accs,data:hp,envs:envs});
                    }
                }
            })

            // api.accessories.getCompAcc(hp.code,UIStore.environment).then(acc=>{
            //     this.setState({compatibleAcc:acc});})

            this.setState({data:hp});
    }

    handleSubmit = (event) =>{
        event && event.preventDefault();

        const {compatibleAcc, envs} = this.state;
        const {envcode,price,reference,eco,description, photo} = this;
        const {id,code,reference_def} = this.state.data;

        const comp = compatibleAcc.map(item=>item.id);

        let itemUpdate = {
            id:id,heatpump_code:envcode.value,heatpump_reference:reference.value,heatpump_ecoprice:eco.value,
            heatpump_price:price.value,description:description.value,code:code,reference_def, heatpump_photo: photo.value, acc_compatible:comp
        };

        this.props.update(itemUpdate,id,this.state.data);
    }

    getCompAccessory = (data,allAccs) =>{
        let retAccs = [];
        data.acc_compatible && data.acc_compatible .forEach(acc=>{
            let found = allAccs.find(item=>item.id == acc );
            if (found) {retAccs.push({value:found.code,id:acc,label:found.reference_def, reference:found.reference_def})}

        })
        return retAccs;
    }

    addEmptyAccessory = ()=>{this.setState({addAcc:true})};

    deleteAccessory = (acc)=>{
        const newAccs = this.state.compatibleAcc.filter(item=>item.value !== acc.value);
        this.setState({compatibleAcc:newAccs})
    }

    filterAccs =  () => {
        if (!this.state.compatibleAcc) return this.state.allAcc;
        return (this.state.allAcc.filter(item=> !this.state.compatibleAcc.find(acc=>acc.value === item.value)));
    }

    selectChange = compatibleAcc => {
        this.setState({compatibleAcc});
    }

    render() {
        const {data,compatibleAcc,addAcc} = this.state;
        const filteredAccs = this.filterAccs();

        return(<div>
              {data && <div><Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <br/>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Button type="submit" className="ButtonUpdate" color="success">{tr("Update")}</Button>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="reference">{tr("reference")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="reference"name="reference"type="text" required defaultValue={data.heatpump_reference}
                                innerRef={input => (this.reference = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="reference_def">{tr("reference_def")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="reference_def"name="reference_def"type="text" disabled defaultValue={data.reference_def}
                                innerRef={input => (this.reference_def = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Col md="1">
                            <Label htmlFor="envcode">{tr("heatpump_code")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="envcode"name="envcode"type="text"  required defaultValue={data.heatpump_code}
                                innerRef={input => (this.envcode = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="code">{tr("code")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="code"name="code"type="text" disabled required defaultValue={data.code}
                                innerRef={input => (this.code = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="price">{tr("price")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="price"name="price"type="text" required defaultValue={data.heatpump_price}
                            innerRef={input => (this.price = input)}/>
                    </Col>
                    <Col md="1"/>
                    <Col md="1">
                        <Label htmlFor="eco">{tr("heatpump_ecoprice")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="eco"name="eco"type="text" required defaultValue={data.heatpump_ecoprice }
                            innerRef={input => (this.eco = input)}/>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={1}>
                            <Label htmlFor="photo">{tr("photo")}</Label>
                        </Col>
                        <Col md={4}>
                            <Input id="photo" name="photo" defaultValue={data.heatpump_photo}
                                innerRef={input => (this.photo = input)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>

                    <Col md="1">
                        <Label htmlFor="description">{tr("description")} </Label>
                    </Col>
                    <Col  md="11">
                        <Input className="EditDescription" id="description"name="description"type="textarea" required defaultValue={data.description}
                            innerRef={input => (this.description = input)}/>
                    </Col>
                </FormGroup>
                </Form>
                </CardBody>
            </Card>
            {data && <HpDocEdit data={data} />}
            <Card>
                <h4>{tr("Compatible_accessories")}</h4>
                {!addAcc && <a className="btn btn-success addAcc" onClick={this.addEmptyAccessory.bind(this)}>
                        <i className="fa fa-plus" />
                      </a>}
                <CardBody>
                {addAcc && <Select className="selectAcc" options={filteredAccs} isMulti isSearchable onChange={this.selectChange} value={compatibleAcc}/>}
                    <Table striped>
                    <thead>
                        <tr>
                        <th>Code</th>
                        <th>Reference</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {compatibleAcc && compatibleAcc.map(acc=>
                        <tr className={acc.code ? "":"allGreenLine"}>
                            <td>{acc.value}</td>
                            <td>{acc.reference}</td>
                        <td><a className="DeleteIcon" onClick={this.deleteAccessory.bind(this,acc)} ><i className="fa fa-trash"/></a></td>
                        </tr>
                            )}
                    </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
        }

         </div>)
     }
 }

function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
}

const connectedHpEdit = connect(mapStateToProps)(HpEdit);

export { connectedHpEdit as HpEdit };