import { menuConstants } from "../Menu/menu.constants";

export function menu(state = {}, action) {
  switch (action.type) {
    case menuConstants.GET_MENU_SUCCESS:
      return { items: action.menu };

    case menuConstants.ADD_MENU_LAYOUT_SUCCESS:
      let newStateItems = [...state.items];
      let index = newStateItems.findIndex(i => i.id === action.menu.id);
      if (index) {
        newStateItems[index].layoutId = action.menu.layoutId;
        newStateItems[index].url = "/layout/" + action.menu.layoutId;
      }
      return { items: newStateItems };

    default:
      return state;
  }
}
export function theme(state = {current:{label:"Light", class:""}}, action) {
  switch (action.type) {
    case menuConstants.CHANGE_THEME:
    return { current: action.theme };
  default:
    return state;
}
}