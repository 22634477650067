import { menuConstants } from "../Menu";
import api, { manageErrors }  from "../_api/api";
import Notifications from "react-notification-system-redux";
import { tr } from "../_locales";

export const menuActions = {
  getMenu,
  addLayoutToMenu,
  changeTheme
};

// function getMenu() {
//   const menu = [
//     {
//       name: "Tableau de bord",
//       icon: "icon-speedometer",
//       title: false,
//       parent: 0,
//       layoutId: 1,
//       extUrl: ""
//     }
//   ];
//   return { type: menuConstants.GET_MENU_SUCCESS, menu };
// }
function getMenu() {
  return dispatch => {
    api.menu.get().then(
      menu => {
        dispatch(success(menu));
      },
      error => {
        const errorInfo = manageErrors(error,"get_error");
        dispatch(Notifications.error({ message: tr(errorInfo.data)}));        
      }
    );
  };
  function success(menu) {
    return { type: menuConstants.GET_MENU_SUCCESS, menu };
  }
}
function addLayoutToMenu(menuId, layoutId) {
  return dispatch => {

    return api.menu.addLayout(menuId, layoutId).then(
      menu => {
        dispatch(success(menu));
      },
      error => {
        const errorInfo = manageErrors(error,"add_error");
        dispatch(Notifications.error({ message: tr(errorInfo.data)}));          
      }
    );
  };

  function success(menu) {
    return { type: menuConstants.ADD_MENU_LAYOUT_SUCCESS, menu };
  }

}
function changeTheme(theme) {
  return { type: menuConstants.CHANGE_THEME, theme };
}
