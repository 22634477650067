import { layoutConstants } from "../Grid";

export function setlayout(state = {}, action) {
  switch (action.type) {
     case layoutConstants.REMOVE_EL_LAYOUT:
      return {
        ...state,
        data: { ...state.data,layout:state.data.layout.filter(item=> item.i !== action.element)}
      };
      case layoutConstants.UPDATE_EL_LAYOUT:
      const modLayout = state.data.layout.map((layout)=>layout.i === action.widget.i ? action.widget:layout);
      return {
        ...state,
        data: { ...state.data,layout:modLayout}
      };      

    case layoutConstants.ADD_EL_LAYOUT:
      if (state.data && state.data.layout)
        return {
          ...state,
          data: {
            ...state.data,
            layout: [...state.data.layout, action.element]
          }
        };
      else
        return {
          ...state,
          data: {
            ...state.data,
            layout: [action.element]
          }
        };

    case layoutConstants.CHANGE_LAYOUT:

      if (!action.layout.length) {
        return {
          ...state,
          data: {}
        };
      }
      let changedLayout = { ...state.data };
      for (let i = 0; i < action.layout.length; i++) {
        // layout update of each item as we don't know which one has changed
        changedLayout.layout[i].w = action.layout[i].w;
        changedLayout.layout[i].h = action.layout[i].h;
        changedLayout.layout[i].x = action.layout[i].x;
        changedLayout.layout[i].y = action.layout[i].y;
      }
      return {
        ...state,
        data: changedLayout
      };

    case layoutConstants.DEF_STORE_SUCCESS:
    case layoutConstants.DEF_UPDATE_SUCCESS:
    case layoutConstants.DEF_GET_SUCCESS:
      if (action.layout && action.layout.layout && action.layout.layout[0] && action.layout.layout[0].x === null){
        action.layout.layout[0].x = 0;
      }
      return {
        ...state,
        data: action.layout
      };
      case layoutConstants.DEF_GET_EMPTY:
      return {
        ...state,
        data: {}
      };      

    default:
      return state;
  }
}
