import React from "react";
import { connect } from "react-redux";
import { Form, Col, Card, CardBody, FormGroup, Input, Label, Button, Table, CustomInput, ButtonGroup } from "reactstrap";
import { tr } from "../../../_locales";
import Select from 'react-select';
var allLanguages = require("@cospired/i18n-iso-languages");
allLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));


class EnvEdit extends React.Component {

    state = { data: null, langs: [], selectedOption: null }
    componentDidMount() {
        let allAcc = null;
        let allLangs = [];
        const isoLangs = allLanguages.getNames("en");
        for (const property in isoLangs) {
            allLangs.push({ label: isoLangs[property], value: property });
        }
        this.setState({ langs: allLangs });
        if (this.props.mode === "edit") {
            const env = this.props.data.data.find(item => item.id === this.props.id);
            let optionsLangues = [];
            env.langs.forEach(lang => { optionsLangues.push({ label: lang, value: lang }) });
            this.setState({ data: env, selectedOption: optionsLangues });
        }
    }
    handleSubmit = (event) => {
        event && event.preventDefault();
        let langs = this.state.selectedOption.map(item => item.value);
        let id = this.state.data ? this.state.data.id : null;
        let values = { id: id, country: this.country.value, code: this.code.value, 
            active: this.active.checked, langs: langs };

        this.props.update(values, id, this.state.data, this.props.mode);
    }
    handleChange = selectedOption => { this.setState({ selectedOption }); }

    toggleActive = () => {
        if (this.state.data) {
            let newData = { ...this.state.data };
            newData.active = this.state.data.active === "false" ? "true" : "false";
            this.setState({ data: newData });
        }
    }
    
    render() {
        const { data, langs, selectedOption } = this.state;
        const { mode } = this.props;
        return (<div>
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <Card>
                    <h4>{tr("Infos")}</h4>
                    <CardBody>
                        <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ? tr("Update") : tr("Add")}</Button>
                        <Button type="back" className="ButtonBack" color="secondary" onClick={this.props.back}>{tr("Back")}</Button>
                        <FormGroup row>
                            <Col md="1">
                                <Label htmlFor="code">{tr("code")} </Label>
                            </Col>
                            <Col md="4">
                                <Input id="code" name="code" type="text" required defaultValue={data && data.code}
                                    innerRef={input => (this.code = input)} />
                            </Col>
                            <Col md="1" />
                            <Col md="1">
                                <Label htmlFor="lang">{tr("lang")} </Label>
                            </Col>
                            <Col md="4">
                                <Select value={selectedOption} isMulti onChange={this.handleChange} options={langs} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="1">
                                <Label htmlFor="country">{tr("country")} </Label>
                            </Col>
                            <Col md="4">
                                <Input id="country" name="country" type="text" required defaultValue={data && data.country}
                                    innerRef={input => (this.country = input)} />
                            </Col>
                            <Col md="1" />
                            <Col md="1">
                                <Label htmlFor="active">{tr("active")} </Label>
                            </Col>
                            <Col md="4">
                                <Input id="active" name="active" type="checkbox" checked={data && data.active === "true"}
                                    onChange={() => this.toggleActive()} innerRef={input => (this.active = input)} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>
        </div>);
    }



}
function mapStateToProps(state) {
    const { list } = state.datasource;
    return { list };
}
const connectedEnvEdit = connect(mapStateToProps)(EnvEdit);
export { connectedEnvEdit as EnvEdit };