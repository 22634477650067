import axios from "axios";
import { config } from "../config";
import setAuthorizationHeader from "./setAuthorizationHeader";

export default (auth) => {
  axios.defaults.baseURL = config.API_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';
  axios.defaults.headers.patch['Content-Type'] = 'application/json';
  axios.defaults.headers.delete['Content-Type'] = 'application/json';
  axios.defaults.headers.get['Content-Type'] = 'application/json';
  auth && auth.user && auth.user && auth.user.token ? setAuthorizationHeader(auth.user.token):setAuthorizationHeader(undefined) ;
};