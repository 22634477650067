import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,Table,Row} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import api from "../../../_api/api";

  class AccessoryEdit extends React.Component {
     state={data:null,allAcc:null,DepAcc:null,InAcc:null}
     componentDidMount(){
         let allAcc= null;
         const acc = this.props.data.data.find(item=>item.id === this.props.id);
         if(acc){
                api.accessories.getDepIncAcc(acc.code,UIStore.environment).then(accs=>{
                    this.depIncAcc(accs,acc);
                })}
            this.setState({data:acc});
        }
     fillTab =(accs,data,depInc)=>{
        let tab=[];
        if (data[depInc]){
            data[depInc].forEach(dep=>{
               dep = parseInt(dep,10);
               let found = accs.find(acc=>acc.id === dep);
               if (found){
                   tab.push(found);
               }
               })
           }
           return tab;
     }
     depIncAcc = (accs,data) =>{
        const dep = this.fillTab(accs,data,"dep");
        const inc = this.fillTab(accs,data,"inc");
        this.setState({DepAcc:dep,InAcc:inc});
     }
     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {code,price,eco,reference,envcode,group,description, photo} = this;
        const {reference_def} = this.state.data;
        let itemUpdate = {id:this.state.data.id,accessory_reference:reference.value,accessory_code:envcode.value,reference_def:reference_def,
                         accessory_price:price.value,accessory_ecoprice:eco.value,description:description.value,code:code.value,group:group.value, accessory_photo: photo.value};
        this.props.update(itemUpdate,this.state.data.id,this.state.data);
     }

     render() {
         const {data,DepAcc,InAcc} = this.state;

         return(<div>
              {data && <div><Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Button type="submit" className="ButtonUpdate" color="success">{tr("Update")}</Button>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="reference">{tr("reference")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="reference"name="reference"type="text" required defaultValue={data.accessory_reference}
                                innerRef={input => (this.reference = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="reference_def">{tr("reference_def")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="reference_def"name="reference_def"type="text" disabled defaultValue={data.reference_def}
                                innerRef={input => (this.reference_def = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="envcode">{tr("envcode")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="envcode"name="envcode"type="text" required defaultValue={data.accessory_code }
                                innerRef={input => (this.envcode = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="code">{tr("code")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="code"name="code"type="text" disabled required defaultValue={data.code}
                                innerRef={input => (this.code = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="price">{tr("price")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="price"name="price"type="text" required defaultValue={data.accessory_price}
                                innerRef={input => (this.price = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                          <Label htmlFor="eco">{tr("accessory_ecoprice")} </Label>
                        </Col>
                        <Col  md="4">
                          <Input id="eco"name="eco"type="text" required defaultValue={data.accessory_ecoprice }
                                 innerRef={input => (this.eco = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={1}>
                            <Label htmlFor='photo'>{tr("photo")}</Label>
                        </Col>
                        <Col md={4}>
                            <Input id='photo' name='photo' type='text' defaultValue={data.accessory_photo}
                                innerRef={input => (this.photo = input)}/>
                        </Col>
                        <Col md="1">
                          <Label htmlFor="group">{tr("group")} </Label>
                        </Col>
                        <Col  md="2">
                          <Input id="group"name="group"type="text" disabled required defaultValue={data.group}
                                 innerRef={input => (this.group = input)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="description">{tr("description")} </Label>
                        </Col>
                        <Col  md="8">
                            <Input className="EditDescription" id="description"name="description"type="textarea" required defaultValue={data.description}
                                innerRef={input => (this.description = input)}/>
                    </Col>
                </FormGroup>
                </Form>
                </CardBody>
            </Card>
            <Card>
            <CardBody>
                <Row>
                    <Col  md="6">
                    <h4 href="depacc">{tr("Dependant_accessories")}</h4>

                        <Table striped>
                        <thead>
                            <tr>
                            <th>Code</th>
                            <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DepAcc && DepAcc.map(acc=>
                            <tr>
                                <td>{acc.code}</td>
                                <td>{acc.reference}</td>
                            </tr>
                                )}
                        </tbody>
                        </Table>
                        </Col>
                        <Col  md="6">
                    <h4 href="inacc">{tr("Invalid_accessories")}</h4>

                        <Table id="inacc" striped>
                        <thead>
                            <tr>
                            <th>Code</th>
                            <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {InAcc && InAcc.map(acc=>
                            <tr>
                                <td>{acc.code}</td>
                                <td>{acc.reference}</td>
                            </tr>
                                )}
                        </tbody>
                        </Table>
                        </Col>
                        </Row>
                </CardBody>
            </Card>


        </div>}

         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedAccessoryEdit = connect(mapStateToProps)(AccessoryEdit);
  export { connectedAccessoryEdit as AccessoryEdit };