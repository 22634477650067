import React, { useState } from "react";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { tr } from "../../_locales";
import "./table.css";
import UIStore from "../../store/UIStore";
import { satisfactionExport, excelStdExport } from "./Projipac/specificExport";
import FileUploader from "../../_components/FileUploader";
import axios from "axios";

import {
  Card,
  CardBody,
  Table,
  Modal,
  ModalBody,
  Button,
  CardHeader,
  ModalHeader,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  ModalFooter,
} from "reactstrap";

const basicOptions = {
  bootstrap4: true,
  hover: true,
  condensed: true,
  striped: true,
};

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {from} {tr("to")} {to} {tr("of")} {size} {tr("Results")}
  </span>
);

export const configOptions = (ComponentToRender, rights, editObj, onTableChange, currentPage, sizePerPage, totalSize) =>
  // Function to configure the BootstrapTable
  class TableOptions extends React.Component {
    constructor(props) {
      super(props);
      let initState = {};
      if (rights.update) {
        initState.cellEdit = editObj;
      }
      if (rights.distant) {
        initState.remote = true; // if update rights are enabled add the right prop
        initState.onTableChange = onTableChange;
        initState.pagination = paginationFactory({
          page: currentPage,
          sizePerPage: sizePerPage,
          totalSize: totalSize,
          showTotal: true,
          paginationTotalRenderer: customTotal,
        });
      } else {
        // if update rights are enabled add the right prop
        initState.pagination = paginationFactory({
          page: currentPage,
          sizePerPage: sizePerPage,
          showTotal: true,
          paginationTotalRenderer: customTotal,
          firstPageTitle: tr("firstpage"),
          lastPageTitle: tr("lastpage"),
          prePageTitle: tr("prevpage"),
          nextPageTitle: tr("nextpage"),
          onSizePerPageChange: (sizePerPage) => {
            UIStore.setPageSize(sizePerPage);
          },
        });
      }
      this.state = initState;
    }
    render() {
      // const defaultSorted = [{dataField: 'description',order: 'asc'}];
      return <ComponentToRender {...this.props} {...this.state} {...basicOptions} filter={filterFactory()} />;
    }
  };

export const MyExportCSV = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleDownloadClick = () => {
    if (props.title === "Satisfaction") {
      satisfactionExport(props.data);
    } else {
      //props.onExport();
      excelStdExport(props.data);
    }
  };
  const handleUploadClick = async () => setOpenModal(true);
  return (
    <>
      {props.title === "Translations" && UIStore.user.role === "superadmin" && (
        <>
          <button className="card-header-action btn " title={tr("upload_csv")} onClick={handleUploadClick}>
            <i className="icon-cloud-upload" />
          </button>
          <ModalTranslationUpload
            isOpen={openModal}
            onValidate={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
          />
        </>
      )}
      {(props.title === "Pompes" || props.title === "Accessoires") && UIStore.user.role === "superadmin" && (
        <>
          <button className="card-header-action btn " title={tr("upload_csv")} onClick={handleUploadClick}>
            <i className="icon-cloud-upload" />
          </button>
          <ModalUpload
            title={props.title}
            isOpen={openModal}
            onValidate={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
          />
        </>
      )}
      <button className="card-header-action btn " title={tr("download_csv")} onClick={handleDownloadClick}>
        <i className="icon-cloud-download" />
      </button>
    </>
  );
};

const ModalTranslationUpload = (props) => {
  const [uploadValue, setUpoadValue] = useState(null);

  const handleUploadClick = async () => {
    await axios.post("/translation", { file: uploadValue });
    setUpoadValue(null);
    alert("translations_updated");
  };

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Upload Translation</ModalHeader>
      <ModalBody>
        <FileUploader
          type={[".xlsx"]}
          onUploaded={(e) => {
            setUpoadValue(e);
          }}
          onError={(e) => {
            debugger;
          }}
          value={uploadValue}
          disabledDelete={true}
          forceText={null}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onCancel();
          }}
        >
          Annuler
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            handleUploadClick();
            props.onValidate();
          }}
        >
          validate
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ModalUpload = (props) => {
  const [uploadValue, setUpoadValue] = useState(null);
  const type = props.title === "Pompes" ? "Heatpumps" : "Accessories"

  const handleUploadClick = async () => {
    try {
      const endpoint = props.title === "Pompes" ? "/heatpumpMassEdit" : "/accessorymgtMassEdit"
      await axios.post(endpoint, { file: uploadValue, env: UIStore.environment, lang: UIStore.projilang });
      setUpoadValue(null);
      alert(type + " updated");
      window.location.reload();
    } catch(ex) {
      alert(type + " update error");
    }
  };

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>Upload {type}</ModalHeader>
      <ModalBody>
        <FileUploader
          type={[".xlsx"]}
          onUploaded={(e) => {
            setUpoadValue(e);
          }}
          onError={(e) => {
            debugger;
          }}
          value={uploadValue}
          disabledDelete={true}
          forceText={null}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onCancel();
          }}
        >
          Annuler
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            handleUploadClick();
            props.onValidate();
          }}
        >
          validate
        </Button>
      </ModalFooter>
    </Modal>
  );
};
