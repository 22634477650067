import {BasicWidgetInput, TableWidgetInput, ObjectWidgetInput, ChartWidgetInput, ColorCardInput,TraceWidgetInput} from '../Widgets';
export * from './WidgetList';
export * from './Widget';
export * from './Basic/BasicWidgetInput';
export * from './Basic/BasicWidget';
export * from './Table/TableWidgetInput';
export * from './Table/TableWidget';
export * from './Object/ObjectWidgetInput';
export * from './Object/ObjectWidget';
export * from './Chart/ChartWidgetInput';
export * from './Chart/ChartWidget';
export * from './ColorCard/ColorCard';
export * from './ColorCard/ColorCardInput';
export * from './Trace/TraceWidgetInput';
export * from './Trace/TraceWidget';
export const widgetList = [
  {  inputComponent: BasicWidgetInput, type:"BasicWidget", icon:"icon-notebook"},
  {  inputComponent: TableWidgetInput, type:"TableWidget", icon:"icon-grid"},
  {  inputComponent: ObjectWidgetInput, type:"ObjectWidget", icon:"icon-list"},
  {  inputComponent: ChartWidgetInput, type:"ChartWidget",icon:"icon-chart"},  
  {  inputComponent: ColorCardInput, type:"ColorCard",icon:"icon-credit-card"},  
  {  inputComponent: TraceWidgetInput, type:"TraceWidget",icon:"icon-credit-card"},     
    ];
