import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,CustomInput} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import {verifyCheckedEnv,getCheckedEnvs} from "./env_management";
import Select from 'react-select';
import api from "../../../_api/api";

  class UserEdit extends React.Component {
     state={data:null,users:[],envs:{},selectedOption:null}
     componentDidMount(){
         const {data,mode} = this.props;
         const user = mode === "add" ? null : data.data.find(item=>item.id === this.props.id);
         if (user){user.envs = user.entities};
         const selectedOption = user  ?  {label:`${user.username} ${user.email}`,value:user.id} :null;
         api.user.getNonAdmins().then(nonAdmins=>{
         const users = nonAdmins.map(item=>({label:`${item.username}  ${item.email}`,value:item.id}));
         const envs =  verifyCheckedEnv(user, UIStore.envs);
         this.setState({data:user,envs:envs,users:users,selectedOption:selectedOption});
         })
    }
     handleSubmit = (event) =>{
        event && event.preventDefault();
        const checkedEnvs = getCheckedEnvs(this.state.envs,UIStore.envs);
        const id = this.state.data ? this.state.data.id : this.state.selectedOption.value;
        let itemUpdate = {id:id,entities:checkedEnvs,mode:this.props.mode,uid:id};                                   
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }
     handleChange = selectedOption => {this.setState({ selectedOption });}
     toggleCheck = (env) =>{
        const {envs} = this.state;
        if (envs){
            let newEnvs = {...envs};
            newEnvs[env] = !envs[env];
            this.setState({envs:newEnvs});
        }
     }

     render() {
         const {data,users,selectedOption} = this.state;
         const {mode} = this.props;
         return(<div>
             <div><Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <br/>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Button type="submit" className="ButtonUpdate" color="success">{mode === "edit" ?tr("Update") : tr("Add")}</Button>
                 <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="user">{tr("user")} </Label>
                        </Col>
                        <Col  md="4">
                        { mode === "add" &&
                        <Select value={selectedOption}   onChange={this.handleChange} options={users}/>}
                        { mode === "edit" &&   <div>{`${data && data.username}  ${data && data.email}`}  </div>     }                
                        </Col>

                </FormGroup> 
                
                </Form>
                </CardBody>
            </Card>
            <Card>
                <h4>{tr("environnement_mgt")}</h4>
                <FormGroup row className="Checkboxes">
                {UIStore.envs && UIStore.envs.map((env,index)=>
                        <CustomInput key={index} className="Checkbox" type="checkbox" id={`env_${index}`} name={env.value}
                        onChange={()=>this.toggleCheck(env.code)} checked={this.state.envs[env.code]}
                        label={env.label} />
                    )}
                    </FormGroup>
            </Card>  
        </div>

         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedUserEdit = connect(mapStateToProps)(UserEdit);
  export { connectedUserEdit as UserEdit };