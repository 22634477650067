import React from "react";
import { datasourceService } from "../../Datasource/datasource.service";
import Notifications from "react-notification-system-redux";
import { tr } from "../../_locales";
import { getUrlParams } from "../../_helpers/stringManager";
import { connect } from "react-redux";
import {Label} from 'reactstrap';
import { typeChart, chartSettings} from './chart.settings';


class ChartWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], // data displayed
      settings:[], //widget settings
      waitingData: true
    };
  }
  componentWillReceiveProps(nextProps) {
      // if (nextProps.data.datasource !== this.props.data.datasource || nextProps.data.chart !== this.props.data.chart) {
        // the table data has been udated so need to reload the right data
        // this.retrieveData();
      
    }
  retrieveData()
  {
    const { list, data } = this.props;
    const found = list && list.filter(item => item.id === parseInt(data.datasource, 10));
    let params = getUrlParams();
    if (this.props.otherData){params ={...params, ...this.props.otherData}};
    found && found.length > 0 &&
      datasourceService.read(found[0], params).then(answer => {
        if (answer.error === false) {
            this.setState({ data: answer.data,settings: data, waitingData: false }); // we store the datasource
        } else {
          this.setState({  waitingData: false });
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
        }
      });
  }
  componentDidMount() {
  this.retrieveData();
  }
  dataDisplayed()
  {
    const {data} = this.state;
    const {labelfield,datafield,chart,title } =this.state.settings;
    let charData={};
    charData.datasets=[];
    charData.labels= labelfield ? data.map(a=>a[labelfield]):[];
    charData.datasets[0] = chartSettings[chart];
    charData.datasets[0].data = data.map(a=>a[datafield]);
    charData.datasets[0].label=title;
    return(charData);
  }

  render() {
    const TheChart = !this.state.waitingData? typeChart[this.state.settings.chart]:<span/>; 
    return (
      <div>
        {this.state.waitingData && (
          <Label ><i>{tr('loading')}</i></Label>
        )}
        { !this.state.waitingData && <TheChart data={this.dataDisplayed.bind(this)} />} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { datasource } = state;
  const { list } = state.datasource;
  return {
    datasource,
    list
  };
}
const connectedChartWidget = connect(mapStateToProps)(ChartWidget);
export { connectedChartWidget as ChartWidget };

