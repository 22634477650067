import React from 'react';
import { config } from "../config";
import { _T } from '../_i18n/i18n.helper';
import {Button} from "reactstrap";

/**
 * Home Page
 */
export default class ConnectPage extends React.Component {

  render () {
        const url = `${config.API_URL}/login/?callbackurl=${window.location.origin}`;
        const urlInterne = `${url}&interne=true`

        return (
          <div>
              <br />&nbsp; Your Session has expired please &nbsp;
              <Button>
                <a href={url}>Connect to Atlantic</a>
              </Button>
              &nbsp;
              <Button style={{backgroundColor: '#4472c4'}}>
                <a href={urlInterne} style={{color: 'white'}}>Connect to Azure AD</a>
              </Button>
          </div>
        )
    }
}