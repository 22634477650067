import React from "react";
import { tr } from "../_locales";
import { Label } from "reactstrap";

export class Waiting extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: "", hidden: true };
  }

  componentDidMount() {
    this._mounted = true; // inform if the component is mounted
    setTimeout(() => this.show1(), 500);
  }
  componentWillUnmount() {
    this._mounted = false;// inform if the component is unmounted
  }
  show1() {
    if (this._mounted) { //only if mounted to avoir setting state of an unmounted component
      this.setState({ message: "loading", hidden: false });
      setTimeout(() => this.show2(), 2000);
    }
  }
  show2() {
    if (this._mounted) {  //only if mounted to avoir setting state of an unmounted component
      this.setState({ message: "loading2" });
    }
  }
  render() {
    return <Label hidden={this.state.hidden}>{this.state.message && tr(this.state.message)}</Label>;
  }
}
