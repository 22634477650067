import { removeBracketAndReplace } from "../_helpers/stringManager";
import { axiosDatasource } from "../_api/apiDatasource";

export const datasourceService = {
  read,
  update,
  _delete,
  create,
  getTotalRecords
};

function addToElement (element,params){
  if (params){
    // On ajoute les paramètres supplémentaires à la structure (ils sont sous la forme a=1&b=blabla
    Object.keys(params).forEach(param=>{
      let items = params[param].split("&");
      items.forEach(item=>{
        const lastSplit = item.split("=");
        if (lastSplit.length === 2){
          element[lastSplit[0]] = lastSplit[1];
        }
      })
    });
  }
}

function getRemoteUrl(data, info, operation, remote, params) {
  const newParams = { ...params, ...remote };
  let remoteEndpoint = data.distant.limit + data.distant.skip.replace("?", "&");
  remoteEndpoint = newParams.sortfield ? remoteEndpoint + data.distant.sort.replace("?", "&") : remoteEndpoint;
  remoteEndpoint = newParams.filters
    ? remoteEndpoint + manageFilters(data.distant.where, newParams.filters)
    : remoteEndpoint;

  const newEndpoint = `${data.baseUrl}${removeBracketAndReplace(
    data.read.endpoint,
    newParams
  )}${removeBracketAndReplace(remoteEndpoint, newParams)}`;
  return newEndpoint;
}
function manageFilters(where, filters) {
  let filterEnd = "";
  Object.keys(filters).forEach(filter => {
    filterEnd += where.replace("?", "&");
    filterEnd = removeBracketAndReplace(filterEnd, { field: filter, value: filters[filter] });
  });
  return filterEnd;
}

function read(data, info = null, operation = null, remote = null) {
  const params = info ? { field: Object.keys(info)[0], value: Object.values(info)[0], operation: operation } : {};
  let endpoint = "";
  if (remote && data.distant.active && data.distant.limit) {
    // we are using remote data
    endpoint = getRemoteUrl(data, info, operation, remote, params);
  }
  //info contains an object with the key and values
  else {
    endpoint = info
      ? `${data.baseUrl}${removeBracketAndReplace(data.read.endpoint, info)}${removeBracketAndReplace(
          data.distant.where,
          params
        )}`
      : `${data.baseUrl}${removeBracketAndReplace(data.read.endpoint, params)}`;
  }
  return axiosDatasource(data.read.method, endpoint, null, "get_error");
}

function update(element, data,params=null) {
  const endpoint = `${data.baseUrl}${removeBracketAndReplace(data.update.endpoint, element)}`;
  //Object.keys(element).forEach(key => element[key] === "" && delete element[key]); // remove fields with empty elements
    // On ajoute les paramètres supplémentaires à la structure (ils sont sous la forme a=1&b=blabla
  addToElement(element,params);
  return axiosDatasource(data.update.method, endpoint, JSON.stringify(element), "update_error");
}

function _delete(id, data, params=null) {
  let element =   { id: id };
  const endpoint = `${data.baseUrl}${removeBracketAndReplace(data.trash.endpoint, element)}`;
  addToElement(element,params);
  return axiosDatasource(data.trash.method, endpoint, JSON.stringify(element), "delete_error");
}
function getTotalRecords(data) {
  const endpoint = `${data.baseUrl}${data.distant.total}`;
  return axiosDatasource("GET", endpoint, null, "");
}

function create(element, data,params =null) {
  const endpoint = `${data.baseUrl}${removeBracketAndReplace(data.create.endpoint, element)}`;
  Object.keys(element).forEach(key => element[key] === "" && delete element[key]); // remove fields with empty elements
  // On ajoute les paramètres supplémentaires à la structure (ils sont sous la forme a=1&b=blabla
  addToElement(element,params);
  return axiosDatasource(data.create.method, endpoint, JSON.stringify(element), "create_error");
}


