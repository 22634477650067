import React, { Component } from "react";
import {
  Card,
  CardBody,
  Table,
  Modal,
  ModalBody,
  Button,
  CardHeader,
  ModalHeader,
  Container,
  Row,
  Col,
  Label,
  Input,
  Form,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import { tr } from "../../../_locales";
import Select from "react-select";
import { toast } from "react-toastify";
import Axios from "axios";
import UIStore from "../../../store/UIStore";

class HpDocEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data.hpdocs,
      currentDataEdit: null,
      editOpened: false,
    };
  }

  render() {
    return (
      <Card>
        <CardBody>
          <h4>{tr("Heatpump documents")}</h4>
          <hr></hr>
          <a style={{ marginBottom: "16px", float: "right" }} onClick={this.modalOpenBlank} className="btn btn-success">
            <i className="fa fa-plus" />
          </a>
          <Table striped>
            <thead>
              <tr>
                <th>{tr("Title")}</th>
                <th>{tr("Link")}</th>
                <th>{tr("Type")}</th>
                <th>{tr("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((i) => (
                <tr key={i.id}>
                  <td>{i.title}</td>
                  <td>{i.link}</td>
                  <td>{i.category}</td>
                  <td>
                    <i
                      onClick={() => {
                        this.modalOpenExisting(i);
                      }}
                      className="fa fa-pencil"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <i
                      onClick={() => {
                        this.deleteDoc(i.id);
                      }}
                      className="fa fa-trash"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <ModalHpDocEdit
          isOpen={this.state.editOpened}
          value={this.state.currentDataEdit}
          onChange={this.change}
          onSubmit={this.submit}
          toggle={this.modalToggle}
        />
      </Card>
    );
  }

  modalOpenExisting = (data) => {
    this.setState({ editOpened: true, currentDataEdit: JSON.parse(JSON.stringify(data)) });
  };
  modalOpenBlank = () => {
    this.setState({ editOpened: true, currentDataEdit: { hp: this.props.data.id } });
  };
  modalToggle = () => {
    this.setState({ editOpened: !this.state.editOpened });
  };
  change = (e) => {
    this.setState({ currentDataEdit: e });
  };
  deleteDoc = (id) => {
    let list = this.state.data;

    Axios.delete(`/admin/heatpumps/docs/${id}`)
      .then(() => {
        list = list.filter((i) => i.id !== id);
        this.setState({ data: list });
      })
      .catch(() => {
        toast.error(tr("ERROR_DURING_DELETE"));
      });
  };
  submit = (e) => {
    let list = this.state.data;
    let hpDoc = this.state.currentDataEdit;

    if (this.state.currentDataEdit.id) {
      hpDoc.env = hpDoc.environnement_id;
      Axios.put(`/admin/heatpumps/docs/${this.state.currentDataEdit.id}`, hpDoc)
        .then((r) => {
          list = list.filter((i) => i.id !== hpDoc.id);
          list.push(r.data);
          this.setState({ data: list, currentDataEdit: null, editOpened: false });
        })
        .catch((r) => {
          toast.error(tr("ERROR_DURING_EDIT"));
        });
    } else {
      hpDoc.lang = UIStore.lang;
      hpDoc.env = UIStore.environment;
      Axios.post(`/admin/heatpumps/docs/${hpDoc.hp}`, hpDoc)
        .then((r) => {
          list.push(r.data);
          this.setState({ data: list, currentDataEdit: null, editOpened: false });
        })
        .catch(() => {
          toast.error(tr("ERROR_DURING_SUBMIT"));
        });
    }
  };
}

/**
 * @prop {bool} isOpen
 * @prop {array} value contain one data of hpdoc
 * @prop {func} onChange
 * @prop {func} onSubmit
 * @prop {func} toggle
 */
class ModalHpDocEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleError: false,
      linkError: false,
      typeError: false,
    };
  }

  render() {
    const options = [
      { value: ".pdf", label: ".pdf" },
      { value: ".odt", label: ".odt" },
    ];
    const selectValue = this.props.value
      ? this.props.value.category
        ? options.filter((i) => i.value === this.props.value.category).length
          ? options.filter((i) => i.value === this.props.value.category)[0]
          : null
        : null
      : null;

    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader>edit hpdoc</ModalHeader>
        <ModalBody>
          {this.props.value && (
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label>Title:</Label>
                <Input value={this.props.value.title || ""} onChange={(e) => this.onChange("title", e.target.value)} />
                {this.state.titleError && <p style={{ color: "red" }}>{tr("TITLE_ERROR")}</p>}
              </FormGroup>
              <FormGroup>
                <Label>Link:</Label>
                <Input value={this.props.value.link || ""} onChange={(e) => this.onChange("link", e.target.value)} />
                {this.state.linkError && <p style={{ color: "red" }}>{tr("LINK_ERROR")}</p>}
              </FormGroup>
              <FormGroup>
                <Label>Category:</Label>
                <Select options={options} value={selectValue} onChange={(e) => this.onChange("category", e.value)} />
                {this.state.typeError && <p style={{ color: "red" }}>{tr("TYPE_ERROR")}</p>}
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>
            Back
          </Button>
          <Button color="primary" onClick={this.onSubmit}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  onSubmit = (e) => {
    if (e) e.preventDefault();
    let eCounter = 0;

    if (!this.props.value.title) {
      eCounter++;
      this.setState({ titleError: true });
    }
    if (!this.props.value.link) {
      eCounter++;
      this.setState({ linkError: true });
    }
    if (!this.props.value.category) {
      eCounter++;
      this.setState({ typeError: true });
    }
    if (!eCounter) {
      this.props.onSubmit(this.props.value);
    }
  };

  onChange = (code, value) => {
    let data = this.props.value || {};

    data[code] = value;
    this.props.onChange(data);
  };
}
export default HpDocEdit;
