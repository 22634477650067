import React from "react";
import ReactDOM from "react-dom";
import { datasourceService } from "../../Datasource/datasource.service";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import Notifications from "react-notification-system-redux";
import { tr } from "../../_locales";
import { getUrlParams } from "../../_helpers/stringManager";
import { configOptions, MyExportCSV } from "./table_service";
import { manageColumns, getIcons, getFilterInfo, sameFilter } from "./column_service";
import history from "../../history";
import { Waiting } from "../../Views/Waiting";
import { Modal } from "reactstrap";
import { SpecificEdit } from "./Projipac/SpecificEdit";
import "./Projipac/projipac.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import UIStore from "../../store/UIStore";

const { SearchBar } = Search;
class TableWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingData: false,
      header: [], // column info
      data: [], // data displayed
      datasourceId: 0,
      rights: { update: false, trash: false, create: false, distant: false },
      page: { currentPage: 1, sizePerPage: UIStore.tablePageSize },
      remoteSort: { sortfield: null, direction: null },
      sFilters: null,
      nbRecords: 0,
      specificEdit: { id: null, modal: false, mode: "edit" },
      specialTable: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.table === "dataSources") {
      // for the table datasource refresh data after create or update
      nextProps.list && this.storeTableInfo(nextProps.list, this.state);
    } else {
      if (
        nextProps.data.fields.length > 0 &&
        (nextProps.data.fields !== this.props.data.fields || nextProps.data.table !== this.props.data.table)
      ) {
        // the table data has been udated in the input widget so need to reload the right data
        const { data, list } = nextProps;
        this.getTableinfo(data, list);
      }
    }
    this.updateSpecial(nextProps.data.title);
  }
  componentDidMount() {
    const { data, list } = this.props;
    if (list && data.table !== "dataSources") {
      // the datasource case info is in redux
      this.getTableinfo(data, list);
    } else {
      // in case of datasource list display we use the store directly if data is present
      this.storeTableInfo(list, this.state);
    }
    this.updateSpecial(data.title);
  }
  updateSpecial(title) {
    const special = title === "dataSources" || title === "Menu" ? false : true;
    this.setState({ specialTable: special });
  }
  storeTableInfo(answer, otherData = null) {
    if (answer) {
      answer = this.addAction(answer, otherData); // Add action column
      const columns = this.getColumnInfo(answer, otherData); //Get the column if not already
      this.setState({ ...otherData, data: answer, header: columns });
    } else {
      this.setState({ waitingData: false });
    }
  }

  getColumnInfo(answer, otherData) {
    const hiddenColumns =
      this.props.data.table === "dataSources" ? ["read", "update", "trash", "create", "distant"] : []; // Only for datasource
    let columns = manageColumns(
      this.props.data.fields,
      answer,
      hiddenColumns,
      otherData.rights.distant,
      otherData.sFilters,
      otherData.remoteSort,
      this.props.dispatch
    );
    if (!columns) return [];
    if (
      (otherData.rights.trash || otherData.rights.update || this.props.data.table === "dataSources") &&
      !columns.find((el) => el.dataField === "action")
    ) {
      // adding a action header column for delete/insert
      columns.push({ dataField: "action", text: tr("action"), editable: false, csvExport: false });
    }
    return columns;
  }
  addAction(answer, otherData) {
    for (let i = 0; i < answer.length; i++) {
      answer[i]["action"] = this.manageIcons(
        otherData.rights.trash,
        otherData.rights.update,
        answer[i]["action"],
        answer[i]["id"]
      );
    }
    return answer;
  }
  getTableinfo(data, list, remote = null, pageInfo = null) {
    const found = list.filter((item) => item.id === parseInt(data.table, 10));
    if (found[0]) {
      // setup the rights to be stored in state
      const rights = {
        update: found[0].update.active,
        create: found[0].create.active,
        trash: found[0].trash.active,
        distant: found[0].distant.active,
      };
      // this.setState({ rights: rights });
      const { page } = this.state;
      // set the right info is right is enabled and data not set
      remote =
        rights.distant && !remote
          ? { limit: page.sizePerPage, skip: (page.currentPage - 1) * page.currentPage }
          : remote;
      let params = getUrlParams();
      // On ajoute l'environnement aux requêtes
      if (this.props.otherData) {
        params = { ...params, ...this.props.otherData };
      }
      this.setState({ waitingData: true });
      datasourceService.read(found[0], params, null, remote).then((answer) => {
        if (answer.error === false) {
          let stateInfo = { datasourceId: data.table, rights: rights, waitingData: false };
          if (pageInfo) {
            stateInfo = { ...stateInfo, page: pageInfo };
          }
          if (remote) {
            stateInfo = {
              ...stateInfo,
              sFilters: remote.filters,
              remoteSort: { sortfield: remote.sortfield, direction: remote.direction },
            };
          }
          this.storeTableInfo(answer.data, stateInfo);
          // this.setState({ datasourceId: data.table });
        } else {
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));

          this.setState({ waitingData: false });
        }
      });
      if (remote) {
        datasourceService.getTotalRecords(found[0]).then((answer) => {
          if (answer !== false) {
            this.setState({ nbRecords: answer });
          }
        });
      }
    }
  }

  cellEdit = cellEditFactory({
    mode: "click",
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      if (row.id) {
        this.handleUpdate(row.id, column.dataField, newValue);
      }
    },
  });
  handleUpdate(rowId, column, newValue) {
    const itemFound = this.state.data.find((item) => item.id === rowId); // find the complete item
    const itemSent = { ...itemFound };
    itemSent[column] = newValue; //update the item
    itemSent["action"] && delete itemSent["action"]; // remove the action field
    this.update(itemSent, rowId, itemFound);
  }
  update(items, rowId, itemFound) {
    let datasource = this.props.list.find((item) => item.id === parseInt(this.state.datasourceId, 10)); //get the datasource info
    if (datasource) {
      return datasourceService.update(items, datasource, this.props.otherData).then((answer) => {
        if (answer.error === true) {
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
          return Promise.reject();
        } else {
          //update the new item in state
          const index = this.state.data.findIndex((item) => item.id === rowId);
          let newData = [...this.state.data];
          newData[index] = answer.data;
          if (itemFound.action) {
            if (answer.data.id) {
              // On met à jour l'id car dans certains cas il change ex: projipac locale
              const { trash, update } = this.state.rights;
              const fctDelete = trash ? this.cnfDeleteRow.bind(this, answer.data.id) : null;
              const fctEdit = update ? this.editRow.bind(this, answer.data.id) : null;
              newData[index].action = getIcons(fctDelete, fctEdit);
            } else {
              newData[index].action = itemFound.action;
            }
          }
          this.setState({ data: newData });
          return true;
        }
      });
    } else {
      return Promise.reject();
    }
  }
  updateFromEdit(items, id, data, mode) {
    let promise;
    if (mode === "add") {
      let datasource = this.props.list.find((item) => item.id === parseInt(this.state.datasourceId, 10));
      delete items["id"];
      if (datasource) {
        promise = datasourceService.create(items, datasource, this.props.otherData).then((answer) => {
          // update the item and display error if needed
          if (answer.error === false) {
            const { trash, update } = this.state.rights;
            const fctDelete = trash ? this.cnfDeleteRow.bind(this, answer.data.id) : null;
            const fctEdit = update ? this.editRow.bind(this, answer.data.id) : null;
            answer.data.action = getIcons(fctDelete, fctEdit);
            this.setState({ data: [...this.state.data, answer.data] });
            return true;
          } else {
            return Promise.reject();
          }
        });
      }
    } else {
      promise = this.update(items, id, data);
    }
    this.toggleEdit();
    return promise;
  }

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit }) => {
    const { data, list } = this.props;
    const { remoteSort, sFilters } = this.state;
    const currentIndex = (page - 1) * sizePerPage;
    // As the table is rerendered the previous filter must be kept so ignore if same filter again to prevent an infinite loop
    if (type === "filter" && sameFilter(type, filters, sFilters)) {
      return;
    }
    if (type === "cellEdit") {
      const { rowId, dataField, newValue } = cellEdit;
      this.handleUpdate(rowId, dataField, newValue);
      return;
    }
    // we store the direction as not sent by bootstrap-table we swap direction only if the change is sorting
    sortOrder =
      type === "sort"
        ? remoteSort.direction === "asc"
          ? "desc"
          : "asc"
        : remoteSort.direction
        ? remoteSort.direction
        : "desc";
    if (type !== "sort") {
      //if not sort event keep previous sort
      sortField = remoteSort.sortfield;
    }
    const filterInfo = getFilterInfo(filters);
    const options = sortField
      ? { limit: sizePerPage, skip: currentIndex, sortfield: sortField, direction: sortOrder, filters: filterInfo }
      : { limit: sizePerPage, skip: currentIndex, sortfield: null, direction: null, filters: filterInfo };

    this.getTableinfo(data, list, options, { currentPage: page, sizePerPage: sizePerPage });
  };

  editDatasource(id) {
    this.props.editDatasourceFct(id);
  }

  deleteDatasource(id) {
    this.props.deleteDatasourceFct(id).then(() => {
      this.setState({ data: this.state.data.filter((item) => item.id !== id) }); // change the state accordingly to refresh
    });
  }

  displayLink(value, field, ownField, param, event) {
    let finalParam = param;
    if (ownField) {
      const searchParam = event.target.closest("tr").getElementsByClassName(ownField); // find the element on the same row with the field class
      finalParam = searchParam.length > 0 ? searchParam[0].textContent : finalParam;
    }
    history.push(`/layout/${value}?${field}=${finalParam}`);
  }

  manageIcons(trash, update, actionPresent, id) {
    if (this.props.data.table === "dataSources") {
      // for datasource we have a trash and a modification icon
      return getIcons(this.deleteDatasource.bind(this, id), this.editDatasource.bind(this, id));
    }
    if ((trash || update) && !actionPresent) {
      // adding a action column for delete
      const fctDelete = trash ? this.cnfDeleteRow.bind(this, id) : null;
      const fctEdit = update && this.state.specialTable ? this.editRow.bind(this, id) : null;

      return getIcons(fctDelete, fctEdit);
    }
  }
  addEmptyRow() {
    // initialise the fields to empty string by default so that they exist
    let emptyField = {};
    this.state.header.map((item) => (emptyField[item.dataField] = ""));
    // If action column not present create one
    if (!this.state.header.find((el) => el.dataField === "action")) {
      this.setState({ header: [...this.state.header, { dataField: "action", text: tr("action"), editable: false }] });
    }
    // add confirmation input in the action column
    emptyField["action"] = getIcons(null, null, this.addElement.bind(this, this.state.data.length));
    let nbPages = Math.trunc(this.state.data.length / this.state.page.sizePerPage) + 1; // Go to last page
    this.setState({
      data: [...this.state.data, emptyField],
      page: { currentPage: nbPages, sizePerPage: this.state.page.sizePerPage },
    });
  }
  addElement(id) {
    let datasource = this.props.list.find((item) => item.id === parseInt(this.state.datasourceId, 10));
    if (datasource) {
      let itemSent = { ...this.state.data[id] };
      delete itemSent["action"]; // do not save the action column
      delete itemSent["id"];

      datasourceService.create(itemSent, datasource, this.props.otherData).then((answer) => {
        // update the item and display error if needed
        if (answer.error === false) {
          this.state.data.splice(id, 1); // delete temporary entry
          if (this.state.rights.trash) {
            // change the icon and put delete if authorised
            answer.data.action = getIcons(this.cnfDeleteRow.bind(this, answer.data.id));
          }
          this.setState({ data: [...this.state.data, answer.data] });
        } else {
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
        }
      });
    }
  }
  cnfDeleteRow(id) {
    confirmAlert({
      message: tr("confirm_delete"),
      buttons: [
        { label: tr("yes"), onClick: () => this.deleteRow(id) },
        { label: tr("no"), onClick: () => {} },
      ],
    });
  }
  deleteRow(id) {
    let datasource = this.props.list.find((item) => item.id === parseInt(this.state.datasourceId, 10));
    if (id && datasource) {
      datasourceService._delete(id, datasource, this.props.otherData).then((answer) => {
        if (answer.error === false) {
          if ((answer.data || {}).forceRefresh) {
            window.location.reload(); //To update the graph on yearloss
          } else this.setState({ data: this.state.data.filter((item) => item.id !== id) });
        } else {
          this.props.dispatch(Notifications.error({ message: tr(answer.data), position: "tc" }));
        }
      });
    }
  }

  editRow(id) {
    this.setState({ specificEdit: { modal: true, id: id, mode: "edit" } });
  }
  addRow(id) {
    this.setState({ specificEdit: { modal: true, id: id, mode: "add" } });
  }
  toggleEdit() {
    this.setState({
      specificEdit: { modal: false, id: this.state.specificEdit.id, mode: this.state.specificEdit.mode },
    });
  }
  render() {
    const { rights, page, nbRecords, specificEdit, specialTable } = this.state;

    const BootstrapTableCfg = configOptions(
      BootstrapTable,
      rights,
      this.cellEdit,
      this.handleTableChange,
      page.currentPage,
      page.sizePerPage,
      nbRecords.data
    );
    const container = document.getElementsByClassName("widget_class_" + this.props.widgetId); //point on the right card layout header for expor icon
    return (
      <div>
        {this.state.waitingData && <Waiting />}
        {!this.state.waitingData && this.state.header.length > 0 && (
          <ToolkitProvider keyField="id" data={this.state.data} columns={this.state.header} exportCSV search>
            {(props) => (
              <div style={{ textAlign: "center" }}>
                <div style={{ textAlign: "right" }}>
                  {container.length > 0 &&
                    ReactDOM.createPortal(
                      <MyExportCSV {...props.csvProps} title={this.props.data.title} data={this.state.data} />,
                      container[0]
                    )}
                  {this.state.rights.create && ( // only display button add if create is enabled
                    <a
                      className="btn btn-success"
                      onClick={specialTable ? this.addRow.bind(this) : this.addEmptyRow.bind(this)}
                    >
                      <i className="fa fa-plus" />
                    </a>
                  )}
                </div>
                <div>
                  {!this.state.rights.distant && (
                    <SearchBar {...props.searchProps} style={{ marginBottom: "5px" }} placeholder={tr("search")} />
                  )}
                  <BootstrapTableCfg {...props.baseProps} wrapperClasses="table-responsive" />
                </div>
              </div>
            )}
          </ToolkitProvider>
        )}
        {!this.state.waitingData && this.state.header.length === 0 && (
          <div>
            {tr("no_data")}
            {this.state.rights.create && ( // only display button add if create is enabled
              <a
                className="btn btn-success"
                onClick={specialTable ? this.addRow.bind(this) : this.addEmptyRow.bind(this)}
              >
                <i className="fa fa-plus" />
              </a>
            )}
          </div>
        )}
        <Modal
          isOpen={specificEdit.modal}
          toggle={() => this.toggleEdit()}
          className="ProjipacModal"
          contentClassName="bg-light"
        >
          <SpecificEdit
            data={this.state}
            id={specificEdit.id}
            update={this.updateFromEdit.bind(this)}
            back={this.toggleEdit.bind(this)}
            mode={specificEdit.mode}
          />
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { list } = state.datasource;
  return {
    list,
  };
}
const connectedTableWidget = connect(mapStateToProps)(TableWidget);
export { connectedTableWidget as TableWidget };
