
import React from "react";
import { Redirect, Router, Switch } from "react-router-dom";
import { observer } from 'mobx-react';
import { connect } from "react-redux";
import { PrivateRoute } from "./_components/index";
import { LoginRoute } from './_components/LoginRoute';
import { LogoutRoute } from './_components/LogoutRoute';
import UIStore from './store/UIStore';
import LoggedInCallbackRoute from './_components/LoggedInCallbackRoute';
import Notifications from "react-notification-system-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

import intl from "react-intl-universal";

import { MainComponent } from "./Menu/MainComponent";
import {HelpPage} from './help';
import { locales } from "./_locales";
import setApiDefaults from "./_api/setApiDefaults";
// import { ConnectedRouter } from "connected-react-router";
import { config } from "./config";
import ConnectPage from "./User/ConnectPage";
import * as i18nService from './_i18n/i18n.service';
import { DEFAULT_ROUTE } from './configApp';

@observer
class App extends React.Component {

  componentDidMount() {
    const { authentication } = this.props;
    document.title = config.TITLE;
    i18nService.translate();
     setApiDefaults(authentication);
  }

  render() {
    const {  history, notifications } = this.props;
    return UIStore.appReady  &&
          <div>
          <ToastContainer />
          <Notifications notifications={notifications} /> {/* All notifications in the applications */}
          <Router history={history}>
            <Switch>
            {/* <ConnectedRouter history={history}> */}
                {/* All routes expect login/regsiter lead to MainComponent */}
                <LoginRoute exact path="/login" component={ConnectPage} auth={UIStore.isAuthenticated} redirectToWhenAuth={DEFAULT_ROUTE} />
                <PrivateRoute   path="/layout" name="Home" component={MainComponent} auth={UIStore.isAuthenticated} />
                <PrivateRoute   path="/datasource" name="Datasource" component={MainComponent} auth={UIStore.isAuthenticated} />
                <PrivateRoute   path="/addscreen" name="Addscreen" component={MainComponent} auth={UIStore.isAuthenticated} />
                <PrivateRoute   path="/dash" name="Dash" component={MainComponent} auth={UIStore.isAuthenticated} />
                <PrivateRoute   path="/settings" name="Settings" component={MainComponent} auth={UIStore.isAuthenticated} />
                <PrivateRoute   path="/help" name="Help" component={HelpPage} auth={UIStore.isAuthenticated} />
                <LoggedInCallbackRoute path="/loggedin"  redirectToWhenAuth={DEFAULT_ROUTE} />
                <LogoutRoute exact path="/logout" />
                <Redirect path='*' to={DEFAULT_ROUTE} />
            {/* </ConnectedRouter> */}
          </Switch>
        </Router>
        </div>;
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.authentication.loggedIn,
    language: state.language,
    notifications: state.notifications,
    authentication: state.authentication
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
