import React from "react";
import { connect } from "react-redux";
import {  Nav, NavItem, Button } from "reactstrap";
import PropTypes from "prop-types";
import {  AppNavbarBrand, AppSidebarToggler, AppHeader } from "@coreui/react";
import {Container, Row, Col} from 'reactstrap';
import { config } from "../config";
import { tr } from "../_locales";
import { Link } from "react-router-dom";
import { canIDoThis } from "../_helpers/rightsManager";
import { observer } from 'mobx-react';
import UIStore from '../store/UIStore';
import {getEnvironnements} from '../_api/apiProjipac';
import Select from 'react-select';
import "./header.css";
import ISO6391 from 'iso-639-1';
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));


const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};
@observer
class DefaultHeader extends React.Component {
  state={dropdown:false,environnement:false,envs:[],currentEnv:{},langs:[],currentLang:{}}

 sort(list) {
   console.log(list)
   list = list.sort((i,j) => (i.label > j.label ? 1 :
                              i.label < j.label ? -1 : 0));
   return (list);
 }

 async componentDidMount(){
  const envs = await getEnvironnements();
  if (envs && envs.length>0){
    let envInfo = this.manageEnvironment(envs);
    let langInfo = this.manageLanguage(envInfo.currentEnv.value);

    envInfo.storeEnvs = this.sort(envInfo.storeEnvs);
    langInfo.langs = this.sort(langInfo.langs);
    this.setState({envs:envInfo.storeEnvs, currentEnv:envInfo.currentEnv,
                  langs:langInfo.langs,currentLang:langInfo.currentLang});
  }
}

manageEnvironment(envs){
  let storeEnvs = [];
  envs.forEach(elt=>{storeEnvs.push({value:elt.id,label:countries.getName(elt.code.toUpperCase(),"en")||elt.country,
                                    code:elt.code,langs:elt.langs});});
  UIStore.setEnvs(storeEnvs);
  let defaultEnv = storeEnvs[0];
  if (!UIStore.environment){
    UIStore.setEnvironnement(defaultEnv.value);
  }
  let envSet = storeEnvs.find(country=>country.value == UIStore.environment );
  if (!envSet) {
    envSet = defaultEnv
    UIStore.setEnvironnement(envSet.value);
  }
  return ({storeEnvs:storeEnvs,currentEnv:envSet});
}
manageLanguage(currentEnv){
  const origEnv = UIStore.envs.find(env=>env.value === currentEnv );
  let languageToSet = UIStore.projilang;
  if (!languageToSet || languageToSet === "undefined"){// Pas de langue dans le local store on prend la première
    languageToSet = origEnv.langs.length>0 ? origEnv.langs[0] : "en";
  }else{// On vérifie si la langue du local store est encore valide
    languageToSet = origEnv.langs.includes(languageToSet) ? languageToSet : origEnv.langs[0];
  }
  UIStore.setLangProjipac(languageToSet);
  let langs =[];
  let storedLanguage =  UIStore.projilang;
  origEnv.langs.forEach(lang=>langs.push({value:lang,label:ISO6391.getNativeName(lang)}));
  return ({langs:langs,currentLang:{value:storedLanguage,label:ISO6391.getNativeName(storedLanguage)}})
}

  toggle = () => this.setState({dropdown:!this.state.dropdown});

  changeEnv = (e)=>{
    UIStore.setEnvironnement(e.value);
    this.manageLanguage(UIStore.environment);
    window.location.reload();}

  changeLang = (e)=>{
    UIStore.setLangProjipac(e.value);
    window.location.reload();}

  render() {
    // eslint-disable-next-line
    const { children } = this.props;
    const { envs, currentEnv,langs,currentLang} = this.state;

    return (
      <AppHeader fixed>
        <div className='navbar-content'>
          <div className='navbar-content-left'>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            {config.LOGO && <AppNavbarBrand full={{ src: config.LOGO,  width: 150, alt: "Application Logo" }} />}
            {/* Checks whether the user can access the datasource */}
            {canIDoThis(UIStore.user, "datasource_modify") && (
              <Nav className="">
                <NavItem className="px-3">
                  <Link to="/datasource">{tr("datasources")}</Link>
                </NavItem>
              </Nav>
            )}
          </div>

          <div className='navbar-content-center '>
            <Nav className="navbar-nav d-none d-lg-block" >
                <span className='select-desc'>Environnement:</span>
                <Select autosize={true} options={envs}  className="select float-right"
                        value={currentEnv} onChange={this.changeEnv} />
            </Nav>
            <Nav className="navbar-nav d-none d-lg-block" >
              <span className='select-desc'>Langue:</span>
              <Select autosize={true} options={langs}  className="select float-right"
                        value={currentLang} onChange={this.changeLang} />
            </Nav>
          </div>

          <div className='navbar-content-right'>
            <Nav>
              <Link to='/help'>{tr('Help')}</Link>
            </Nav>
            <Nav>
              <Link to='/logout'>
                <Button color='danger' outline>
                  <i className='nav-icon icon-power' />&nbsp;
                </Button>
              </Link>
            </Nav>
          </div>
        </div>
      </AppHeader>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

const connectedDefaultHeader = connect(mapStateToProps)(DefaultHeader);

export { connectedDefaultHeader as DefaultHeader };
