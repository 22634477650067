export const removeBracketAndReplace= (s, obj, prefix) => {
  null != prefix && (prefix += ".");
  // var regexp = /##([^/& ]+)##/gim;
  var regexp = /##(.*?)##/gim;
  return s = s.replace(regexp, function(m, p1) {
      return (p1 = null != prefix ? prefix + p1 : p1,
      null != obj[p1] ? obj[p1] : m);
  })
  
}
export const getUrlParams =( prop ) => {
  if (window.location.href.indexOf( '?' ) === -1) // no params found
  {
    return (null);
  }
  
  var params = {};

  var search = decodeURIComponent( window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ) );
  var definitions = search.split( '&' );

  definitions.forEach( function( val, key ) {
      var parts = val.split( '=', 2 );
      params[ parts[ 0 ] ] = parts[ 1 ];
  } );

  return ( prop && prop in params ) ? params[ prop ] : params;
}

export const getFileExtension= (fileName)=>{
  const re = /(?:\.([^.]+))?$/;
  return re.exec(fileName)[1];
}

export const isObject= (item)=>{
  return (typeof item === "object" && !Array.isArray(item) && item !== null);
}