import React from "react";
import { Button, Form, Input } from "reactstrap";
import { tr } from "../../_locales";
import { config } from "../../config";
import { getFileExtension } from "../../_helpers/stringManager";
import Notifications from "react-notification-system-redux";

export class FileEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null
    };
  }
  fileChosen(e) {
    let ext = getFileExtension(e.target.files[0].name);
    if (!config.AUTHORIZED_UPLOAD || config.AUTHORIZED_UPLOAD.includes(ext)) {
      this.setState({ file: e.target.files[0] });
    }
  }
  notAuthorized() {
    const message = tr("ext_not_authorized") + config.AUTHORIZED_UPLOAD.join(", ");
    this.props.dispatch(Notifications.error({ message: message, position: "tc" }));
  }
  onSubmit(e) {
    e.preventDefault();
    if (!this.state.file) {
      this.notAuthorized();
    } else {
      let reader = new FileReader();
      if (this.state.file) {
        reader.readAsDataURL(this.state.file); //corts to base64
        reader.onload = () => this.props.onUpdate(reader.result); // when file is loaded provide the data to cell
      } else {
        this.props.onUpdate("");
      }
    }
  }
  render() {
    return [
      <Form onSubmit={this.onSubmit.bind(this)}>
        <Input type="file" id="file" onChange={this.fileChosen.bind(this)} />
        <Button color="primary" type="submit">
          {tr("ok")}
        </Button>
      </Form>
    ];
  }
}

export const fileFormatter = (cell, row) => {
  if (cell) {
    const test = cell.split(";base64,");
    if (test.length > 1) {
      // it is a file in base64
      if (test[0].startsWith("data:image")) {
        return (
          <span>
            <i color="primary" className="fa fa-file-o" />
            <img alt="display" src={cell} />
          </span>
        );
      } else if (test[0].startsWith("data:video")) {
        return (
          <span>
           {row.id &&( <img
              alt="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />)}           
            {!row.id &&( tr("file_loaded_add"))}

            <video width="1" height="1">
              <source src={cell} type="video/mp4" />
            </video>
          </span>
        );
      }
    }
  }
  return <span>{cell}</span>;
};
