import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import UIStore from '../store/UIStore';
import * as userService from '../User/auth.service';
import queryString from 'query-string';
import { tr } from "../_locales";

/**
 * Manage login callback from backend
 * Receive the jwt token by query params
 */
@observer
export default class LoggedInCallbackRoute extends React.Component {

  constructor (props) {
    super (props);

    this.state = {
      loading: true,
      authFailed: false
    };
  }

  /**
   * Get jwt token by query params
   * then ask backend for user informations
   */
  async componentDidMount () {
    const parsed = this.props.location && queryString.parse(this.props.location.search);
    if(!parsed || parsed.token === null) {
      this.setState({
        loading: false,
        authFailed: true
      });

    } else {
      userService.setToken(parsed.token);
      const results = await userService.getUserInfos();

      this.setState({
        loading: false,
        authFailed: !results
      });

    }
  }

  /**
   * Redirect to the page we want (this.props.redirectToWhenAuth)
   * or to the login page if auth failed: ie: no jwt token or unable to get user informations
   */
  render () {
    let { ...rest } = this.props;
    let test = UIStore.isLoggedIn;

    return (
      this.state.loading
        ? <div>Loading...</div>
        : <Route {...rest} render={props => (UIStore.isAuthenticated && this.redirect(props) )} />
    );
  }

  redirect (props) {
    let { redirectToWhenAuth } = this.props;
    if(!this.state.authFailed) {
      // toast.success(tr("SUCCESS_AUTHENTICATION"));
    } else {
      toast.error(tr("ERROR_DURING_AUTHENTICATION"));
    }    

    return (
      this.state.authFailed
        ? <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        : <Redirect to={{ pathname: redirectToWhenAuth, state: { from: props.location } }} />
    );
  }
}