import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,Table,Row,CustomInput} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import api from "../../../_api/api";
import Select from 'react-select';
import {verifyCheckedEnv,getCheckedEnvs} from "./env_management";

  class AccessorySuperEdit extends React.Component {
     state={data:null,envs:{},allAcc:null,DepAcc:null,InAcc:null,allGroup:null,addDep:false,addIn:false,selectedGroup:null}
     componentDidMount(){
         let allAcc= null;
         let acc = null;
         api.accessories.getAll().then(allAccs=>{
            let accs= allAccs.map(item =>{
                return{id:item.id,reference:item.reference_def,value:item.code,label:`${item.code}  ${item.reference_def}`} 
            } );

            api.accessories.getAllGroups(UIStore.lang).then(allGroups=>{
                const groups = allGroups.map(elt=>{return{label:elt.description,value:elt.id}});
                if (this.props.mode ==="add"){
                    this.setState({allAcc:accs,allGroup:groups});
                }else{
                    acc = this.props.data.data.find(item=>item.id === this.props.id);
                    const envs = verifyCheckedEnv(acc, UIStore.envs);
                    if(acc){
                            api.accessories.getDepIncAcc(acc.code,UIStore.environment,true).then(accs=>{
                               this.depIncAcc(accs,acc);})
                            accs = accs.filter(element=> element.id != acc.id); //remove the accessory itself from the list
                            }
                       this.setState({data:acc,allAcc:accs,allGroup:groups,envs:envs,
                                      selectedGroup:{value:acc.accessorygroup_id,label:acc.group}});
                }                  
            })
          }) 

        }
        toggleCheck = (env) =>{
           const {envs} = this.state;
           if (envs){
               let newEnvs = {...envs};
               newEnvs[env] = !envs[env];
               this.setState({envs:newEnvs});
           }
        }
        
     fillTab =(accs,data,depInc)=>{
        let tab=[];
        if (data[depInc]){
            data[depInc].forEach(dep=>{
               dep = parseInt(dep,10);
               let found = accs.find(acc=>acc.id === dep);
               if (found){
                   tab.push({id:dep,value:found.code,label:found.reference,reference:found.reference});
               }
               })
           }
           return tab;
     }

     depIncAcc = (accs,data) =>{
        const dep = this.fillTab(accs,data,"dep");
        const inc = this.fillTab(accs,data,"inc");
        this.setState({DepAcc:dep,InAcc:inc});
     }

     getAccIds = (type)=>{
         if (this.state[type]){
            return this.state[type].map(item=>item.id.toString());
         }
         return null
     }

     handleSubmit = (event) =>{
        event && event.preventDefault();
        const checkedEnvs = getCheckedEnvs(this.state.envs,UIStore.envs);
        let countries =[];
        for (var country in this.state.envs){
            if(this.state.envs[country]){ countries.push(country)}
        }
        const {code,reference,photo} = this;
        const idGroup =  this.state.selectedGroup ? this.state.selectedGroup.value : null;
        const group = this.state.selectedGroup ? this.state.selectedGroup.label : null;
        const id = this.state.data ? this.state.data.id : null;
        let itemUpdate = {id:id,reference_def:reference.value,code:code.value,envs:checkedEnvs,photo:photo.value,
                         dep:this.getAccIds("DepAcc"),inc:this.getAccIds("InAcc"),accessorygroup_id:idGroup,
                         group:group,countries:countries.join(", ")};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }

     filterAccs =  (type) => {
        if (!this.state[type]) return this.state.allAcc;
        return (this.state.allAcc.filter(item=> !this.state[type].find(acc=>acc.value === item.value)));
    }

    listAcc = ()=>{
        return this.state.allAcc.map(item =>{return{value:item.code,label:`${item.code}  ${item.reference}`} } )
    }
    
    deleteAccessory = (acc,type)=>{
        const newAccs = this.state[type].filter(item=>item.value !== acc.value);
        let obj={};obj[type] = newAccs;
        this.setState(obj);
    }

    addEmptyAccessoryDep = ()=>{this.setState({addDep:true})};
    addEmptyAccessoryIn = ()=>{this.setState({addIn:true})};
    selectChangeDep = DepAcc =>{this.setState({DepAcc});}
    selectChangeInc = InAcc =>{this.setState({InAcc});}  
    selectChangeGroup = selectedGroup=>{this.setState({selectedGroup});}      

     render() {
         const {data,DepAcc,InAcc,addDep,addIn,allGroup,selectedGroup} = this.state;
         const filtereDepAccs = this.filterAccs("DepAcc");
         const filtereIncAccs = this.filterAccs("InAcc");         
         
         return(<div>
                    <div><Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Button type="submit" className="ButtonUpdate" color="success">{this.props.mode === "edit" ?tr("Update") : tr("Add")}</Button>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="reference">{tr("reference")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="reference"name="reference"type="text" required defaultValue={data && data.reference_def}
                            innerRef={input => (this.reference = input)}/>
                    </Col>      
                    <Col md="1"/>                                         
                    <Col md="1">
                        <Label htmlFor="code">{tr("code")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="code"name="code"type="text"  required defaultValue={data && data.code}
                            innerRef={input => (this.code = input)}/>
                    </Col> 
                    
  
                    </FormGroup>
                    <FormGroup row>
                    <Col md="1">
                        <Label htmlFor="group">{tr("group")} </Label>
                    </Col>      
                    <Col  md="4">
                         <Select className="group" options={allGroup}  isSearchable onChange={this.selectChangeGroup} value={selectedGroup}/> 
                    </Col>      
                    <Col md="1"/>                                         
                    <Col md="1">
                        <Label htmlFor="photo">{tr("photo")} </Label>
                    </Col>
                    <Col  md="4">
                        <Input id="photo"name="photo"type="text"  defaultValue={data && data.photo}
                            innerRef={input => (this.photo = input)}/>
                    </Col>                                                      
                    </FormGroup>                                    
                </Form>
                </CardBody>
            </Card>
            <Card>
            <h4>{tr("environnement_mgt")}</h4>                

            <FormGroup row className="Checkboxes">
            {UIStore.envs && UIStore.envs.map((env,index)=>
                      <CustomInput className="Checkbox" type="checkbox" id={`env_${index}`} name={env.value}
                      onChange={()=>this.toggleCheck(env.code)} checked={this.state.envs[env.code]}
                      label={env.label} />
                )}
                </FormGroup>
            </Card>             
            <Card>
            <CardBody>
                <Row>
                    <Col  md="6">
                    <h4 href="depacc">{tr("Dependant_accessories")}</h4>
                    {!addDep && <a className="btn btn-success depAcc" onClick={this.addEmptyAccessoryDep.bind(this)}>
                        <i className="fa fa-plus" />
                      </a>}
                      {addDep && <Select className="selectAcc" options={filtereDepAccs} isMulti isSearchable onChange={this.selectChangeDep} value={DepAcc}/>}                        
                        <Table striped>
                        <thead>
                            <tr>
                            <th>Code</th>
                            <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DepAcc && DepAcc.map(acc=>
                            <tr  className={acc.code ? "":"allGreenLine"}>
                                <td>{acc.value}</td>
                                <td>{acc.reference}</td>
                                <td><a className="DeleteIcon" onClick={this.deleteAccessory.bind(this,acc,"DepAcc")} ><i className="fa fa-trash"/></a></td>                                                             
                            </tr>
                                )}
                        </tbody>  
                        </Table>
                        </Col>
                        <Col  md="6">                            
                    <h4 href="inacc">{tr("Invalid_accessories")}</h4>
                    {!addIn && <a className="btn btn-success depAcc" onClick={this.addEmptyAccessoryIn.bind(this)}>
                        <i className="fa fa-plus" />
                      </a>}
                      {addIn && <Select className="selectAcc" options={filtereIncAccs} isMulti isSearchable onChange={this.selectChangeInc} value={InAcc}/>}
                        <Table id="inacc" striped>
                        <thead>
                            <tr>
                            <th>Code</th>
                            <th>Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {InAcc && InAcc.map(acc=>
                          <tr  className={acc.code ? "":"allGreenLine"}>
                          <td>{acc.value}</td>
                          <td>{acc.reference}</td>
                          <td><a className="DeleteIcon" onClick={this.deleteAccessory.bind(this,acc,"InAcc")} ><i className="fa fa-trash"/></a></td>                                                             
                      </tr>
                          )}
                        </tbody>                      
                        </Table>
                        </Col> 
                        </Row>
                </CardBody>
            </Card>
                
                
        </div>

         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedAccessorySuperEdit = connect(mapStateToProps)(AccessorySuperEdit);
  export { connectedAccessorySuperEdit as AccessorySuperEdit };