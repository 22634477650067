import React, {Component} from 'react';
import styles from './rate.module.scss';

/**
 * @prop {Number} value
 */
class RaterView extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const {value, disabled} = this.props;

    return (
      <div className={styles.rate}>
        <span className={`${styles.star} ${value >= 1 && styles.starActive}`} />
        <span className={`${styles.star} ${value >= 2 && styles.starActive}`} />
        <span className={`${styles.star} ${value >= 3 && styles.starActive}`} />
        <span className={`${styles.star} ${value >= 4 && styles.starActive}`} />
        <span className={`${styles.star} ${value >= 5 && styles.starActive}`} />
      </div>
    )
  }
}

export default RaterView;