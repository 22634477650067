import React from "react";
import { connect } from "react-redux";
import { tr, locales, localeActions } from "../_locales";
import { menuActions } from "../Menu/menu.actions";
import { Card, CardBody, CardHeader, Col, Button, Row } from "reactstrap";
import intl from "react-intl-universal";

const listThemes=[
  {label:"Light", class:""},
  {label:"Dark", class:"bg-dark"},
  {label:"Gray", class:"bg-secondary"}
];
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  changeLanguage(language) {
    intl.init({
      currentLocale: language,
      locales
    }).then(()=>{
      this.props.dispatch(localeActions.changeLanguage(language))
      window.location.reload(); // reload the page to take the new languge into account
    });

  }
  changeTheme(theme) {
    this.props.dispatch(menuActions.changeTheme(theme));
  }
  render() {
    const themeClass= this.props.theme ? this.props.theme.class :"";
    return (
      <Card className={themeClass}>
        <CardHeader>
          <strong>{tr("Settings")}</strong>
        </CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col md="2"  >
              {tr("Languages")}
            </Col>
            {Object.keys(intl.getInitOptions().locales).map(language => {
              return (
                <Col key={language} md="2">
                  <Button
                    block
                    outline
                    className={
                      language === intl.getInitOptions().currentLocale
                        ? "active"
                        : ""
                    }
                    onClick={() => this.changeLanguage(language)}
                    color="primary"
                  >
                    {language.split("-")[0]}
                  </Button>
                </Col>
              );
            })}
          </Row>
          <br />
          <Row className="align-items-center">
            <Col md="2"  >
              {tr("Themes")}
            </Col>
            {listThemes.map(theme => {
              return (
                <Col key={theme.label} md="2">
                  <Button
                    block
                    outline
                    className={
                      this.props.theme && (theme.label === this.props.theme.label)
                        ? "active"
                        : ""
                    }
                    onClick={() => this.changeTheme(theme)}
                    color="primary"
                  >
                    {tr(theme.label)}
                  </Button>
                </Col>
              );
            })}
          </Row>          
        </CardBody>
      </Card>
    );
  }
}

// export default Settings;
function mapStateToProps(state) {
  return {
    language: state.language.current,
    theme:    state.theme.current
  };
}

const connectedSettings = connect(mapStateToProps)(Settings);
export { connectedSettings as Settings };
