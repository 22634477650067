import React from "react";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button} from "reactstrap";
import { tr } from "../../../_locales";

  export class YearLossEdit extends React.Component {
     state={data:null}
     componentDidMount(){
        let group = null;
        if(this.props.id){
            group = this.props.data.data.find(item=>item.id === this.props.id);
            this.setState({data:group})
        }
    }

     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {envyear,envvalue} = this;
        const {id,code} = this.state.data;
        let itemUpdate = {id:id,envyear:envyear.value,envvalue:envvalue.value,code:code};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode).then(() => {
          window.location.reload();//To update the graph
        });
     }

     render() {
         const {data} = this.state;
         const {mode} = this.props;

         return(<div>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Card>
                    <h4>{tr("Infos")}</h4>
                    <CardBody>
                    <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                    <Button type="submit" className="ButtonUpdate" color="success">{tr("Update")}</Button>
                        <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="envyear">{tr("envyear")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="envyear"name="envyear"type="text"   defaultValue={data && data.envyear}
                                innerRef={input => (this.envyear = input)}/>
                        </Col>
                        <Col md="2"/>
                        <Col md="1">
                            <Label htmlFor="envvalue">{tr("envvalue")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="envvalue"name="envvalue"type="text"   defaultValue={data && data.envvalue}
                                innerRef={input => (this.envvalue = input)}/>
                        </Col>
                        </FormGroup>
                    </CardBody>
                    </Card>
                </Form>
         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }