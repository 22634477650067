import React from "react";
import { config } from "../config";


class DefaultFooter extends React.Component {
  render() {


    return (
      <React.Fragment>
        <span>
          <a href={config.COMPANY_URL}>{config.COMPANY_NAME}</a> &copy;{config.COPYRIGHT}
        </span>
      </React.Fragment>
    );
  }
}

export default DefaultFooter;
