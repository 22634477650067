export const layoutConstants = {
  CHANGE_LAYOUT: "CHANGE_LAYOUT",
  REMOVE_EL_LAYOUT: "REMOVE_EL_LAYOUT",
  ADD_EL_LAYOUT: "ADD_EL_LAYOUT",
  UPDATE_EL_LAYOUT: "UPDATE_EL_LAYOUT",
  
  DEF_STORE_SUCCESS: "LAYOUT_DEF_STORE_SUCCESS",

  DEF_UPDATE_SUCCESS: "LAYOUT_DEF_UPDATE_SUCCESS",

  DEF_GET_SUCCESS: "LAYOUT_DEF_GET_SUCCESS",
  DEF_GET_EMPTY: "LAYOUT_DEF_GET_EMPTY",

  DEF_DELETE_SUCCESS: "LAYOUT_DEF_DELETE_SUCCESS"
};
