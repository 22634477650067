import React, {Component, Fragment} from 'react'
import {Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import DefaultFooter from "../Menu/DefaultFooter";
import { datasourceActions } from "../Datasource/datasource.actions";
import { menuActions } from "../Menu/";
import { DefaultHeader } from "../Menu/DefaultHeader";
import { tr } from "../_locales";
import { canIDoThis } from "../_helpers/rightsManager";
import { observer } from 'mobx-react';
import { connect } from "react-redux";
import Tutorial from './tutorial';
import UIStore from '../store/UIStore';
import Axios from 'axios';
import Loader from '../_components/Loader'
import styles from './help.module.scss';
import classnames from 'classnames'

@observer class HelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tutorials: null,
      tabName: [],
      loading: true,
      activeTab: 0
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.props.dispatch(menuActions.getMenu(this.props.authentication)); // retrieve the menu to be displayed
    this.props.dispatch(datasourceActions.getAll()); // Store all datasources to use in the application
    Axios.get('/help/env=' + UIStore.environment + '&lang=' + UIStore.lang)
      .then(r => {
        this.setState({
          tutorials: r.data,
          tabName: this.getTabName(r.data),
          loading: false,
        });
      })
  }

  render() {
    const theMenu = {...this.props.menu};

    return (
      <div className='app'>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarForm />
            {theMenu && theMenu.items && <AppSidebarNav navConfig={this.getMenu(theMenu)} {...this.props} />}
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className='main' style={{marginTop: '20px'}}>
            <div className={styles.tutorialBox}>
              { this.state.loading ?
                <div className={styles.loader}><Loader /></div>
                :
                <Fragment>
                  <Nav tabs>
                    { this.state.tabName.map((i,id) =>
                      <NavLink className={classnames({active: this.state.activeTab === id})}
                               onClick={() => this.setState({activeTab: id})}
                               key={i}>
                        {i}
                      </NavLink>
                    )}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    { this.state.tabName.map((tabName, tabId) =>
                      <TabPane key={tabName} tabId={tabId}>
                        <div className={styles.tutorialContainer}>
                          {this.state.tutorials.filter(i => i.category === tabName).map(tabContent =>
                            <Tutorial data={tabContent} key={tabContent.id}/>
                          )}
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </Fragment>
              }
            </div>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
  getTabName(data) {
    let tabs = [];

    for (let i = 0; i < data.length; i++) {
      if (!tabs.includes(data[i].category))
        tabs.push(data[i].category);
    }
    return (tabs);
  }
  getMenu(menu) {;
    // adds url to menu items and addscreen item
    let tempMenu = { ...menu };
    tempMenu.items = tempMenu.items.filter(item=>{
      if (!canIDoThis(UIStore.user, "super") && item.superAdmin){
        return (false)}
      return true;
      })

    if (tempMenu && tempMenu.items) {
      tempMenu.items.forEach((item, index) => {
        item.name = tr(item.name);
        if (item.parent && item.parent !== 0) {
          this.addChildren(item, item.parent, tempMenu.items);
        } else {
          item = this.getUrl(item);
        }
      });
    }
    canIDoThis(UIStore.user, "layout_modify") &&
      !tempMenu.items.find(el => el.url === "/addScreen") &&
      tempMenu.items.push(this.addScreen());
    tempMenu.items = tempMenu.items.filter(el => el.parent === 0 || el.url === "/addScreen"); //remove children from main menu
    return tempMenu;
  }
  getUrl(item) {
    // URL is either an external URL or layout/id
    if (!item.url) {
      if (item.layoutId) {
        item.url = "/layout/" + item.layoutId;
      } else if (item.extUrl) {
        item.url = item.extUrl;
      } else {
        item.url = "/layout/" + item.name;
      }
    }
    return item;
  }
  addScreen = () => {
    // addscreen menu item creates a new entry
    return {
      name: tr("add_screen"),
      icon: "icon-plus",
      url: "/addScreen",
      variant: "secondary"
    };
  };
  addChildren(child, parent, tab) {
    let theParent = tab.find(el => el.id === parent);
    if (theParent){
      if (!theParent.children) {
        theParent.children = [child];
      } else if (!theParent.children.find(el => el.id === child.id)) {
        child = this.getUrl(child);
        theParent.children.push(child);
      }
    }
  }
};

function mapStateToProps(state) {
  const { menu, authentication } = state;
  return {
    menu,
    authentication
  };
}
const connectedHelpPage = connect(mapStateToProps)(HelpPage);
export {connectedHelpPage as HelpPage};

/*<Fragment>
                  {this.state.tutorials &&
                    <Fragment>
                      {this.state.tutorials != 0 && 
                      <div className={styles.tutorialContainer}>
                          {this.state.tutorials.map(i =>
                              <Tutorial key={i.id} data={i} />
                          )}
                      </div>
                      }
                      {this.state.tutorials == 0 &&
                        <h4>{tr('No tutorial available.')}</h4>
                      }
                    </Fragment>
                  }
                </Fragment>*/