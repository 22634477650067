import React, { Component } from 'react';
import {Card, CardBody, InputGroupAddon,
        Button, Input, Form, FormGroup, Label, InputGroup, InputGroupText,} from 'reactstrap';
import {tr} from '../../../_locales';
import UIStore from '../../../store/UIStore';

class TvaEdit extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      rate: '0',
      id:null
    };
  }
  componentDidMount(){
    const data = this.props.mode === "add" ? null : this.props.data.data.find(item=>item.id === this.props.id);
    const rate = data ? data.rate :'0';
    const id = data ? data.id : null;
    this.setState({rate:rate,id:id,data:data});
  }
  
  render() {
    return (
      <Form onSubmit={this.submit}>
        <Card>
          <h4>{tr('VAT')}</h4>
          <CardBody>
            <Button className="ButtonBack" onClick={this.props.back} color='secondary'>{tr('Back')}</Button>
            <Button className="ButtonUpdate" onClick={this.submit} color='success'>{tr('Update')}</Button>
            <FormGroup>
              <Label>{tr('rate')}</Label>
              <InputGroup>
                <Input min='0' max='100' step='0.1' type='number' onChange={this.rateChange} value={this.state.rate} placeholder={tr('Rate') + ': ...'}/>
                <InputGroupAddon addonType='append'>
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </CardBody>
        </Card>
      </Form>
    );
  }

  //-------------------------- events -------------------------------//
  submit = (e) => {
    const {id,data} = this.state;
    if (e) e.preventDefault();
    this.props.update({id:id,rate: this.state.rate, environnement_id: UIStore.environment}, id, data, this.props.mode);
  }

  rateChange = (e) => {
    this.setState({rate: e.target.value});
  }
}

export {TvaEdit};