import { userConstants } from "../User";
import { tr } from "../_locales";
import api from "../_api/api";
import setAuthorizationHeader from "../_api/setAuthorizationHeader";
import Notifications from "react-notification-system-redux";
import history from "../history";

export const userActions = {
  login,
  logout,
  update,
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));
    api.user.login(username, password).then(
      user => { 
        dispatch(success(user));
        setAuthorizationHeader(user.token);
        history.push("/");
      },
      error => {  
        if (error.response && error.response.status === 401) {
          dispatch(Notifications.error({ message: tr("error_login_password") }));
          logout();
        } else {
          dispatch(Notifications.error({ message: tr("error_network") }));
        }
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  // api.user.logout(); //No logout for the time being
  return { type: userConstants.LOGOUT };
}

function update(data) {
  return dispatch => {
    return api.user.update(data).then(
      answer => {
        dispatch(success(answer));
      }, error => {
        dispatch(Notifications.error({ message:  tr("update_error") }));
      });
  };

  function success(data) {
    return { type: userConstants.UPDATE_SUCCESS, data };
  }

}

