import React from "react";
import { connect } from "react-redux";
import {Form,Col,Card,CardBody,FormGroup,Input,Label,Button,CustomInput} from "reactstrap";
import { tr } from "../../../_locales";
import UIStore from '../../../store/UIStore';
import {verifyCheckedEnv,getCheckedEnvs} from "./env_management";

  class EnergyEdit extends React.Component {
     state={data:null,mode:"admin",envs:{}}
     componentDidMount(){
         const {data,mode} = this.props;
         const energy = mode === "add" ? {} : data.data.find(item=>item.id === this.props.id);
         const modeAdmin = this.props.data.rights.create ? "super" : "admin";
         const envs = modeAdmin === "super" ? verifyCheckedEnv(energy, UIStore.envs):{};
         this.setState({data:energy,envs:envs,mode:modeAdmin});
    }
     handleSubmit = (event) =>{
        event && event.preventDefault();
        const {mode,data} = this.state
        const checkedEnvs = mode === "super" ? getCheckedEnvs(this.state.envs,UIStore.envs):null;
        let countries =[];
        if (checkedEnvs){
            for (var country in this.state.envs){
                if(this.state.envs[country]){ countries.push(country)}
            }
        }
        const {code,label,price,unit,unitco2,co2} = this;
        const {id} = this.state.data;
        let itemUpdate = mode ==="super" ? {id:id,code:code.value,envs:checkedEnvs,unit:unit.value,unitco2:unitco2.value,
                                            countries:countries.join(", ")}
                                         : {id:id,code:code.value,label:label.value,price:price.value,envs:checkedEnvs,
                                            unit:data.unit,unitco2:data.unitco2,co2:co2.value};
        this.props.update(itemUpdate,id,this.state.data,this.props.mode);
     }

     toggleCheck = (env) =>{
        const {envs} = this.state;
        if (envs){
            let newEnvs = {...envs};
            newEnvs[env] = !envs[env];
            this.setState({envs:newEnvs});
        }
     }

     render() {
         const {data,mode} = this.state;
         return(<div>
              {data && <div><Card>
                <h4>{tr("Infos")}</h4>
                <CardBody>
                <Button onClick className="ButtonBack" onClick={this.props.back} >{tr("Back")}</Button>
                <br/>
                <Form onSubmit={this.handleSubmit.bind(this)}>
                <Button type="submit" className="ButtonUpdate" color="success">{this.props.mode === "edit" ?tr("Update") : tr("Add")}</Button>
                {mode === "admin" && <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="label">{tr("label")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="label"name="label"type="text" required defaultValue={data.label}
                                innerRef={input => (this.label = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="code">{tr("code")} </Label>
                        </Col>
                        <Col  md="4">
                            <Input id="code"name="code"type="text" disabled  defaultValue={data.code}
                                innerRef={input => (this.code = input)}/>
                        </Col>
                      
                    </FormGroup>}
                    {mode === "super" && <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="code">{tr("code")} </Label>
                        </Col>
                        <Col  md="3">
                            <Input id="code"name="code"type="text" required defaultValue={data.code}
                                innerRef={input => (this.code = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="unit">{tr("unit")} </Label>
                        </Col>
                        <Col  md="2">
                            <Input id="unit"name="unit"type="text"  defaultValue={data.unit}
                                innerRef={input => (this.unit = input)}/>
                        </Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="unitco2">{tr("unitco2")} </Label>
                        </Col>
                        <Col  md="2">
                            <Input id="unitco2"name="unitco2"type="text"  defaultValue={data.unitco2}
                                innerRef={input => (this.unitco2 = input)}/>
                        </Col>                        
                    </FormGroup>}

                    {mode === "admin" && <FormGroup row>
                        <Col md="1">
                            <Label htmlFor="price">{tr("price")} </Label>
                        </Col>
                        <Col  md="3">
                            <Input id="price"name="price"type="text" required defaultValue={data.price}
                                innerRef={input => (this.price = input)}/>
                        </Col>
                        <Col md="1"> <Input id="unit"name="unit"type="text" disabled  defaultValue={data.unit}/></Col>
                        <Col md="1"/>
                        <Col md="1">
                            <Label htmlFor="co2">{tr("co2")} </Label>
                        </Col>
                        <Col  md="3">
                            <Input id="co2"name="co2"type="text"   defaultValue={data.co2}
                                innerRef={input => (this.co2 = input)}/>
                        </Col>   
                        <Col md="2"> <Input id="unitco2"name="unitco2"type="text" disabled  defaultValue={data.unitco2}/></Col>                                              
                    </FormGroup>  }
                </Form>
                </CardBody>
            </Card>
           {this.state.mode === "super" &&
            <Card>
                <h4>{tr("environnement_mgt")}</h4>
                <FormGroup row className="Checkboxes">
                {UIStore.envs && UIStore.envs.map((env,index)=>
                        <CustomInput key={index} className="Checkbox" type="checkbox" id={`env_${index}`} name={env.value}
                        onChange={()=>this.toggleCheck(env.code)} checked={this.state.envs[env.code]}
                        label={env.label} />
                    )}
                    </FormGroup>
            </Card>  }
        </div>}

         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedEnergyEdit = connect(mapStateToProps)(EnergyEdit);
  export { connectedEnergyEdit as EnergyEdit };