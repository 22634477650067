import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * Manage Login
 * If not authenticated show Login else Redirect to Component
 * @param Component
 * @param auth
 * @param redirectToWhenAuth
 * @param rest
 * @returns {*}
 * @constructor
 */
export const LoginRoute = ({ component: Component, auth, redirectToWhenAuth, ...rest }) => (
  <Route {...rest} render={props => (
    auth
      ? <Redirect to={{pathname: redirectToWhenAuth, state: {from: props.location}}} />
      : <Component {...props} />
  )} />
)
