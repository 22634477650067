import intl from 'react-intl-universal';
import {config} from "../config";
import ax from 'axios';
import UIStore from "../store/UIStore";
// import { locales } from "../_locales";
/**
 * Load the lang locale form the backend
 * @param lang
 * @returns {Promise<*>}
 */
export async function loadLocale(lang) {
  try {
    const response = await ax.get(`${config.API_URL}/backofficelocals/${lang}`);
    return response.data;

  } catch (error) {
    console.error(error);
    return false;
  }
}


/**
 * Load the "lang" locale and refresh App
 * @param lang
 * @returns {Promise<void>}
 */
export async function translate(lang) {
  if(!lang) {
    lang = UIStore.projilang || 'fr'; 
  }
  const locales = {};
  locales[lang] = await loadLocale(lang);
  intl.init({
    currentLocale: lang,
    locales,
  }).then(() => {
    UIStore.setLanguage(lang);
    UIStore.appIsReady();
    // reload app if its a language switch
    // UIStore.appReady ? window.location.reload() : UIStore.appIsReady();
  });

}