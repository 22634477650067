import React from "react";
import { connect } from "react-redux";
import {HpEdit} from './HpEdit';
import {HpSuperEdit} from './HpSuperEdit';
import {AccessoryEdit} from './AccessoryEdit';
import {AccessoryGroupEdit} from './AccessoryGroupEdit';
import {AccessorySuperEdit} from './AccessorySuperEdit';
import {EnvEdit} from './EnvEdit';
import {EnergyEdit} from './EnergyEdit';
import {TvaEdit} from './TvaEdit';
import {UserEdit} from './UserEdit';
import {ChangelogEdit} from './ChangelogEdit';
import {ParameterEdit} from './ParameterEdit';
import {YearLossEdit} from './YearLossEdit';
import {LocaleEdit} from './LocaleEdit';
import {TutorialEdit} from './TutorialEdit';
import {FaqEdit} from './FaqEdit';
import {AdminTutorialEdit} from './AdminTutorialEdit';
import { LegalHelpEdit } from "./LegalHelpEdit";
import {SatisfactionView} from './SatisfactionView';

import "./projipac_edit.css";
const theComponents={Pompes:HpEdit, pompes_mgt:HpSuperEdit, Accessoires:AccessoryEdit,
                    accessory_mgt:AccessorySuperEdit, energy: EnergyEdit, energy_mgt:EnergyEdit,
                    accessorygroup:AccessoryGroupEdit, environnement:EnvEdit, TVA:TvaEdit,  Users:UserEdit,
                    Changelog: ChangelogEdit, yearloss:YearLossEdit, locale:LocaleEdit, Parameter: ParameterEdit,
                    AdminTutorial: AdminTutorialEdit, Tutorial: TutorialEdit, FAQ:FaqEdit,
                    LegalHelp: LegalHelpEdit, Satisfaction: SatisfactionView, 
                };

  class SpecificEdit extends React.Component {
     state={component:null};
     componentDidMount(){
         const foundTable= this.props.list.find(item => item.id == this.props.data.datasourceId);
         if (theComponents[foundTable.name]){
            this.setState({component:theComponents[foundTable.name]});
         }        
     }
     render() {
         const Component = this.state.component;

         return(<div className="SpecificEdit">
                {Component && <Component {...this.props}/>}

         </div>)
     }
 }
 function mapStateToProps(state) {
    const { list } = state.datasource;
    return {list};
  }
  const connectedSpecificEdit = connect(mapStateToProps)(SpecificEdit);
  export { connectedSpecificEdit as SpecificEdit };